<template lang="pug">
modal-vue(
  :visible="visible"
  :modal_id="'links_modal'"
  :content_loaded="links_loaded"
  :size_modal="'modal-lg'"
  :auto-close-enabled="true"
)
  template(v-slot:header)
    span.align-self-center.mr-auto
      | {{ t('components.documents.modal_links.title') }}

  template(v-slot:body)
    div.error_message(
      v-if="links_load_error"
      data-cy="error_message"
    )
      | {{ t('components.documents.modal_links.load_error') }}
    div(v-else)
      link-vue(
        v-for="link in links"
        :key="link.id"
        :link="link"
      )

  template(v-slot:footer)
    button.btn.btn-secondary(
      type="button"
      @click="hide_modal"
    )
      | {{ t('components.documents.modal_links.close') }}

</template>

<script>
import Vue from 'vue'
import mixins from 'common/mixins'

export default {
  name: 'modal-links-vue',
  mixins: [mixins.translate, mixins.requests],
  components: {
    'modal-vue': () => import(/* webpackChunkName: "[request]" */ 'components/Modal'),
    'link-vue': () => import(/* webpackChunkName: "[request]" */ 'components/documents/Link')
  },
  data() {
    return {
      links_loaded: false,
      links_load_error: false,
      links: [],
      visible: false
    }
  },
  methods: {
    async load_links(document_ids) {
      this.reset_modal()
      await this.fetch_links(document_ids)
      this.show_modal()
    },
    reset_modal() {
      this.links_loaded = false
      this.links_load_error = false
      this.documents = []
    },
    show_modal() {
      this.visible = true
    },
    hide_modal() {
      this.visible = false
    },
    async fetch_links(document_ids) {
      const params = new URLSearchParams()
      document_ids.forEach(doc_id => {
        params.append('ids[]', doc_id)
      });
      
      const url = `/base/${gon.current_app}/documents?${params}`
      const response = await fetch(url)

      if (!response.ok) {
        this.set_links_error()
        return
      }

      const data = await response.json()
      this.set_links_data(data)
    },
    set_links_data(data) {
      this.links_loaded = true
      this.links = data
    },
    set_links_error(){
      this.links_loaded = true
      this.links_load_error = true
    },
  }
}
</script>

