import Vue from 'vue/dist/vue.esm'

Vue.component(
  'documents-detail-vue',
  () => import(/* webpackChunkName: "[request]" */ "components/documents/Detail")
)
Vue.component(
  'documents-search-bar-vue',
  () => import(/* webpackChunkName: "[request]" */ "components/documents/SearchBar")
)
Vue.component(
  'documents-options-button-vue',
  () => import(/* webpackChunkName: "[request]" */ "components/documents/OptionsButton")
)
Vue.component(
  'documents-font-size-button-vue',
  () => import(/* webpackChunkName: "[request]" */ "components/documents/FontSizeButton")
)
Vue.component(
  "hubs-filters-vue",
  () => import(/* webpackChunkName: "[request]" */ "components/hubs/Filters")
)
