let url = () => { return `/base/${gon.current_app}/user_subscriptions/calendars` }
let url_for_public_calendar = (url) => {
  return `/base/${gon.current_app}/user_subscriptions/calendars/public_calendar?url=${url}`
}

const state = {
  calendars: [],
  loading: false,
  errors: []
}

const getters = {
  editable_calendars({calendars}) {
    return calendars.filter(calendar => calendar.type == "data")
  },
  calendars_length({calendars}) {
    return calendars.length
  },
  calendars({calendars}) {
    return () => calendars
  },
  events_for_calendar({calendars}) {
    return (calendar_id) => {
      let calendar = calendars.find(calendar => calendar.id === calendar_id)
      return calendar.events
    }
  },
  event_sources({calendars}) {
    return (selected_calendars) => {
      var sources = [];
      calendars.forEach((calendar) => {
        if (!selected_calendars.some(cal => cal.id === calendar.id))
          return;
        if (calendar.type == "ics") {
          sources.push({
            url: url_for_public_calendar(calendar.url),
            format: "ics",
          });
        } else {
          sources.push(
            calendar.events.map((event) => {
              let new_event = { color: calendar.color, calendar_id: calendar.id }
              Object.keys(event).map((key) => {
                if (key == "start" || key == "end") new_event[key] = new Date(event[key])
                if (key == "allDay") new_event[key] = event[key] == "true"
                else new_event[key] = event[key]
              })
              return new_event
            })
          );
        }
      });
      return sources;
    }
  }
}

const mutations = {
  set_calendars(state, data) { state.calendars = data },
  clear_errors(state) { state.errors = [] },
  start_loading(state) { state.loading = true },
  end_loading(state) { state.loading = false },
  add_calendar(state, data) { state.calendars.push(data) },
  add_error(state, data) { state.errors.push(data) },
  update_calendar(state, data) {
    var calendar = state.calendars.find((c) => { return c.id == data.id })
    if (!calendar) return

    if (data.name != undefined) { calendar.name = data.name }
    if (data.type != undefined) { calendar.type = data.type }
    if (data.url != undefined) { calendar.url = data.url }
    if (data.events != undefined) { calendar.events = data.events }
    if (data.color != undefined) { calendar.color = data.color }
  },
  remove_calendar(state, data) {
    state.calendars.splice(state.calendars.indexOf(data), 1)
  },
  add_event_to_calendar(state, data) {
    var calendar = state.calendars.find((c) => { return c.id == data.calendar_id })
    calendar.events = [...calendar.events, data.event]
  },
  update_event(state, data) {
    let calendar = state.calendars.find((c) => { return c.id == data.calendar_id })
    calendar.events = calendar.events.map((e) => {
      return e.id == data.event.id ? data.event : e
    })
  },
  delete_event(state, data) {
    let calendar = state.calendars.find((c) => { return c.id == data.calendar_id })
    let position = calendar.events.findIndex(e => e.id == data.event.id)
    if (position != -1)
      calendar.events.splice(position, 1)
  },
}

const actions = {
  async load_calendars({commit}) {
    commit('clear_errors')
    commit('start_loading')

    let response = await fetch(url())
    if (response.ok) {
      commit('set_calendars', await response.json())
    }
    else
      commit('add_error', {name: 'load_calendars_failed', message: 'Falló la carga de calendarios'})

    commit('end_loading')
  },

  async create_calendar({commit}, calendar) {
    commit('clear_errors')
    commit('start_loading')

    let response = await fetch(url(), {
      method: "POST",
      headers: { "Content-Type": "application/json", "Accept": "application/json" },
      body: JSON.stringify(calendar)
    })
    if (response.ok)
      commit('add_calendar', await response.json())
    else if (response.status == 403)
      commit('add_error', {name: 'invalid_format_calendar', message: 'Formato de calendario invalido, verifica que sea de tipo iCal/ICS'})
    else
      commit('add_error', {name: 'create_calendar_failed', message: 'Falló la creación del calendario'})

    commit('end_loading')
  },

  async update_calendar({commit}, calendar) {
    commit('clear_errors')
    commit('start_loading')

    let response = await fetch(url() + `/${calendar.id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json", "Accept": "application/json" },
      body: JSON.stringify(calendar)
    })

    if (!response.ok)
      commit('add_error', {name: 'update_calendar_failed', message: 'Falló la actualización del calendario'})

    commit('end_loading')
  },

  async edit_calendar({commit, dispatch}, calendar) {
    commit('clear_errors')
    commit('update_calendar', calendar)
    dispatch("update_calendar", calendar)
  },

  async delete_calendar({commit}, calendar) {
    commit('clear_errors')
    commit('start_loading')

    let response = await fetch(url() + `/${calendar.id}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", "Accept": "application/json" },
    })
    let data = await response.json()
    if (response.ok)
      commit('remove_calendar', calendar)
    else
      commit('add_error', {name: 'delete_calendar_failed', message: data.toString()})

    commit('end_loading')
  },

  async create_event({commit, dispatch, state}, data) {
    commit('clear_errors')
    commit('add_event_to_calendar', data)
    var calendar = state.calendars.find((c) => { return c.id == data.calendar_id })
    dispatch("update_calendar", calendar)
  },

  async update_event({commit, dispatch, state}, data) {
    commit('clear_errors')
    commit('update_event', data)
    var calendar = state.calendars.find((c) => { return c.id == data.calendar_id })
    dispatch("update_calendar", calendar)
  },

  async delete_event({commit, dispatch, state}, data) {
    commit('clear_errors')
    commit("delete_event", data)
    var calendar = state.calendars.find((c) => { return c.id == data.calendar_id })
    dispatch("update_calendar", calendar)
  }
}

export default {
  namespaced: true,
  name: "calendars",
  state,
  getters,
  mutations,
  actions
}
