export default {
  props: {
    sofiaFilters: { type: Array, default: () => [] }
  },
  components: {
    'sofia-filter-modals-vue': () => import(
      /* webpackChunkName: "[request]" */ 'components/sofia/SofiaFilterModals'
    )
  },
  data() {
    return {
      saveStatusVisible: false,
      documentTitleVisible: false,
      tirantCloud: {},
      sofia: {}
    }
  },
  computed: {
    user_path() {
      return `${gon.app_tolgeo}/${this.$user_id}/${this.$subscription_id}/`
    },
    is_subscription_user() {
      return this.$subscription_id != ''
    }
  },
  methods: {
    get_sofia_token(path) {
      return this.get_service_token("sofia", { fav: path })
    },
    get_tirantcloud_token() {
      let params = {
        folder_path: this.folder_path,
        storage_type: this.storage_type || ""
      }

      return this.get_service_token("tirantcloud", params)
    },
    openSofiaFilterModal(selectedModal) {
      this.$refs['sofia-filter-modals'].showModal(
        selectedModal.tipoid,
        selectedModal.field
      )
    },
    confirmFilterValues(tipoid, name, selectedValues) {
      this.sofia.eventBus.publish('sofia:setted-filter-values', tipoid, name, selectedValues)
    }
  },
  mounted() {
    this.tirantCloud = new TirantCloud({
      lang: this.$language,
      tolgeo: gon.app_tolgeo,
      paisiso: gon.paisiso,
      baseEndpoint: this.$cloud_service_url,
      tokenFunction: this.get_tirantcloud_token,
      sofiaBaseEndpoint: this.$sofia_service_url + '/',
      sofiaTokenFunction: this.get_sofia_token,
      saveStatusVisible: this.saveStatusVisible,
      favouritesEnabled: this.is_subscription_user,
      pdfJsUrl: this.url_for_pdfjs_library(),
      isExtEditable: false,
      allowedExtensions: ['.pdf', '.txt', '.rtf', '.doc', '.docx', '.odt', '.html', '.ott', '.sxw'],
      documentLang: this.documentLang,
      showNewDocumentMenu: this.show_new_document_menu,
      documentTitleVisible: this.documentTitleVisible,
      sofiaFilters: this.sofiaFilters,
      onSofiaOpenFilterModal: this.openSofiaFilterModal
    })

    this.tirantCloud.publicEventBus.subscribe('sofia:created', (sofia) => {
      this.sofia = sofia
      this.sofia.eventBus.subscribe('sofia:modal-cleared',(tipoid, field) => {
        this.$refs['sofia-filter-modals'].clearModal(tipoid, field)
      })
    })    
  }
}
