<template>
    <div v-if="showBackdrop" id='bg-filters' class='bg-opacity' />
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'backdrop-vue',
  computed: {
    ...mapState(['showBackdrop'])
  },
};
</script>
