import validate from 'common/validations'

export default class {
  constructor(validations = []) {
    this.is = this
    this.success = true
    this.fails = false
    this.messages = {}
    this.validations = this.getValidations(validations)
  }

  do() {
    let allValidationsPassed = true

    this.validations.forEach(item => {
      [item.validates].flat().forEach(validation_name => {
        let itemValid = this.check(item.values, item.params, validation_name)
        
        if (!itemValid) {
          this.addMessage(validation_name, item.fields, item.params)
        } 

        allValidationsPassed = allValidationsPassed && itemValid
      })
    })
    
    this.success = allValidationsPassed
    this.fails = !allValidationsPassed

    return this
  }
  
  addMessage(validation_name, fields = [], params = []) {
    let message = {}
    let message_key = `not_${validation_name}_${fields}`
    let translation_key = `validations.not_${validation_name}`
    message[message_key] = I18n.t(translation_key, { fields, params })

    Object.assign(this.messages, message)
  }

  check(values = [], params = [], validation_name) {
    return validate[validation_name](values, params)
  }

  listMessages() {
    return Object.keys(this.messages)
  }

  getValidations(validations) {
    if (Array.isArray(validations)) {
      return validations
    }

    return [validations]
  }

}
