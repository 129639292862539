import ApiClient from 'common/ApiClient'

let url = (rfc_code = '') => {
  return `/base/${gon.current_app}/rfc/alerts/${rfc_code}`
}
let url_alerts_list = () => { return `/base/${gon.current_app}/rfc/alerts/list` }
let url_alerts = () => { return `/base/${gon.current_app}/rfc/alerts/id` }
let url_massive_alerts = () => { return `/base/${gon.current_app}/rfc/alerts/massive_alerts` }

const state = {
  alerts: [],
  loading: false,
  errors: {},
  actionMessages: {},
  actionErrors: {}
}

const getters = {
  alerts_length({ alerts }) {
    return alerts.length
  },
  has_errors(state) {
    return Object.keys(state.errors).length != 0
  }
}

const mutations = {
  start_loading(state) { state.loading = true },
  end_loading(state) { state.loading = false },
  add_error(state, data) { state.errors[data.key] = data.value },
  add_action_error(state, data) { state.actionErrors[data.key] = data.value },
  add_action_messages(state, data) { state.actionMessages[data.key] = data.value },
  clear_action_error(state) { state.actionErrors = {} },
  clear_action_messages(state) { state.actionMessages = {} },
  clear_errors(state) { state.errors = {} },
  add_alert(state, data) { state.alerts.push(data) },
  add_rfc_alert_list(state, data) { state.alerts = data },
}

const actions = {
  async create_alert(context, alert) {
    const apiClient = new ApiClient()

    context.commit('clear_errors')
    context.commit('start_loading')
    let response = await apiClient.post(url(), alert)

    if (response.failed)
      context.commit('add_error', { key: 'create_alert_failed', value: 'La alerta no se pudo crear, intente de nuevo.' })

    context.commit('end_loading')
  },

  async delete_alerts(context, rfc_ids) {
    context.commit('clear_errors')
    context.commit('start_loading')
    context.commit('clear_action_error')
    context.commit('clear_action_messages')

    let response = await fetch(url_alerts(), {
      method: "DELETE",
      headers: { "Content-Type": "application/json", "Accept": "application/json" },
      body: JSON.stringify({ rfc_ids: rfc_ids })
    })
    context.commit('end_loading')
    if (!response.ok) {
      context.commit('add_action_error', { key: 'deleteError', value: "Las alertas no se pudieron eliminar, intente de nuevo" })
    }
    else if (response.ok) {
      let data = await response.json()
      context.commit('add_action_messages', { key: 'successMessage', value: `Se han eliminado correctamente ${data.updates_count} alerta(s) RFC de su lista.` })
      return true
    }
  },
  async update_alert(context, params) {
    context.commit('clear_errors')
    context.commit('start_loading')
    context.commit('clear_action_error')
    context.commit('clear_action_messages')

    let response = await fetch(url_alerts(), {
      method: "PUT",
      headers: { "Content-Type": "application/json", "Accept": "application/json" },
      body: JSON.stringify(params)
    })
    context.commit('end_loading')
    if (!response.ok) {
      context.commit('add_action_error', { key: 'deleteError', value: 'La alerta no se pudo editar. Intente de nuevo.' })
    }
    else if (response.ok) {
      context.commit('add_action_messages', { key: 'successMessage', value: 'Se ha editado correctamente el nombre de la alerta' })
      return true
    }
  },

  async create_massive_alerts(context, csvFile) {
    context.commit('clear_errors')
    context.commit('start_loading')
    context.commit('clear_action_error')
    context.commit('clear_action_messages')

    let formData = new FormData()
    formData.append("file", csvFile)

    let response = await fetch(url_massive_alerts(), {
      method: "POST",
      body: formData
    })
    if (!response.ok) {
      if (response.status == 422) {
        let data = await response.json()
        if (data.rows_repeated != '') {
          context.commit('add_action_error', { key: 'massive_rows_repeated', value: data.rows_repeated })
        }
        if (data.rows_bad_format != '') {
          context.commit('add_action_error', { key: 'massive_rows_bad_format', value: data.rows_bad_format })
        }
        if (data.error != 'bodyCSV') {
          context.commit('add_action_error', { key: 'headerError', value: data.error })
        }
        if (data.rows_created != '') {
          context.commit('add_action_messages', { key: 'successMessage', value: data.rows_created })
        }
        context.commit('end_loading')
        return data.num_created
      } else {
        context.commit('add_error', {
          key: 'server_error',
          value: 'En este momento no podemos atender su petición, intentelo mas tarde o contacte con el servicio al cliente'
        })
      }
    } else {
      let data = await response.json()
      context.commit('add_action_messages', { key: 'successMessage', value: data.rows_created })
      context.commit('end_loading')
      return data.num_created
    }
  },
  async load_rfc_alert(context, rfc_code) {
    context.commit('clear_errors')
    context.commit('start_loading')

    let response = await fetch(url(rfc_code))

    if (response.ok)
      context.commit('add_rfc_search_result', await response.json())
    else
      context.commit('add_error', 'search_rfc_failed')

    context.commit('end_loading')
  },

  async load_alerts(context) {
    context.commit('start_loading')
    let response = await fetch(url_alerts_list(), {
      method: "GET",
      headers: { "Content-Type": "application/json", "Accept": "application/json" },
    })
    if (response.ok) {
      context.commit('add_rfc_alert_list', await response.json())
    }
    else {
      context.commit('add_error', {
        key: 'list_rfc_failed',
        value: 'En este momento no podemos atender su petición, intentelo mas tarde o contacte con el servicio al cliente'
      })
    }
    context.commit('end_loading')
  },
}

export default {
  namespaced: true,
  name: "rfc_alerts",
  state,
  getters,
  mutations,
  actions
}
