const formatNumberTol = (tolgeo, docid) => {
  return docidPrefix(tolgeo) + parseInt(docid).toLocaleString()
}

const docidPrefix = (tolgeo) => {
  switch (tolgeo) {
    case 'latam':
      return 'LTM'
    case 'mex':
      return 'TMX'
    default:
      return 'TOL'
  }
}

export { formatNumberTol, docidPrefix }
