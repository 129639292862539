import Vue from "vue";

export default {
  data() {
    return {
      bus: new Vue(),
      uploaded_file: [],
      data64: null,
      error: false,
      creating_file: false,
      error_upload: false,
      selected_file: undefined,
      uploading_file: false,
      dragged_status_class: "",
      time_out_request: false,
      redirect: true
    };
  },
  methods: {
    validate_file_name(file_name) {
      return /\.(pdf|txt|rtf|doc|docx|odt|html)$/i.test(file_name)
    },
    new_document(text = ''){
      this.error = false
      this.error_upload = false
      this.time_out_request = true
      this.creating_file = true;
      this.fetch_upload_request({}, this.redirect, true, text)
    },
    onChange(event) {
      let candidate_file = "";
      if (event.type == "change") {
        candidate_file = event.target.files[0];
      } else if (event.type == "drop") {
        candidate_file = event.dataTransfer.files[0];
      }
      if (this.validate_file_name(candidate_file.name)) {
        this.error_upload = false;
        this.error = false;
        this.selected_file = candidate_file
        this.try_analize()
      } else {
        this.error = true;
        this.selected_file = undefined;
      }
    },
    async try_analize() {
      this.uploading_file = true
      await new Promise(resolve => setTimeout(resolve, 3000));
      if(this.uploading_file && this.selected_file){
         this.time_out_request = true
         this.fetch_upload_request(this.selected_file, this.redirect)
      }
    },
    cancel() {
      this.uploading_file = false
      this.selected_file = false
      this.error_upload = false
      this.time_out_request = false
    },
    drag_over(event) {
      this.error_upload = false
      this.error = false
      event.preventDefault();
      this.dragged_status_class = "dragged";
    },
    drag_leave() {
      this.dragged_status_class = "";
    },
    drop(event) {
      event.preventDefault();
      this.onChange(event);
      this.dragged_status_class = "";
    },
  },
  computed: {
    is_selected_file() {
      return !!this.selected_file
    },
    sofia_file_uploader_container_class() {
      let selected_file_class = this.is_selected_file ? "selected-file" : ""

      return this.dragged_status_class + selected_file_class
    },
    uploading_file_text(){
      if(this.creating_file){
        return I18n.t("components.sofia_file_analyzer.creating")
      }
      else{
        return I18n.t("components.sofia_file_analyzer.redirecting")
      }
    }
  }
}