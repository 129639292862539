<template>
  <div class="per_data_form">
    <h3 class="title">{{ t("user_subscriptions.password_change.title", { brand: app_brand }) }}</h3>
    <h5 class="text-info">{{ t("user_subscriptions.password_change.subtitle") }}</h5>

    <forms-messages-list-vue
      :messages= notifications
      :notification_message="true"
    />

    <forms-password-input-vue v-model="new_password" @keyup="clearErrors" />

    <forms-messages-list-vue
      :messages= errors
    />

    <button
      class="btn btn-primary accept_button"
      id="send_request"
      @click="validateForm"
      :disabled="sending_request"
    >
      {{ t(text_value) }}
    </button>
  </div>
</template>

<script>
import mixins from "common/mixins"
import Validator from "common/Validator"
import constants from "common/constants"

export default {
  components: { 
    "forms-messages-list-vue": () => import(/* webpackChunkName: "[request]" */ "components/forms/MessagesList"),
    "forms-password-input-vue": () => import(/* webpackChunkName: "[request]" */ "components/forms/PasswordInput")
  },
  mixins: [mixins.requests, mixins.translate],
  name: "user-subscription-password-change-vue",
  props: ['token'],
  data() {
    return {
      new_password: "",
      errors: {},
      notifications: {},
      sending_request: false,
      text_value: "user_subscriptions.password_change.send",
      password_min_length: constants.PASSWORD_MIN_LENGTH,
      password_max_length: constants.PASSWORD_MAX_LENGTH
    };
  },

  methods: {
    validateForm() {
      this.clearErrors();
      this.clearNotifications();

      let validator = new Validator([
        {
          fields: ["password"],
          values: [this.new_password],
          params: [constants.PASSWORD_MIN_LENGTH, constants.PASSWORD_MAX_LENGTH],
          validates: "length_between",
        },
        {
          fields: ["password"],
          values: [this.new_password],
          validates: "no_blanks",
        },
      ]).do();

      if (!validator.success) {
        this.setErrors(validator.messages);
        return;
      }

      this.requestSave();
    },

    requestSave() {
      this.sending_request=true;
      this.text_value = "user_subscriptions.password_change.sending",
      this.http_post({
        url: this.url_for_user_subscriptions_password_change({
          password: this.new_password,
          token: this.token
        }),
        success: (data) => {
          this.resetInputs()
          this.sending_request= false
          this.text_value = "user_subscriptions.password_change.send"
          window.location.assign(
            this.url_for_password_change_result({
              success: data.body.success.toString()
            })
          )
        },
        error: () => {
          this.sending_request= false
          this.text_value = "user_subscriptions.password_change.send"
        },
      });
    },

    clearErrors() {
      this.setErrors({});
    },

    setErrors(errors) {
      this.errors = errors;
    },

    addError(error) {
      let errors = Object.assign(error, this.errors);
      this.setErrors(errors);
    },

    setNotifications(notifications) {
      this.notifications = notifications;
    },

    clearNotifications() {
      this.setNotifications({});
    },

    addNotification(notification) {
      let notifications = Object.assign(notification, this.notifications);
      this.setNotifications(notifications);
    },

    resetInputs() {
      this.new_password= "";
    },
  },
};
</script>
