<template>
  <div>
    <input type="text" id="autocomplete" v-model="autocomplete" v-on:keyup="do_autocomplete">
    <div id="suggestions" v-show="suggestions_visible">
      <div class="suggestion" v-for="suggestion in suggestions" @click="select_suggestion(suggestion)">
        {{suggestion.name}}
      </div>
    </div>
  </div>
</template>
<script>
  import mixins from 'common/mixins.js'
  import filters from 'common/filters.js'
  import { directive as onClickaway } from 'vue-clickaway';

  export default {
    props: ['field', 'tipoid'],
    data() {
      return {
        selected: '',
        autocomplete: '',
        suggestions: []
      }
    },
    directives: {
      onClickaway: onClickaway,
    },
    filters: {
      highlight_substring : filters.highlight_substring,
    },
    computed: {
      suggestions_visible() {
        return this.suggestions.length > 0
      }
    },
    methods: {
      do_autocomplete() {
        if (this.autocomplete.length > 2) {
          this.fetch_suggestions()
        } else {
          this.clear_suggestions()
        }
      },
      fetch_suggestions() {
        this.http_get({
          url: this.url_for_filter_value_index({facetname: this.field, q: this.text_to_regextext(this.autocomplete), tipoid: this.tipoid}),
          success: this.load_suggestions
        })
      },
      load_suggestions(response) {
        if(response.data) {
          this.suggestions = response.data
        }
      },
      select_suggestion(suggestion) {
        this.autocomplete = suggestion.name
        this.selected = suggestion.id
        this.clear_suggestions()
      },
      clear_suggestions() {
        this.suggestions = []
      },
      clean() {
        this.autocomplete = ''
        this.selected = ''
        this.clear_suggestions()
      }
    },
    mixins: [
      mixins.requests,
      mixins.regex_utils
    ]
  }
</script>