import TotalDocumentsService from "common/services/update_total_documents"
import ApiClient from 'common/ApiClient'


const state = {
  hubs: [],
  filteredHubs: [],
  recentHubs: [],
  totalDocuments: 0,
  loading: false,
  ambitsSelected: [],
  hubsNodes: [],
  collapsePanel: false,
  fromTemas: false
}

var timeOutId

const getters = {
  totalDocuments(state){
    return state.filteredHubs.reduce((total, hub) => {
      return total + hub.total
    }, 0)
  },
  showRecentHubs(state){
    return state.recentHubs.length > 2 && ( state.filteredHubs.length == state.hubs.length )
  }
}

const mutations = {
  setHubs(state, data) { state.hubs = data },
  setFilteredHubs(state, data) { state.filteredHubs = data},
  setRecentHubs(state, data) { state.recentHubs = data },
  setTotalDocuments(state, data) { state.totalDocuments = data },
  setLoading(state, data) { state.loading = data },
  setSelectedAmbits(state, data) { state.ambitsSelected = data },
  pushHubNode(state, data) { state.hubsNodes.push(data) },
  setCollapsePanel(state, data) { state.collapsePanel = data },
  setFromTemas(state, data) { state.fromTemas = data }
}

const actions = {
  filterHubs(context, ambitsSelected) {
    if (ambitsSelected.length == 0) {
      context.commit('setFilteredHubs', state.hubs)
    } else {
      let filtered = state.hubs.filter(hub => hub.ambitos.some(e => ambitsSelected.includes(e)))
      context.commit('setFilteredHubs', filtered)
    }
  },
  retrieveRecentHubs(context, hubIds){
    let recentHubs = []
    hubIds.forEach(hub_id => {
      let hub = state.hubs.find(hub => hub.id == hub_id)
      if(hub) recentHubs.push(hub)
    })
    context.commit('setRecentHubs', recentHubs)
  },
  async fetchTotalDocument(context, ambitsSelected) {
    let totalDocumentsService = new TotalDocumentsService()
    let params = { 'ambitos': ambitsSelected }
    const url_base = `${gon.url_for.total_documents_searches_path.replace(':search_type', 'formularios')}`
    clearTimeout(timeOutId)

    timeOutId = setTimeout(async () => {
      try {
        let response = await totalDocumentsService.call(url_base, params)
        context.commit('setTotalDocuments', response)
        state.loading = false
      } catch (error) {
        console.error('Error fetching total documents:', error)
      }
    }, 1200)
  },
  async fetchHubNode(context, hubFilter) {
    const api = new ApiClient()
    let url = `${gon.url_for.hubs_indice_path.replace(':id', hubFilter.id)}?ambito_hub=${hubFilter.ambito}`
    let response = await api.get(url)

    if(response.ok){
      context.commit('pushHubNode', response.data)
    }
  }
}

export default {
  namespaced: true,
  name: "hub_search",
  state,
  getters,
  mutations,
  actions
}
