import Vue from 'vue/dist/vue.esm'

Vue.component("search-alphabetical-facet-vue", () => import(/* webpackChunkName: "[request]" */ "components/search/AlphabeticalFacet"))

Vue.component("forms-letter-selector-vue", () => import(/* webpackChunkName: "[request]" */ "components/forms/LetterSelector"))

Vue.component("forms-navigation-list-vue", () => import(/* webpackChunkName: "[request]" */ "components/forms/NavigationList"))

Vue.component("forms-options-selector-vue", () => import(/* webpackChunkName: "[request]" */ "components/forms/OptionsSelector"))

Vue.component("breadcrumb-vue", () => import(/* webpackChunkName: "[request]" */ "components/Breadcrumb"))
