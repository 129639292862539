<template lang="pug">
p.text-center
  span
    | {{t('user_subscriptions.home.drop_out_1')}}
  a.font-weight-bold(:href="url_for_user_subscription_drop_out()")
    span.color-primary.cursor-pointer
    | {{t('user_subscriptions.home.drop_out_link')}}
  span
    | {{t('user_subscriptions.home.drop_out_2')}}
</template>

<script>
  import mixins from "common/mixins"

  export default {
    mixins: [mixins.translate, mixins.requests],
    name: "drop-out-text-vue",
  }
</script>