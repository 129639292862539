<template>
<div>
  {{index}}
  {{numeroTOL}}
  <a id="title" :href="document_url">{{ title }}</a>
  <div id="buttons" v-show="buttons_visible">
    <button id="show_quick_view" type="button" @click="showQuickView(id)" v-show="quickViewVisible"/>
    <button id="options_button" class=".dropdown-toggle" type="button" data-toggle="dropdown"/>

    <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm">
      <a id="download_option" href="#" class="dropdown-item" @click="show_options('download')"/>
      <a id="print_option" href="#" class="dropdown-item" @click="show_options('print')"/>
      <a id="share_option" href="#" class="dropdown-item" @click="show_options('share')"/>
      <a id="save_option" v-show="saveOptionVisible" href="#" class="dropdown-item" @click='save_document'/>
    </div>
  </div>

  <documents-options-modal-vue ref="options_modal" />

  <div id="modal_user_subscriptions_case">
      <button class="btn btn-primary" id="save_case" type="button">Guardar</button>
  </div>

  <div id="list_fields">
    <li class="list_field" :class="list_field_class(field)" :key="field.id" v-for="field in list_fields" />
  </div>

  <documents-metadata-vue v-if="has_metadata_fields" :fields="metadata_fields" :search-attributes="searchAttributes" />
  <modal-quick-view-vue :bus="quickViewBus" :copy-buttons-visible="false" :search-type='searchAttributes.search_type' :cleaned-tokens="cleaned_tokens" />
</div>
</template>

<script>
import mixins from 'common/mixins.js'
import filters from 'common/filters.js'
import document_mixin from 'common/mixins/document_mixin.js'
import Vue from "vue"

const COVER_PATH = 'https://editorial.tirant.com/low'

export default {
  name: 'documents-item-vue',
  props: {
    index: { type: Number, default: 0 },
    num_found: { type: Number, default: 0 },
    searchAttributes: { type: Object, default: () => { return {} } },
    cleaned_tokens: { type: Array, default: () => [] },
    search_tokens: { type: Array, default: () => [] },
    token_id: { type: String, default: '' },
    highlight: { type: Array, default: () => [] },
    tolgeo: { type: String, default: '' },
    hide_save_document: { type: Boolean, default: false },
    hide_quick_view: { type: Boolean, default: false }
  },
  components: {
    'documents-metadata-vue': () => import(/* webpackChunkName: "[request]" */ "components/documents/Metadata"),
    'modal-quick-view-vue': () => import(/* webpackChunkName: "[request]" */ "components/ModalQuickView"),
    'documents-resumen-auto-fields-vue': () => import(/* webpackChunkName: "[request]" */ "components/documents/ResumenAutoFields"),
    'documents-options-modal-vue': () => import(/* webpackChunkName: "[request]" */ "components/documents/OptionsModal")
  },
  data() {
    return {
      quickViewBus: new Vue(),
      current_body_fields: this.body_fields || [],
      current_header_fields: [],
      current_indice_fields: [],
      current_selected_option: this.selected_option,
      loading_body_fields: false,
      cover_url: this.format_cover_url(this.cover)
    }
  },
  computed: {
    doc_pie_class() {
      if(this.has_highlight)
        return 'doc_pie--highlight'
      return ''
    },
    show_map() {
      return this.clazz == 'Jurisprudencia' && this.is_tolgeo_mex
    },
    art_url() {
      let art_num = this.indice_virtual.art_num
      let hashtag
      if(art_num)
        hashtag = `art_${art_num}`
      else
        hashtag = this.indice_virtual.id
      return `${this.url_for_grails_documento_show(this.id)}#${hashtag}`
    },
    index_is_odd() {
      return (this.index % 2) != 0
    },
    index_identifier() {
      return `doc_${this.index}`
    },
    text_to_share() {
      return `${this.share_text}\n\n${this.$grails_host}${this.url_for_get_file_without_params}`
    },
    concepts_visible() {
      return this.mine != undefined && this.mine["doc-semanticas_tes"] != undefined && this.mine["doc-semanticas_tes"].length > 0
    },
    mine_concepts() {
      if (!this.concepts_visible)
        return ''

      let concepts = this.mine["doc-semanticas_tes"].sort((a, b) => { return b.s - a.s })
      let concatConcepts = concepts.slice(0, 3).map(concept => concept.v).join(', ').replaceAll('-',' ')

      return (concatConcepts.replace(/(^\w)/g, m => m.toUpperCase()))
    },
    mineResumen() {
      return (this.mine || {})['resumen']
    },
    has_file_fields() {
      return (this.file_fields || []).length > 0
    },
    has_metadata_fields() {
      return this.metadata_fields.length > 0
    },
    buttons_visible() {
      return this.clazz != 'bibliotecavirtual' && this.clazz != 'TipoDoctrina'
    },
    hasSearchTokens() {
      return this.search_tokens.length > 0
    },
    has_highlight() {
      return (this.highlight || []).length > 0
    },
    is_derogada() {
      return this.list_fields.some(field => field.id === 'derogada')
    },
    only_derogada(){
      return this.list_fields.length == 1 && this.is_derogada
    },
    document_url() {
      let url = ''

      if (this.clazz == 'TipoDoctrina') {
        if (this.group_ids.length == 1)
          url = this.url_for_grails_documento_show(this.group_ids[0], {
            librodoctrina: this.id, general: this.search_tokens.join(' ')
          })
        else
          url = this.url_for_librodoctrinas_show(this.id, {token_id: this.token_id})
      } else if (this.token_id) {
        let params = {
          token_id: this.token_id,
          index: this.index,
          docid: this.id,
        }
        url = this.tolweb_url_for_search_navigate(this.searchAttributes.search_type, params);
      } else {
        url = this.url_for_grails_documento_show(this.id)
      }

      return url
    },
    has_indice_virtual() {
      return this.indice_virtual && this.indice_virtual.name && this.indice_virtual.description
    },
    optionsModal() {
      var modal = this.$refs.options_modal
      return Array.isArray(modal) ? modal[0] : modal
    },
    saveOptionVisible() {
      return !this.hide_save_document
    },
    classItemDetails(){
      if(this.has_highlight){
        return ''
      }
      return 'flex-lg-row'
    },
    hubExists(){
      return gon.controller_name == 'indice' && gon.current_id != undefined
    },
    quickViewVisible(){
      return !this.hide_quick_view
    }
  },
  methods: {
    showQuickView(id){
      this.quickViewBus.$emit("document_show", String(id), '')
    },
    show_options(mode='download') {
      this.optionsModal.showModal()
      this.optionsModal.selectOption(mode)
      this.fetch_body_fields()
    },
    hide_options() {
      this.optionsModal.hideModal()
    },
    format_highlight(html) {
      return `... ${html} ...`
    },
    fetch_body_fields() {
      if (this.current_body_fields.length == 0) {
        this.loading_body_fields = true
        this.http_get({
          url: this.url_for_search_show(this.id),
          success: this.map_body_fields,
          fail: this.close_all
        })
      }
    },
    map_body_fields(response) {
      this.loading_body_fields = false
      if (response.data) {
        this.current_body_fields = response.data.body_fields
        this.current_header_fields = response.data.header_fields
        this.current_indice_fields = response.data.indice_fields
      } else {
        alert('Error al recuperar las secciones del documento')
      }
    },
    close_all() {
      this.loading_body_fields = false
      this.optionsModal.hideModal()
    },
    save_document(){
      this.$emit('save:document', {"document[idTol]": this.id, "document[formattedTol]": this.numeroTOL});
    },
    format_cover_url(file_name) {
      if(this.searchAttributes.search_type == 'bibliotecavirtual'){
        return file_name
      }
      return `${COVER_PATH}/${file_name}.jpg`
    },
    set_default_cover_url() {
      var file_name = 'notavailable'
      var editorial = this.list_fields.find((field) => { return field.id == 'editorial' })
      if (editorial && editorial.value == 'Palestra')
        file_name = 'notavailable-palestra'
      this.cover_url = this.format_cover_url(file_name)
    },
    separatorVisible(index) {
      var cleanedFields = this.list_fields.filter((val) => { return val.value_type != "boolean" })
      return index < cleanedFields.length - 1
    },
    list_field_class(field) {
      if (field.normalized_value)
        return `list_field_${field.id}_${field.normalized_value}`
      else
        return ''
    }
  },
  filters: {
    char_limit : filters.char_limit,
    clean_bolds: filters.clean_bolds,
    highlight : filters.highlight,
    parse_format_date: filters.parse_format_date,
    remove_intro_chars: filters.remove_intro_chars,
    format_field_value_to_string: filters.format_field_value_to_string,
    limit_string_length: filters.limit_string_length
  },
  mixins: [mixins.internationalization, document_mixin, mixins.translate]
}
</script>

<style scoped>

</style>
