$(document).ready(function () {
  $('#call_back_form').on('submit', function (e) {
    e.preventDefault()
    if ($('#call_back_policy')[0].checked) {
      $('#call_back_error').hide()
      this.submit()
      $('#call_back_modal').find('.close').click()
    } else {
      $('#call_back_error').show()
    }
  })
})
