<template>
  <div>
      <!-- These 2 first inputs are here to avoid the browser autocomplete -->
    <div v-if="!autocomplete">
      <input type="text" style="display:none" />
      <input type="password" style="display:none" />
    </div>
    <div class="input-group" :class="custom_class">
      <input type="password" :name="input_name" class="form-control" id="input_password" :placeholder="password_place_holder" v-model="localValue" 
        :maxlength="password_max_length" @keyup="keyup()" />
      <div class="input-group-append">
        <i class="far input-group-text show-password-icon" :class="show_icon" @click="toogle_psw_visibility($event)"></i>
      </div>
    </div>
  </div>
</template>

<script>

import constants from "common/constants.js"
import customVmodel from "common/mixins/custom_vmodel"


export default {
  name: "forms-password-input-vue",
  mixins: [customVmodel],
  props: {
    custom_class: { type: String, default: "mb-3" }, 
    input_name: { type: String, default: "" },
    autocomplete: { type: Boolean, default: false }
  },
  data () {
    return {
      password_visibility: false,
      password_max_length: constants.PASSWORD_MAX_LENGTH,
      password_place_holder: I18n.t('user_presubscriptions.fields.password'),
    }
  },
  methods: {
    toogle_psw_visibility(event) {
      let psw_input = event.target.parentElement.previousElementSibling
      this.password_visibility = (psw_input.type === "password")

      if (this.password_visibility) {
        psw_input.type = "text";
      } else {
        psw_input.type = "password";
      }
    },
    keyup(event){
      this.$emit('keyup', event);
    }
  },
  computed: {
    show_icon() {
      if (this.password_visibility) {
        return 'fa-eye-slash'
      }
      return 'fa-eye'
    }  
  },  
}
</script>