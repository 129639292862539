<template lang="pug">
.col-md-2.vertical-menu.px-0
  h3(id="lateral_menu_title")
    | {{title}}
  ul.nav.pl-3(id="menuContainer")
    li(v-for="item in menuItems" :key="item.name" :class="item.class")
      a.nav-link(:href="item.url")
        span(:data-cy="'sidebar-item-' + item.name")
          | {{t(`menu.user_subscription.${item.name}`)}}
          div(v-if="item.svg_icon")
            img(:src="getImgUrl(item.svg_icon)")
  a.per_exit(:href="url_for_user_subscription_log_out()")
    | {{t("menu.user_subscription.logout")}}
    i.fas.fa-sign-out-alt
</template>

<script>
import mixins from "common/mixins"

export default {
  mixins: [mixins.translate, mixins.requests],
  name: "user-subscription-lateral-menu-vue",
  props: {
    menuItems: { type: Array, default: () => [] },
    title: { type: String, default: '' }
  },
  methods: {
    getImgUrl(imgName) {
      return require('../../../assets/images/'+ imgName )
    }
  }
}

</script>
