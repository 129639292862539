<template>
  <div v-if="texts_loaded" id="lopd_container" class="form-check">
    <label class="form-check-label">
      <input
        aria-label="lopd_privacy"
        value="1"
        type="checkbox"
        v-model="lopd_privacy"
        class="form-check-input"
      />
      <span class="checkbox-label" v-html="texts.actions.main" />
    </label>
    <span
      role="button"
      aria-label="expand_lopd"
      @click="toggleConfig"
      class="expand-lopd cursor-pointer"
    >
      {{ t("sofia.lopd.configurar") }}
    </span>

    <div id="lopd_configuration_box" v-show="config_visible" class="card card-body">
      <div class="form-row lopd_text">
        <div class="col-sm-12 mt-3">
          <p v-for="item in texts.top" v-html="item"></p>
          <div class="form-check">
            <label class="form-check-label">
              <input
                aria-label="lopd_group"
                type="checkbox"
                value="1"
                v-model="lopd_group"
                class="form-check-input"
              />
              <span class="checkbox-label" v-html="texts.actions.personal_data" />
            </label>
          </div>
          <div class="form-check mt-2 mb-4">
            <label class="form-check-label">
              <input
                aria-label="lopd_comercial"
                type="checkbox"
                value="1"
                v-model="lopd_publicity"
                class="form-check-input"
              />
              <span class="checkbox-label" v-html="texts.actions.commercial" />
            </label>
          </div>
          <p v-for="item in texts.bottom" v-html="item"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "common/mixins"
import { mapState, mapGetters, mapActions , mapMutations } from "vuex"

export default {
  name: 'forms-lopd-text-vue',
  mixins: [mixins.translate, mixins.requests],
  data: () => ({ config_visible: false }),
  computed: {
    ...mapState('lopd_texts',
      [ 
        "texts",
        "texts_loaded",
        "lopd_privacy",
        "lopd_group",
        "lopd_publicity",
      ]), 
    ...mapGetters('lopd_texts', [
      'privacy',
      'group',
      'publicity'
    ]),
    lopd_privacy: {
      get() { return this.privacy },
      set() { this.togglePrivacy() }
    },
    lopd_group: {
      get() { return this.group },
      set() { this.toggleGroup() }
    }, 
    lopd_publicity: {
      get() { return this.publicity },
      set() { this.togglePublicity() }
    },
  },
  methods: {
    toggleConfig() {
      this.config_visible = !this.config_visible
    },
    ...mapMutations('lopd_texts', [
      'togglePrivacy',
      'toggleGroup',
      'togglePublicity'
    ]),
    ...mapActions('lopd_texts', ['loadTexts'])
  },
  created() {
    this.loadTexts()
  }
}
</script>
