<template>
  <div>
    <input
      :name="input_formatted_name"
      type="text"
      class="form-control"
      aria-describedby="emailHelp"
      v-model="value_field"
      @input="handleInputChange"
      v-on:keydown.enter="handleEnter"
      :placeholder="placeholder"
      :class="button_classes"
    />
    <forms-messages-list-vue
      id="errors"
      :messages="errors"
      :single_message="true"
    />
  </div>
</template>

<script>
import Vue from "vue"
import Validator from "common/Validator"

export default {
  name: "forms-generic-input-vue",
  props: {
    input_type: { type: String, default: "" },
    input_name: { type: String, default: "" },
    input_field: { type: String, default: "" },
    form_name: { type: String, default: "" },
    default_value: { type: String, default: "" },
    bus: { type: Object, default: () => new Vue()},
    auto_validates: { type: Boolean, default: true },
    button_classes: { type: Array, default: () => [] }
  },
  data() {
    return {
      value_field: this.default_value,
      errors: {},
      placeholder: I18n.t(this.input_field)
    }
  },

  components: {
    "forms-messages-list-vue": () => import(/* webpackChunkName: "[request]" */ "components/forms/MessagesList")
  },

  methods: {
    handleInputChange() {
      this.$emit("input_changed", this.value_field)
      if (this.auto_validates && this.value_field != "") {
        this.validateFormat()
      }
    },
    handleEnter(){
      this.$emit("enter:pressed")
    },
    validateFormat() {
      this.clearErrors()
      let validator = new Validator([
        {
          fields: this.input_name,
          values: this.value_field,
          validates: this.input_type,
        },
      ]).do()

      if (validator.fails) {
        this.setErrors(validator.messages)
      }
    },
    setErrors(errors) {
      this.errors = errors
    },
    clearErrors() {
      this.setErrors({})
    },
  },
  computed: {
    input_formatted_name() {
      if (this.form_name) {
        return `${this.form_name}[${this.input_field}]`
      } else {
        return this.input_field
      }
    }
  },
  watch:{
    default_value(){
      this.value_field = this.default_value
    }
  },
  created() {
    if (this.default_value != ""){
      this.validateFormat()
      this.$emit("input_changed", this.value_field)
    }
    this.bus.$on("validate_input", () => {
      this.validateFormat()
    })
  },
}
</script>
