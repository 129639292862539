<template>
  <a class="text-decoration-none" :href="url_for_sofia_file_analyzer()">
    <div :class="brand_style" h>
      <div class="brand-sofia-logo">
        <img
          class="sofia-icon"
          src="../../../assets/images/sofia/sofia-color.png"
        />
      </div>
      <div class="brand-sofia-text">
        <span class="brand-sofia-title">
          {{ t("components.sofia.brand.title") }}:
        </span>
        {{ t("components.sofia.brand.slogan") }}
      </div>
    </div>
  </a>
</template>

<script>
import mixins from "common/mixins";

export default {
  name: 'sofia-brand-vue',
  props: {
    size: { type: String, default: ""}
  },
  mixins: [mixins.translate, mixins.requests],
  computed: {
    brand_style(){
      return "brand-sofia " + this.size
    }
  }
}
</script>
