// NOTA: esta librería está copiada desde app/assets/javascripts/charts/tirant_bar_chart.js
// se ha adaptado para poderla utilizar como un módulo de webpack
import * as d3 from 'd3'
import dimple from 'dimple-js/dist/dimple.latest.min'

function TirantBarChart(options) {
  this.data = options.data;
  this.chartContainer = options.chartContainer;
  this.options = options.options || {};
  this.options.chart_type = options.chart_type || dimple.plot.bar;
  this.handlers = options.handlers || {};
  this.locale = options.locale || 'es';
  this.left_margin = 60;
  this.top_margin = 15;
  this.right_margin = 40;
  this.bottom_margin = 50;
}

TirantBarChart.prototype.init = function() {
  var self = this;

  self.setLocale()
  self.tooltipFunction = this.defaultTooltipFunction
  if (self.handlers.tooltip != null)
    self.tooltipFunction = self.handlers.tooltip

  self.clickFunction = this.defaultClickFunction
  if (self.handlers.click != null)
    self.clickFunction = self.handlers.click

  self.mouseoverFunction = this.defaultMouseoverFunction
  if (self.handlers.mouseover != null)
    self.mouseoverFunction = self.handlers.mouseover

  self.mouseleaveFunction = this.defaultMouseleaveFunction
  if (self.handlers.mouseleave != null)
    self.mouseleaveFunction = self.handlers.mouseleave

  self.createGraph();
}

TirantBarChart.prototype.buildDataNames = function(chart) {
  var self = this;

  self.label_names = []
  self.serie_names = [];
  $.each(self.data, function(index, element) {
    if (self.label_names.indexOf(element['label']) < 0)
      self.label_names.push(element['label']);

    if (self.options['series']) {
      if (self.serie_names.indexOf(element['serie']) < 0)
        self.serie_names.push(element['serie']);
    }
  });
}

TirantBarChart.prototype.calculateGraphHeight = function(data) {
  var self = this;
  var height
  if (self.options['horizontal']){
    var barGroupHeight = self.options['series'] ? self.serie_names.length*22 : 32
    var label_spacing = self.options['series'] ? (self.label_names.length - 1) * 8 + 45 : 0
    height = self.label_names.length * barGroupHeight + label_spacing + self.top_margin + self.bottom_margin;
  } else if (self.options['chart_type'] == dimple.plot.pie) {
    height = 250;
  } else {
    height = 400;
  }
  return self.options['max_height'] ? Math.min(height, self.options['max_height']) : height;
}

TirantBarChart.prototype.setBounds = function(chart) {
  var self = this;

  if (self.options['horizontal']) {
    if (self.options['inside_labels'])
      self.left_margin = 10
    else
      self.left_margin = 200
  } else if (self.options['chart_type'] == dimple.plot.pie){
    self.left_margin = 40
  }
  
  if (self.options['legend'] == 'top')
    self.top_margin = self.serie_names.length * 17 + self.top_margin + 30
  else if (self.options['legend'] == 'bottom')
    self.bottom_margin = 160
  else if (self.options['legend'] == 'right')
    self.right_margin = 350

  chart.setMargins(self.left_margin, self.top_margin, self.right_margin, self.bottom_margin)
}

TirantBarChart.prototype.setAxisX = function(chart) {
  var self = this;
  var x;

  if (self.options['horizontal']){
    x = chart.addMeasureAxis("x", "value");
    x.tickFormat = ',.0f';
  }
  else{
    var field = 'label'
    if (self.options['series'] && self.options['chart_type'] != dimple.plot.line)
      field = ['label','serie']

    x = chart.addCategoryAxis("x", field);
  }
  if (self.options['hide_x'])
    x.hidden = true;

  x.title = "";
  x.fontSize = 13;
  return x
}

TirantBarChart.prototype.setAxisY = function(chart) {
  var self = this;
  var y;

  if (self.options['horizontal']) {
    var field = 'label'
    if (self.options['series'] && self.options['chart_type'] != dimple.plot.line)
      field = ['label','serie']
    y = chart.addCategoryAxis("y", field);
  }
  else {
    y = chart.addMeasureAxis("y", "value");
    y.tickFormat = '~r';
  }

  if(self.options['title_y_axis']){
    y.title = self.options['title_y_axis'];
  } else {
    y.title = ""
  }

  y.fontSize = 13;

  if (self.options['order'] != null) {
    var desc = self.options['order'] != 'value' ? true : false;
    y.addOrderRule(self.options['order'], desc);
  } else {
    y.addOrderRule(self.label_names, true)
    if (self.options['series'])
      y.addGroupOrderRule(self.serie_names, true)
  }

  return y
}

TirantBarChart.prototype.setAxisP = function(chart) {
  var self = this;
  var p;

  p = chart.addMeasureAxis("p", "value");
  p.tickFormat = ',.0f';
  
  if(self.options['title_p_axis']){
    p.title = self.options['title_p_axis'];
  } else {
    p.title = "";
  }

  return p;
}

TirantBarChart.prototype.draw_legend = function(chart, svg) {
  var self = this;

  var total_width = $(svg[0][0]).prop('clientWidth');
  var total_height = $(svg[0][0]).prop('clientHeight');
  var legend;

  if (self.options['legend'] == 'top')
    legend = chart.addLegend(self.left_margin,
                            0,
                            10,
                            self.top_margin,
                            "left");
  else if (self.options['legend'] == 'bottom')
    legend = chart.addLegend(self.left_margin,
                            total_height-self.bottom_margin+60,
                            total_width-self.left_margin-self.right_margin,
                            self.bottom_margin-50,
                            "left");
  else if (self.options['legend'] == 'right')
    legend = chart.addLegend(total_width-self.right_margin+20,
                            self.top_margin,
                            self.right_margin-20,
                            total_height-self.top_margin-self.bottom_margin,
                            "left");
  legend.fontSize = 13;
  return legend
}

TirantBarChart.prototype.createGraph = function() {
  var self = this;

  self.buildDataNames()
  self.cleanData()
  self.id_svg = self.chartContainer + '_tirant_bar_chart';

  var svg_div = self.chartContainer;
  var no_x_scroll = 15;
  self.graphWidth = "100%";
  self.graphHeight = self.calculateGraphHeight();

  var svg = dimple.newSvg(svg_div, self.graphWidth, self.graphHeight);
  svg.attr('id', self.id_svg);

  var chart = new dimple.chart(svg, self.data);

  self.setBounds(chart)

  var x;
  var y;
  var p;

  if (self.options['chart_type'] != dimple.plot.pie) {
    x = self.setAxisX(chart)
    y = self.setAxisY(chart)
  } else {
    p = self.setAxisP(chart)
  }

  var series = chart.addSeries(self.options['series'] ? 'serie' : 'label', self.options['chart_type'] );
  if (self.options['show_points']) {
    series.interpolation = "cardinal";
    series.lineMarkers = true;
  }
  if (self.options['ring_radius'])
    series.innerRadius = self.options['ring_radius'];

  series.addOrderRule(self.serie_names, false)

  self.set_after_draw_elements(svg, series, y)

  var legend;
  if (self.options['legend'])
    legend = self.draw_legend(chart, svg)

  if (self.options['custom_colors']) {
    $.each(self.data, function(index, element) {
      chart.assignColor(element['serie'] ? element['serie'] : element['label'], element['color'] ? element['color'] : "#80b1d3", element['color'] ? element['color'] : "#80b1d3");
    })
  } else {
    self.setDefaultColors(chart)
  }

  chart.draw();

  series.getTooltipText = function(e) {
    var labelToShow = []
    if (e) {
      var element = self.getElementData(e)
      labelToShow = self['tooltipFunction'](element)
    }
    return labelToShow;
  };

  if (self.options['legend'])
    legend.shapes.selectAll("text").style("fill", "#343a40")

  if (x != null) {
    x.shapes.selectAll("line").style("stroke", "lightgray")
    x.shapes.selectAll("path").style("stroke", "lightgray")
    x.shapes.selectAll("text").style("fill", "#343a40")
  }

  if (y != null) {
    y.shapes.selectAll("line").style("stroke", "lightgray")
    y.shapes.selectAll("path").style("stroke", "lightgray")
    y.shapes.selectAll("text").style("fill", "#343a40")
  }

  self.mouseoverTooltipText(svg,chart,series);

  svg.attr('height', self.graphHeight);
  svg.selectAll('rect').attr('opacity', 0.6);

  if (self.options['inside_labels'])
    svg.selectAll('text.dimple-custom-axis-label').remove()

  if (self.options['show_points'])
    d3.selectAll('.dimple-custom-line-marker').style("cursor", "pointer")

  self.onClickEvents(svg);
}

TirantBarChart.prototype.setDefaultColors = function(chart) {
  chart.defaultColors = [
    new dimple.color("#6EA9CC", "#6EA9CC"),
    new dimple.color("#ee9298", "#ee9298"),
    new dimple.color("#F7DC6F", "#F7DC6F"),
    new dimple.color("#83CCC0", "#83CCC0"),
    new dimple.color("#c67fb6", "#c67fb6"),
    new dimple.color("#e5dbbe", "#e5dbbe"),
    new dimple.color("#4FC3F7", "#4FC3F7"),
    new dimple.color("#E59866", "#E59866"),
    new dimple.color("#1ABC9C", "#1ABC9C"),
    new dimple.color("#f0c9ff", "#f0c9ff"),
    new dimple.color("#88f5d3", "#88f5d3"),
    new dimple.color("#b1a5d4", "#b1a5d4"),
    new dimple.color("#FFE7E3", "#FFE7E3"),
    new dimple.color("#31c7f2", "#31c7f2"),
    new dimple.color("#ee9298", "#ee9298"),
    new dimple.color("#88f5d3", "#88f5d3"),
    new dimple.color("#b1a5d4", "#b1a5d4"),
    new dimple.color("#dcffbb", "#dcffbb"),
    new dimple.color("#ff7f50", "#ff7f50"),
    new dimple.color("#b8da92", "#b8da92"),
    new dimple.color("#c8d6e5", "#c8d6e5"),
    new dimple.color("#e056fd", "#e056fd"),
    new dimple.color("#fea47f", "#fea47f"),
    new dimple.color("#686de0", "#686de0"),
    new dimple.color("#fc427b", "#fc427b")
  ]
}


TirantBarChart.prototype.getElementData = function(e) {
  var self = this;
  var result = {}
  $.each(self.data, function(index, element) {
    if ((e['label'] == null || e['label'] === element['label']) && (e['serie'] == null || e['serie'] === element['serie'])) {
      result = element;
      return false
    }
  })
  return result
}

TirantBarChart.prototype.defaultTooltipFunction = function(element) {
  var self = this;

  var tooltip=[]
  var formatted_value = (element['tooltip_label'] ? element['tooltip_label'] : (element['value_label'] ? element['value_label'] : element['value'])).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
  var final_value = '';
  if(self.options['title_y_axis'])
    final_value = formatted_value + self.options['title_y_axis'];
  else
    final_value = formatted_value + (element['serie'] && element['label'] ? (' (' + element['serie'] + ')') : '');

  var text = element['label'] ? element['label'] : element['serie']
  if(text.length > 40) {
    tooltip = self.tokenizeLargeText(text)
    tooltip.push(final_value)
  } else {
    tooltip.push(text + ': ' + final_value)
  }

  return tooltip
}

TirantBarChart.prototype.tokenizeLargeText = function(text) {
  var self = this;

  var res = [];
  var iter = 0;
  while (iter < text.length) {
    var i = Math.min(iter+40, text.length-1);
    while (text.charAt(i) != ' ' && i < text.length) {
      i+=1;
    }
    res.push(text.substring(iter, i));
    iter = i+1;
  }
  return res
}

TirantBarChart.prototype.transformToEllipsis = function(text, maxChars) {
  var self = this;
  if (text) {
    var result = text
    if (maxChars < text.length) {
      var i = maxChars
      while (text.charAt(i) != ' ' && i >= 0) {
        i-=1;
      }
      result = text.substring(0, i) + '...';
    }
    return result;
  }
  else {
    return ""
  }
}
  

TirantBarChart.prototype.defaultClickFunction = function(element) {
  return null;
}

TirantBarChart.prototype.onClickEvents = function(svg) {
  var self=this;
  var svg_div = self.chartContainer;

  if ($(svg_div).length > 0) {
    self.onClickBar(svg);
  }
}

TirantBarChart.prototype.onClickBar = function(svg) {
  var self=this;

  svg.selectAll(self.getClickSelector()).on('click', function(e) {
    var selected = e ? e : $(this)
    var element = self.getElementData(self.getChartSelection(selected));
    self['clickFunction'](element);
  });
}

TirantBarChart.prototype.getTooltipSelector = function() {
  var self = this;

  if (self.options['series'])
    return ".dimple-bar, .dimple-custom-line-marker, .inside_label, .dimple-pie"
  else
    return ".dimple-axis-y .tick, .dimple-bar, .dimple-custom-line-marker, .inside_label"
}

TirantBarChart.prototype.getClickSelector = function() {
  var self = this;

  if (self.options['horizontal'])
    return "rect, text"
  else
    return "rect, circle"
}

TirantBarChart.prototype.mouseoverTooltipText = function(svg,chart,series) {
  var self = this;
  var timer;
  svg.selectAll(self.getTooltipSelector()).on('mouseover', function(e) {
    var that = this
    timer = setTimeout(function(){
      var selected_element = d3.select(that);
      var procesable_element = selected_element;
      if (!e) {
        procesable_element = d3.select("#" + selected_element.attr('parent_id'));
      }
      var tag_selection = e ? e : selected_element
      var graph_element = self.getChartSelection(tag_selection)
      self.mouseoverFunction(svg, chart, procesable_element, graph_element, series)
    }, 300);
  }).on('mouseleave', function(e) {
    clearTimeout(timer);
    self.mouseleaveFunction(e, chart, series);
  })
}

TirantBarChart.prototype.defaultMouseoverFunction = function(svg, chart, procesable_element, graph_element, series) {
  var self = this;

  var origFill = procesable_element.attr('fill');
  if (self.options['show_points']) {
    procesable_element.attr('fill', procesable_element.attr('stroke'))
  }

  dimple._showBarTooltip(graph_element, procesable_element[0][0], chart, series);
  var t = chart._tooltipGroup;
  var rect = t.select('rect');

  var tooltip_width = parseFloat(rect.attr('width')) + 15
  var tooltip_height = parseFloat(rect.attr('height')) + 15

  if (self.options['horizontal']) {
    var element_x = parseFloat(procesable_element.attr("x"))
    var element_y = parseFloat(procesable_element.attr("y"))
    var element_width = parseFloat(procesable_element.attr("width"))
    var element_height = parseFloat(procesable_element.attr("height"))

    var tooltip_in_bounds = tooltip_width + 10 < element_width
    var x = (tooltip_in_bounds ? element_x + element_width/2 - tooltip_width/2: element_x + 10);
    var y = (element_y + element_height + 10);

    t.select('g').attr("transform", "translate("+x+","+y+")")
  }
  rect.attr('width', tooltip_width ).attr('height', tooltip_height );
  t.selectAll('text').attr('dx',5).attr('dy',10);
  procesable_element.attr('fill', origFill)
}

TirantBarChart.prototype.defaultMouseleaveFunction = function (e, chart, series) {
  var self = this;

  dimple._removeTooltip(e, this, chart, series);
}

TirantBarChart.prototype.getChartSelection = function(element) {
  var self = this

  var result = {}
  if (typeof element === 'string')
    result['label'] = element
  if (typeof element === 'object'){
    //Comprobamos si es un objeto creado por nosotros
    if (element.attr) {
      if (element.attr('label'))
        result['label'] = element.attr('label')
      if (element.attr('serie'))
        result['serie'] = element.attr('serie')
    }
    else {
      if (self.options['chart_type'] != dimple.plot.pie) {
      result['label'] = element['cy']
      if (!self.options['horizontal'])
        result['label'] = element['cx']
      }
      if (self.options['series'])
        result['serie'] = element['aggField'][0]
    }
  }
  return result
}

TirantBarChart.prototype.isMap = function(svg_div) {
  var boolIsMap = false;
  if (svg_div.indexOf('mapa') > -1) {
      boolIsMap=true
  }

  return boolIsMap;
}

TirantBarChart.prototype.cleanData = function() {
  var self = this;

  // if (!self.data.filter) return

  self.data = self.data.filter(function(value, index, arr) {
    return value['value'] != null
  })
}

TirantBarChart.prototype.set_after_draw_elements = function(svg, series, y) {
  var self = this;

  if (self.options['inside_labels'] || self.options['inside_values'] || self.options['chart_type'] == dimple.plot.pie) {
    series.afterDraw = function (s, d) {
      var element = self.getElementData(self.getChartSelection(d))
      if (self.options['chart_type'] != dimple.plot.pie) {
        var shape = d3.select(s)
        var label, fontSize, position;
        if (self.options['inside_labels']) {
          label = element['label'];
          fontSize = 13
          position = parseFloat(shape.attr("x")) + 5
        }
        else if (self.options['inside_values']) {
          label = element['tooltip_label'] ? element['tooltip_label'] : element['value'];
          fontSize = 12
          position = Math.max(parseFloat(shape.attr("x")) + 5, parseFloat(shape.attr("x")) + parseFloat(shape.attr("width")) - label.length*8)
        }

        var total_width = $(svg[0][0]).prop('clientWidth');

        // Add some bar labels for the y value
        svg.append("text")
          .attr("x", position)
          .attr("y", Math.ceil(parseFloat(shape.attr("y")) + shape.attr("height")/2 + fontSize/2 - 2))
          .attr("class", 'inside_label')
          .attr("label", element['label'])
          .attr("serie", element['serie'])
          .attr("parent_id", shape.attr('id'))
          .style("font-size", fontSize)
          .style("pointer-events", "all")
          .style("cursor", "pointer")
          .style("fill", "#333")
          .text(self.transformToEllipsis(label, Math.floor(total_width/7)));
    
        // Draw without a border
        shape.attr("stroke", shape.attr("fill"));
      } else {
        var angle = (d.endAngle+d.startAngle)/2
        var width = self.calculate_pie_diameter(svg) + 10
        var x_projection = width/2*Math.sin(angle)
        var y_projection = - width/2*Math.cos(angle)
        if (d.endAngle-d.startAngle > 0.2) {
          var ctm = s.getCTM();
          svg.append("text")
            .attr("x", ctm.e + x_projection + (x_projection < 0 ? -element['value_label'].length*8 : 0 ))
            .attr("y", ctm.f + y_projection + (y_projection < 0 ? 0 : 13 ))
            .style("font-size", "13px")
            .style("fill", "#333")
            .text(element['value_label']);
        }
      }
    };
  }
}

TirantBarChart.prototype.calculate_pie_diameter = function(svg) {
  var box = $(svg[0][0]).children()[0].getBBox()
  return Math.min(box.width, box.height)
}

TirantBarChart.prototype.setLocale = function() {
  var self = this;

  let config = {
    decimal: ".",
    thousands: ",",
    grouping: [ 3 ],
    currency: [ "$", "" ],
    dateTime: "%a %b %e %X %Y",
    date: "%m/%d/%Y",
    time: "%H:%M:%S",
    periods: [ "AM", "PM" ],
    days: [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ],
    shortDays: [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ],
    months: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
    shortMonths: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ]
  };

  if(self.locale == 'es') {
    config = {
      decimal: ",",
      thousands: ".",
      grouping: [3],
      currency: ["€", ""],
      dateTime: "%a %b %e %X %Y",
      date: "%d/%m/%Y",
      time: "%H:%M:%S",
      periods: ["AM", "PM"],
      days: ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"],
      shortDays: ["Lun", "Mar", "Mi", "Jue", "Vie", "Sab", "Dom"],
      months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      shortMonths: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"]
    };
    //d3.d3_time.format = ES.timeFormat;
  }
  else if(self.locale == 'ca') {
    config = {
      decimal: ",",
      thousands: ".",
      grouping: [3],
      currency: ["€", ""],
      dateTime: "%a %b %e %X %Y",
      date: "%d/%m/%Y",
      time: "%H:%M:%S",
      periods: ["AM", "PM"],
      days: ["Dilluns", "Dimarts", "Dimecres", "Dijous", "Divendres", "Dissabte", "Diumenge"],
      shortDays: ["Dl", "Dm", "Dc", "Dj", "Dv", "Ds", "Dg"],
      months: ["Gener", "Febrer", "Març", "Abril", "Maig", "Juny", "Juliol", "Agost", "Setembre", "Octubre", "Novembre", "Desembre"],
      shortMonths: ["Gen", "Feb", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Oct", "Nov", "Des"]
    };
  }
  self.localeFormat = d3.locale(config);
  d3.format = self.localeFormat.numberFormat;
}
export default TirantBarChart
