const gtag = function() {
  if (!analytics_loaded) return

  window.dataLayer.push(arguments)
}
let analytics_loaded = false

const loadAnalytics = function() {
  if (analytics_loaded) return

  window.dataLayer = window.dataLayer || []

  analytics_loaded = true

  const analyticsId = gon.analytics.id
  const url = 'https://www.googletagmanager.com/gtag/js'

  gtag('js', new Date())
  gtag('config', analyticsId)
}

const registerAnalyticsEvent = (name, params) => {
  gtag('event', name, params)
}

export { loadAnalytics, registerAnalyticsEvent }
