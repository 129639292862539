import { loadAnalytics } from 'common/metrics'

const loadCookieConsent = () => {
  if (typeof CookieConsent !== 'undefined') {
    window.cookieconsent = new CookieConsent({
      sections: gon.cookieconsent.sections.map((section) => CookieConsent[section]),
      lang: gon.iso_language,
      version: gon.cookieconsent.version
    })
  } else {
    console.warn('[tolweb] CookieConsent no está cargado. El script puede haber fallado.')
    if (window.newrelic) {
      const CookieConsentError = new Error('CookieConsent no está cargado. El script puede haber fallado')
      window.newrelic.noticeError(CookieConsentError, {
        'tirant_error_id': 'cookieconsent',
        'tirant_project': 'tolweb',
        'tirant_file': 'app/javascript/common/cookies.js',
      })
    }
  }

  if (!window.cookieconsent) return

  window.cookieconsent.start()

  $(document).on('click', '.cookie_config_link', function() {
    window.cookieconsent.show_config_modal()
  })

  if(gon.smart_chat && window.cookieconsent.get_allowed().includes('chat')) {
    smartChat()
  }

  if(gon.analytics && window.cookieconsent.get_allowed().includes('analytics')) {
    loadAnalytics()
  }

  document.addEventListener('cookieconsent.saved', function (event) {
    if (gon.smart_chat && event.detail.currentSections.includes('chat')) {
      smartChat()
    }
    if (gon.analytics &&event.detail.currentSections.includes('analytics')) {
      loadAnalytics()
    }
  })
}

export default loadCookieConsent
