require('common/pollyfills');

import { docidPrefix } from 'common/documentUtils'

var mixins = {
  array_utils: {
    methods: {
      arraysEqual: function(array1, array2) {
        if (array1.length !== array2.length) {
          return false;
        }
        let sortedArray1 =  array1.sort();
        let sortedArray2 = array2.sort();
        for (let i = 0; i < sortedArray1.length; i++) {
          if (sortedArray1[i] !== sortedArray2[i])
            return false;
        }
        return true;
      }
    }
  },
  regex_utils: {
    methods: {
      text_to_regextext: function(text) {
        var tmp = text
        tmp = tmp.replace(/[oóò]/g, '[oóò]')
        tmp = tmp.replace(/[aáà]/g, '[aáà]')
        tmp = tmp.replace(/[eéè]/g, '[eéè]')
        tmp = tmp.replace(/[iíì]/g, '[iíì]')
        tmp = tmp.replace(/[uúùü]/g, '[uúùü]')
        return tmp
      },
      text_to_regex: function(text) {
        return new RegExp(this.text_to_regextext(text), 'i')
      },
      text_to_normalized: function(text) {
        return text.toLowerCase()
          .replace(/[aáàã]/g, 'a')
          .replace(/[eéè]/g, 'e')
          .replace(/[iíìï]/g, 'i')
          .replace(/[oóò]/g, 'o')
          .replace(/[uúùü]/g, 'u')
          .replace(/ñ/g, 'n')
          .replace(/\s+/g, '-')
      }
    }
  },
  filters: {
    methods: {
      new_filter: function(name = '', values = [], operator = 'OR') {
        return {
          name: name,
          values: values.slice(),
          operator: operator,
          sign: 'POSITIVE'
        }
      },
      formatted_filter: function(filter='') {
        return filter.replace('_mb/', '').replace(/-/g, ' ').split('/')
      },
      filterNodeName(filter='') {
        if(filter == null || filter == '') return ''
        let regex = /.+/
        let node_position = 1
        if(filter.includes('themine')) {
          regex = /(.+)_(.*?)-(.*?)(_+)/
          node_position = 2
        }
        if(filter.includes('_mb//')) {
          regex = /(.+)(.*?)\/\/(.*)/
        }
        let match = regex.exec(filter)
        return match == null ? '' : this.capitalizeName(match[match.length - node_position])
      },
      capitalizeName(filter='') {
        let current_filter = filter.replace(new RegExp('-', 'g'), ' ')
        if(current_filter != undefined) {
          return current_filter.charAt(0).toUpperCase() + current_filter.slice(1)
        }
        return ''
      },
      findNode(nodes, id) {
        let result = null
        if (nodes && nodes.length) {
          nodes.some((node) => {
            // if not node, return true to continue
            if (!node) return true
            if (node.id == id) {
              result = node
              return true
            }
            let child = this.findNode(node.children, id)
            if (child) {
              result = child
              return true
            }
          })
        }
        return result
      },
      deleteNode(nodes, id) {
        if (nodes && nodes.length) {
          nodes.some((node, index) => {
            if (!node) return true
            if (node.id == id) {
              nodes.splice(index, 1)
              return true
            }
            let child = this.deleteNode(node.children, id)
            if (child) {
              return true
            }
          })
        }
      }
    }
  },
  translate:{
    methods: {
      t: (...args) => I18n.t(...args)
    }
  },
  internationalization: {
    props: ['labels'],
    methods: {
      get_label(name) {
        return (this.labels || {})[name] || ''
      }
    }
  },
  facet: {
    /* CHILDREN MUST IMPLEMENT
      computed: filter_has_values
      methods: do_clear
    */
    props: ['name', 'values', 'operator', 'sign', 'facet', 'applied_values'],
    data() {
      return {
        clean_modal_visible: false,
        visible: this.applied_values.length > 0 || this.facet.default_visible
      }
    },
    methods: {
      toggle_visible() {
        this.visible = !this.visible
      },
      show_clean_modal() {
        this.clean_modal_visible = true
      },
      hide_clean_modal() {
        this.clean_modal_visible = false
      },
      clean_filter() {
        this.hide_clean_modal()
        this.do_clear()
      }
    }
  },
  requests: {
    data() {
      return {
        loading: false
      }
    },
    computed: {
      tolweb_base_url() {
        return `/base/${gon.current_app}`
      },
      grails_base_url() {
        return `/${gon.grails_appname}`
      },
      app_brand() {
        return gon.app_brand
      },
      is_tolgeo_mex() {
        return gon.app_tolgeo == "mex"
      },
      is_cgae(){
        return gon.app_tolgeo == "cgae"
      },
      docids_prefix() {
        return docidPrefix(gon.app_tolgeo)
      },
    },
    methods: {
      url_for_search_create(search_type) {
        return `${this.tolweb_base_url}/${search_type}/searches`;
      },
      url_for_search_show(id, params) {
        var path = `${this.tolweb_base_url}/searches/${id}`
        return this.format_uri(path, params)
      },
      url_for_search_update(search_type,id) {
        return `${search_type}/searches/${id}`
      },
      tolweb_url_for_search_show(search_type,id) {
        return `${this.tolweb_base_url}/${search_type}/searches?token_id=${id}`
      },
      url_for_analytics(token) {
        return `${this.tolweb_base_url}/analytics/select_jurisdictions/${token}`
      },
      url_for_analytics_grails(token) {
        return `${this.grails_base_url}/busquedaJurisprudencia/stats?homeAnalytics=true&token=${token}`
      },
      tolweb_url_for_search_navigate(search_type, params) {
        var path = `${this.tolweb_base_url}/${search_type}/searches/navigate`
        return this.format_uri(path, params)
      },
      url_for_user_subscriptions_document_new(params) {
        var path = `${this.tolweb_base_url}/user_subscriptions/documents/new`
        return this.format_uri(path, params)
      },
      url_for_user_subscriptions_search_new(params) {
        var path = `${this.tolweb_base_url}/user_subscriptions/searches/new`
        return this.format_uri(path, params)
      },
      url_for_document_file_create() {
        return `${this.tolweb_base_url}/document_file`
      },
      url_for_contacto_comercial_create(params){
        var path =`${this.tolweb_base_url}/commercial_contacts`
        return this.format_uri(path,params)
      },
      url_for_document_file_show(id, params) {
        var path = `${this.tolweb_base_url}/document_file/${id}`
        return this.format_uri(path, params)
      },
      url_for_filter_value_index(params) {
        var path = `${this.tolweb_base_url}/filter_value`
        return this.format_uri(path, params)
      },
      url_for_librodoctrinas_show(id, params) {
        var path = `${this.tolweb_base_url}/librodoctrinas/${id}`
        return this.format_uri(path, params)
      },
      url_for_grails_documento_show(id, params = {}) {
        var path = `${this.grails_base_url}/${gon.grails_appname == 'latam' ? 'documentoLatam' : 'documento'}/show/${id}`

        if (gon.materia && gon.materia.id) {
          params['materia'] = gon.materia.id
        }

        if (gon.app_tolgeo == 'latam')
          params['tolgeo'] = 'latam'
        return this.format_uri(path, params)
      },
      url_for_grails_selobuscamos(){
        return `${this.grails_base_url}/solicitudFormularios`
      },
      url_for_rails_documento_show(id, params = {}) {
        var path = `${this.tolweb_base_url}/documents/${id}`
        return this.format_uri(path, params)
      },
      url_for_bibliotecavirtual_show(id) {
       return `${this.tolweb_base_url}/biblioteca_virtual/${id}`
      },
      url_for_grails_personalizacion(){
        var path = `${this.grails_base_url}/personalizacion`
        return this.format_uri(path)
      },
      url_for_get_token(params, controller) {
        var path = `${this.tolweb_base_url}/${controller}/get_token`
        return this.format_uri(path, params)
      },
      url_for_user_subscriptions_profile(){
        return `${this.tolweb_base_url}/user_subscriptions/profile/0`
      },
      url_for_pdfjs_library() {
        return `${window.location.origin}/pdfjs/web/viewer.html`
      },
      async get_service_token(controller, params) {
        let url = this.url_for_get_token(params, controller)
        var request = await fetch(url, {
          method: 'GET',
        });
        var response = await request.json();
        var token = response.token;
        return token;
      },
      url_for_user_subscriptions_password_recoveries(params) {
        var path = `${this.tolweb_base_url}/user_subscriptions/password_recoveries`
        return this.format_uri(path, params)
      },
      url_for_user_subscriptions_password_change(params) {
        var path = `${this.tolweb_base_url}/user_subscriptions/password_changes`
        return this.format_uri(path, params)
      },
      url_for_password_change_result(params) {
        var path = `${this.tolweb_base_url}/user_subscriptions/password_changes/confirm`
        return this.format_uri(path, params)
      },
      url_for_sofia_file_uploader_post_upload(params) {
        var path = `${this.tolweb_base_url}/sofia_file_uploader/upload`
        return this.format_uri(path, params)
      },
      url_for_sofia_file_analyzer(params) {
        var path = `${this.tolweb_base_url}/sofia_file_analyzer`
        return this.format_uri(path, params)
      },
      url_for_sofia_folders(params) {
        var path = `${this.tolweb_base_url}/sofia_file_analyzer/user_subscription_folders`
        return this.format_uri(path, params)
      },
      url_for_estaticas(app, locale, resource) {
        return `/estaticas/${app}/${locale}/${resource}.html`
      },
      url_for_sofia_info() {
        return `${this.tolweb_base_url}/statics/sofia_info`
      },
      url_for_user_pre_subscriptions_create(params){
        var path = `${this.tolweb_base_url}/user_presubscriptions`
        return this.format_uri(path, params)
      },
      url_for_public_calendar(params) {
        var path = `${this.tolweb_base_url}/user_subscriptions/calendars/public_calendar`
        return this.format_uri(path, params)
      },
      url_for_document_map(id) {
        return `${this.grails_base_url}/documento/map/${id}/?searchtype=substring`
      },
      url_for_user_subscription_drop_out(){
        return `${this.grails_base_url}/altaPersonalizacion/bajaPersonalizacionForm`
      },
      url_for_user_subscription_log_out(){
        var path = `${this.grails_base_url}/personalizacionLogin/logout`
        return this.format_uri(path)
      },
      url_for_user_subscription_home() {
        return `${this.tolweb_base_url}/user_subscriptions/home`
      },
      //TODO Refactor JS ajax requests
      //https://trello.com/c/va6zfBhp/359-refactor-de-llamadas-http-desde-el-js
      http_get(
        {
          url = '',
          success = null,
          fail = null,
          format = 'json',
          trigger_action_name = null
        } = {}
      ) {
        this.loading = true
        if (format=="JS"){
          getAjaxCall(url, null, trigger_action_name)
        } else{
          this.$http.get(url).then(
            response => {
              success(response)
              this.loading = false
            },
            error => {
              this.error_callback(fail, error)
            }
          )
        }
      },
      encode_params_to_url(params) {
        return Object.entries(params).map(
          kv => kv.map(encodeURIComponent).join("=")
        ).join("&");
      },
      format_uri(path, params = {}) {
        return `${path}?${this.encode_params_to_url(params)}`
      },
      http_post(
        {
          url = '',
          params = {},
          success = null,
          fail = null,
          format = 'json',
          trigger_action_name = null
        } = {}
      ) {
        this.loading = true
        if (format=="JS"){
          postAjaxCall(url, null, trigger_action_name, success, fail)
        } else{
          this.$http.post(url, params).then(
            response => {
              success(response)
              this.loading = false
            },
            error => {
              this.error_callback(fail, error)
            }
          )
        }
      },
      http_put( { url = '', params = {}, success = null, fail = null, format = 'json', } = {}) {
        this.loading = true
        this.$http.put(url, params).then(
          response => {
            success(response)
            this.loading = false
          },
          error => {
            this.error_callback(fail, error)
          }
        )
      },
      fetch_upload_request(fileToUpload, redirect, newDocument = false, textForNewDocument = ''){
        this.uploading_file = true
        let formData = new FormData()
        formData.append("file", fileToUpload)
        formData.append("text", textForNewDocument)
        fetch(
          this.url_for_sofia_file_uploader_post_upload({
            file_name: fileToUpload.name,
            new_document: newDocument
          }),
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((result) => {
            this.error_upload = false
            let params = {
              file_path: result.path + result.file_name,
              new_document: newDocument
            };
            if (redirect) {
              window.location.href = this.url_for_sofia_file_analyzer(params)
            } else {
              this.launch_redirect_event(params)
            }
          })
          .catch((error) => {
            console.error("Failed to upload file to Sofia", error)

            if (window.newrelic){
              console.log("Sending error to new relic when uploading file to sofia", error)

              window.newrelic.noticeError(
              error,
              {
                tirant_error_id: 'fetch-upload-request',
                tirant_project: 'tolweb',
                tirant_file: 'app/javascript/common/mixins.js',
                tirant_function_name: 'fetch_upload_request',
                file_to_upload: fileToUpload.name
              })
            }

            this.uploading_file = false
            this.selected_file = false
            this.error_upload = true
            this.time_out_request = false
          });
      },
      launch_redirect_event(params) {
        var event = new CustomEvent("sofia-file-upload", {
          detail: {
            redirect: true,
            url: this.url_for_sofia_file_analyzer(params),
          },
        });
        document.dispatchEvent(event);
      },
      error_callback(fail, error) {
        this.loading = false;
        (fail || this.default_error_callback)(error)
      },
      default_error_callback(error) {
        if(Array.isArray(error.body))
          console.log(error.body.join(', '))
        else if(error.body != "")
          console.log(error.body)
      },
    }
  },
  voice:{
    data(){
      return {
        listening: false
      }
    },
    methods:{
      get_voice(text_callback = function() {}, end_callback = function() {}){
        var self = this
        this.listening = true

        var tirantSpeech = new TirantSpeech({
          host: this.$voice_host,
          port: this.$voice_port,
          onResult: function(result) {
            text_callback(result);
            self.listening = false;
            // Timeout to set full search string before callback
            setTimeout(function() {
              end_callback();
            }, 300);
          },
          onPartialResult: function(result) {
            text_callback(result);
          },
          onError: function(errorMessage) {
            self.listening = false;
            alert(errorMessage);
          }
        });
        tirantSpeech.start();
      }
    }
  },
  tree_node:{
    methods:{
      docRelated(node_name, opened_children) {
        let dashed_name = node_name.toLowerCase().replace(/\s+/g, "-")
        return opened_children.some((opened_child) =>
          opened_child.includes(node_name.toLowerCase()) ||
          opened_child.includes(dashed_name)
        );
      }
    },
  }
}
export default mixins;
