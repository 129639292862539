const state = {
  id: 0,
  numeroTol: '',
  clazz: '',
  headerFields: [],
  indiceFields: [],
  bodyFields: [],
  fileFields: [],
  searchParagraphsProcessed: 0,
  searching: false
}

const getters = {
  totalParagraphs(state) {
    return state.bodyFields.reduce((acc, field) => {
      return acc + field.value.length
    }, 0)
  }
}

const mutations = {
  setId(state, data) { state.id = data },
  setNumeroTol(state, data) { state.numeroTol = data },
  setClazz(state, data) { state.clazz = data },
  setHeaderFields(state, data) { state.headerFields = data },
  setIndiceFields(state, data) { state.indiceFields = data },
  setBodyFields(state, data) { state.bodyFields = data },
  setFileFields(state, data) { state.fileFields = data },
  increaseParagraphsProcessed(state) {
    state.searchParagraphsProcessed++
    if (state.searchParagraphsProcessed == getters.totalParagraphs(state))
      state.searching = false
  },
  initSearch(state) {
    state.searching = true
    state.searchParagraphsProcessed = 0
  }
}

export default {
  namespaced: true,
  name: 'document',
  state,
  getters,
  mutations
}
