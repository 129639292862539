<template>
  <div class="h-100">
    <div id="concepts_chart" class="h-100"></div>
  </div>
</template>

<script>
import TirantBarChart from 'common/TirantBarChart'

export default {
  name: 'bar-chart-vue',
  props: ['concepts'],
  methods: {
    printChart() {
      let bar_chart = new TirantBarChart({
        data: this.concepts,
        chartContainer: "#concepts_chart",
        options: {
          horizontal: true,
          custom_colors: true,
          inside_labels: true,
          hide_x: true,
          order: 'value'
        },
        handlers: {
          click: function (element) {
            var event = new CustomEvent("concepts:click", {
              detail: element
            });
            document.dispatchEvent(event); //Uncomment when it needs highlight for concepts
          },
          tooltip: function(element) {
            let text = element['label'] ? element['label'] : element['serie']

            if(text.length > 40) {
              return this.tokenizeLargeText(text)
            } else {
              return [text]
            }
          }
        }
      })
      bar_chart.init()
    },
    removePointers() {
      let barElements = Array.from(document.getElementsByClassName('dimple-bar'))
      barElements.forEach( element => {
        element.classList.remove('dimple-bar')
      })

      let labelElements = Array.from(document.getElementsByClassName('inside_label'))
        labelElements.forEach(element => {
          element.style.cursor = 'default'
      })
    }
  },
  mounted() {
    this.printChart()

    this.removePointers()
  }
}
</script>
