<template>
  <div>
    <div class="calendar-content__overlay" id="alerts_overlay" v-if="!has_user_subscription">
      <p v-html="t('components.rfc_alerts.not_personalized_message')" />
      <span id='rfc_login_button' class="d-flex align-items-center justify-content-center" @click="showLoginForm">
        {{ t('components.calendars.main.personalized_button') }}
      </span>
    </div>
    <div v-else>
      <div v-if="display_error">
        <forms-messages-list-vue
          id="errors"
          :messages="errors"
          :single_message="true"
        />
      </div>
      <div v-else>
        <div>
          <massive-alerts-vue @massive:updated="reloadDataTable"/>
          <div
            v-if="someAlert && !loading"
            id="rfc_alerts_table"
            class="container table-container"
          >
            <div class="row mx-0">
              <div class="col-md-12">
                <div class="row mr-3">
                  <div class="col-md-8">
                    <p class="description_alerts rfc-message">
                      {{ t("components.rfc_alerts.description") }}
                    </p>
                  </div>
                  <div class="col-md-4 d-flex align-items-md-end justify-content-md-end mb-3">
                    <button id="delete_button" class="btn btn-secondary" v-if="alertsAreSelected" @click="showModal('delete')">
                      <i class="pr-2 fas fa-trash"></i>
                      {{ t("components.rfc_alerts.delete_button",{ number_selected_alerts : selected_alerts.length }) }}
                    </button>
                    <div class="row d-flex align-items-md-end">
                      <forms-messages-list-vue
                        class="col-12 m-2 p-0"
                        id="errors"
                        :messages="actionErrors"
                        :single_message="false"
                      />
                      <forms-messages-list-vue
                        class="col-12"
                        id="messages"
                        :messages="actionMessages"
                        :single_message="true"
                        :notification_message="true"
                      />
                    </div>
                  </div>
                </div>
                <table
                  id="alerts_data"
                  class="display table table-hover table-borderless"
                >
                  <thead>
                    <tr>
                      <th>
                        {{ t("components.rfc_alerts.rfc") }}
                      </th>
                      <th>
                        {{ t("components.rfc_alerts.name") }}
                      </th>
                      <th>
                        {{ t("components.rfc_alerts.created_at") }}
                      </th>
                      <th>
                        {{ t("components.rfc_alerts.delete_select") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody id = 'box_table' class="box_table" />
                </table>
              </div>
            </div>
          </div>
          <div id="no_alerts" v-if="!someAlert && !loading">
            <p class="rfc-dialog">
              <i class="fas fa-exclamation"></i>
              {{ t("components.rfc_alerts.no_alerts") }}
            </p>
          </div>
        </div>
      </div>
      <loader-vue id='load_spinner' v-if="loadingStatus" :visible="true" />
    </div>
    <modal-user-subscription-login-vue id="rfc_login_modal" ref="user_subscription_login_modal" @login:success="handleLoginSuccess"/>
    <confirmation-modal-vue
      ref="delete_alert_confirmation_modal"
      :title="t('components.rfc_alerts.delete_modal.title')"
      @confirmation:clicked="handleDeleteConfirmationClicked">
      <slot>
          <div class="d-flex align-items-center py-4">
            <h5 class="mb-0">
              <i class="fas fa-exclamation-circle pr-2"></i>
              {{ t("components.rfc_alerts.delete_modal.body",{ num_alerts : selected_alerts.length }) }}
            </h5>
          </div>
      </slot>
    </confirmation-modal-vue>
    <confirmation-modal-vue
      ref="edit_alert_confirmation_modal"
      :title="t('components.rfc_alerts.edit_name_modal.title',{ rfc_code : selected_alert.rfc})"
      @confirmation:clicked="handleEditConfirmationClicked"
      :valid="!disableAccept"
    >
      <h4 class="modal-title">{{t('components.rfc_alerts.edit_name_modal.rfc_title',{ rfc_code : selected_alert.rfc})}}</h4>
      <input class="form-control" ref="editNameModal" v-on:keydown.enter="enterAccept" type="text" v-model="new_alert_name" autofocus >
      <p class="modal-label" v-if="sameAlertName" >{{t('components.rfc_alerts.edit_name_modal.body')}}</p>
      <div v-if="newAlertNameEmpty" class="text-danger"> {{t('components.rfc_alerts.edit_name_modal.name_validation')}} </div>
    </confirmation-modal-vue>
  </div>
</template>

<script>

import Vue from 'vue'
import mixins from "common/mixins";
import moment from "moment";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "rfc-alerts-vue",
  components: {
    "modal-user-subscription-login-vue": () =>
      import(
        /* webpackChunkName: "[request]" */ "components/user_subscription/ModalLogin"
      ),
      "loader-vue": () =>
      import(
        /* webpackChunkName: "[request]" */ "components/Loader"
        ),
    "forms-messages-list-vue": () =>
      import(
        /* webpackChunkName: "[request]" */ "components/forms/MessagesList"
      ),
    "massive-alerts-vue": () =>
      import(
        /* webpackChunkName: "[request]" */ "components/rfc/MassiveAlerts"
      ),
    'confirmation-modal-vue': () =>
    import(
      /* webpackChunkName: "[request]" */ 'components/ConfirmationModal'
      )

  },
  mixins: [mixins.translate],
  props:{
    table_alert_labels:{
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      display_error: false,
      selected_alerts: [],
      selected_alert: {},
      new_alert_name: ""
    };
  },
  computed: {
    loadingStatus() {
      return this.loading;
    },
    someAlert() {
      return this.alerts.length > 0;
    },
    alertsAreSelected(){
      return this.selected_alerts.length > 0;
    },
    newAlertNameEmpty(){
      return  this.new_alert_name === "" ||  this.new_alert_name.match(/^ *$/) !== null
    },
    sameAlertName(){
      return  this.new_alert_name === this.selected_alert.name
    },
    disableAccept(){
      return this.newAlertNameEmpty || this.sameAlertName
    },
    ...mapState("rfc_alerts", ["alerts", "loading", "errors", "actionMessages", "actionErrors"]),
    ...mapGetters("rfc_alerts", ["alerts_length", "has_errors"]),
    ...mapGetters(["has_user_subscription"])
  },
  watch: {
    alerts_length(newValue, oldValue) {
      if (oldValue < newValue) {
        $("#alerts_data").DataTable().destroy()
        this.loadDataAlerts()
      }
    },
  },
  methods: {
    async loadDataAlerts() {
      await this.load_alerts()
      this.setErrors()
      this.createTable()
    },

    async deleteAlerts(){
      var selected_rfcs = this.selected_alerts.map(a => a["rfc"])
      await this.delete_alerts(selected_rfcs)
      this.reloadDataTable()
    },

    async editAlertName(params) {
      await this.update_alert(params)
      this.reloadDataTable()
    },

    reloadDataTable(){
      $("#alerts_data").DataTable().destroy()
      this.selected_alerts = []
      this.loadDataAlerts()
    },

    async showModal(action){
       if(action=="edit"){
         this.$refs.edit_alert_confirmation_modal.show()
         Vue.nextTick().then(() => {
          this.$refs.editNameModal.focus()
        });

       }
      else if(action=="delete")
        this.$refs.delete_alert_confirmation_modal.show()
    },
    handleDeleteConfirmationClicked({confirmed}){
      if (confirmed) {
        this.deleteAlerts()
      }
    },

    enterAccept(){
      this.$refs.edit_alert_confirmation_modal.confirmation_accept()
    },

    handleEditConfirmationClicked({confirmed}){
      if (confirmed) {
          this.editAlertName({ id: this.selected_alert.id, name: this.new_alert_name })
      }
      else{
        this.selected_alert= ""
        this.selected_alert_name= ""
      }
    },

    setErrors() {
      if (this.has_errors) {
        if (this.errors["list_rfc_failed"] != undefined) {
          this.display_error = true;
        }
      }
    },

    showLoginForm() {
      this.$refs.user_subscription_login_modal.show()
    },

    setSelectedAlert(rfc,check_status){
      this.$store.commit("rfc_alerts/clear_action_error")
      this.$store.commit("rfc_alerts/clear_action_messages")
      this.alerts.forEach((alert) => {
      if(alert['rfc'] == rfc){
        if(check_status){
          alert['selected'] = true
        }
        else{
          alert['selected'] = false
          this.selected_alerts.splice(this.selected_alerts.indexOf(rfc), 1)
        }
      }
    })

    this.selected_alerts = this.alerts.filter(function(obj) {
      return obj["selected"];
    });
    },

    setUpdatedAlert(input){
      var rfc_name = input.attributes.name.value
      this.selected_alert = this.alerts.find(alert => alert.rfc === rfc_name)
      this.new_alert_name = this.selected_alert.name
      this.showModal("edit")
    },

    createTable() {
      var self = this;
      moment.locale("es");
      this.alerts.forEach((elem) => {
        elem["created_at"] = moment(elem["created_at"]).calendar();
        elem["selected"] = false
      });
      $("#alerts_data").DataTable({
        data: this.alerts,
        order: [[2, 'desc']],
        columns: [
          {
            data: "rfc",
            render: (data) => {
              return '<a href="searches?rfc=' + encodeURIComponent(data) + '">' + data + "</a>";
            },
          },
          { data: "name",
            className: "table-name-column",
            render: (data,type, row) => {
              return '<div name= ' + row.rfc + ' class="row table_rfc_name justify-content-between align-items-center"><p id= '+ row.rfc +' class="col-10 mb-0 ">' + data +'</p> <i  class= "edit_button fas fa-pen col-2"></i></div>';
            },
          },
          { data: "created_at" },
          {
            data: "rfc",
            className: "text-center",
            render: (data) => {
              return '<input id='+ data +' class="alert_checkbox" type="checkbox"/>';
            },
          },
        ],
        language: this.table_alert_labels,
        drawCallback: function() {
          $(".alert_checkbox").on('change', function () {
            self.setSelectedAlert(this.id,this.checked)
          })
          $(".table_rfc_name").on('click', function () {
            self.setUpdatedAlert(this)
          })
        }
      })
    },

    async handleLoginSuccess() {
      await this.loadDataAlerts()
    },
    ...mapActions("rfc_alerts", ["load_alerts","delete_alerts","update_alert"]),
  },
  async mounted() {
    if(this.has_user_subscription){
      await this.loadDataAlerts()
    }
  },
};
</script>