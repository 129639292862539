<template>
  <div class="headerSofia">
    <sofia-brand-vue :size="size" />
    <div class="btnInfo__sofia">
      <a
        :href="url_for_sofia_info()"
        target="_blank"
        class="text-primary"
        :style="more_info_style"
        ><i class="fas fa-question-circle"></i
      ></a>
    </div>
  </div>
</template>

<script>
import mixins from "common/mixins";

export default {
  name: "sofia-header-vue",
  props: {
    size: { type: String, default: "" }
  },
  mixins: [mixins.translate, mixins.requests],
  computed:{
    more_info_style(){
      let fontSize = ""
      if(this.size === 'sm'){
        fontSize = "0.7rem"
      }
      return {fontSize: fontSize}
    }
  }
};
</script>
