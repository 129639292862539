import Vue from 'vue/dist/vue.esm'

Vue.component(
  "user-subscription-profile-email-vue",
  () => import(/* webpackChunkName: "[request]" */ "components/user_subscription/ProfileEmail")
)

Vue.component(
  "user-subscription-profile-password-vue",
  () => import(/* webpackChunkName: "[request]" */ "components/user_subscription/ProfilePassword")
)
