<template lang="pug">
  div.container-fluid.col-sm-12.mt-3(
      role="form"
      aria-label="Contact information"
    )
    div.form-group
      input.form-control(
        type="text"
        :placeholder="translate('fields.name')"
        v-model="name"
      )
    div.form-group
      input.form-control(
        type="text"
        :placeholder="translate('fields.last_name')"
        v-model="lastName"
      )
    div.form-group
      input.form-control(
        type="email"
        :placeholder="translate('fields.email')"
        v-model="email"
      )
    div.form-group
      input.form-control(
        type="phone"
        :placeholder="translate('fields.phone')"
        v-model="phone"
        maxlength="16"
      )
    div.form-group
      forms-lopd-text-vue#lopd_component(
        v-if="lopdAvailable" 
        ref="lopdFields"
      ) 
    forms-messages-list-vue(
      :messages="errors"
      :title="translate('error_messages.title')"
    )
    button.btn.btn-primary.m-1.submit(
      :disabled="sendingRequest"
      @click="trySave"
    ) {{ t("send") }}
    button.btn.btn-secondary.m-1.reset(
      @click="clearFields"
    ) {{ t("borrar_campos") }}
    loader-vue(:visible="sendingRequest")
</template>
<script>
import mixins from "common/mixins"
import Validator from "common/Validator"
import { mapState, mapMutations, mapActions } from "vuex"
import ApiClient from "common/ApiClient"

export default {
  name: 'commercial-contact-form-vue',
  mixins: [mixins.translate],
  props: {
    app_source: { type: String, default: 'undefinned app' }
  },
  components: {
    "forms-lopd-text-vue": () => import(/* webpackChunkName: "[request]" */ "components/forms/LopdText"),
    "forms-messages-list-vue": () => import(/* webpackChunkName: "[request]" */ "components/forms/MessagesList"),
    "loader-vue": () => import(/* webpackChunkName: "[request]" */ "components/Loader")
  },
  data() {
    return {
      name: "",
      lastName: "",
      phone: "",
      email: "",
      errors: {},
      sendingRequest: false,
      api: new ApiClient()
    }
  },
  computed: {
    ...mapState('lopd_texts', [
      'lopd_privacy',
      'lopd_group',
      'lopd_publicity'
    ]),
    ...mapState('commercial_contact', [
      'sendingSaveRequest',
      'saveRequestFailed'
    ]),
    lopdAvailable(){
      return gon.lopd_available
    }
  },
  methods: {
    async trySave (){
      let validator = new Validator(this.fields()).do()
      this.errors = validator.messages
      if (validator.fails) return

      let payload = {
        email: this.email,
        nombre: this.name,
        apellidos: this.lastName,
        telefono: this.phone,
        lopd_privacidad: this.lopd_privacy,
        lopd_grupo: this.lopd_group,
        lopd_comercial: this.lopd_publicity,
        origen: this.app_source
      }

      await this.saveRequest(payload)

      if (this.saveRequestFailed) {
        this.handleFailedResponse()
        return
      }

      this.handleSuccessfulResponse()
    },

    handleSuccessfulResponse() {
      this.clearFields()
      $('.commercial_contact_modal').modal("hide")
      this.$emit("save_request:successful")
    },

    handleFailedResponse() {
      this.errors = {
        bad_request: this.translate('error_messages.failed_request') 
      }
    },

    fields(){
      let fields = [
        {
          fields: this.translate('fields.name'),
          values: this.name,
          validates: "filled"
        }, {
          fields: this.translate('fields.last_name'),
          values: this.lastName,
          validates: "filled"
        }, {
          fields: this.translate('fields.email'),
          values: this.email,
          validates: "email_format"
        }, {
          fields: this.translate('fields.phone'),
          values: this.phone,
          validates: "filled"
        }
      ]

      if (this.lopdAvailable) {
        fields.push({
          fields: this.translate('fields.lopd_privacy'),
          values: this.lopd_privacy,
          validates: 'checked'
        })
      }

      return fields
    },

    clearFields() {
      this.name = ""
      this.lastName = ""
      this.phone = ""
      this.email = ""
      this.errors = {}
      this.clearLopd()
    },

    translate(key) {
      const rootKey = 'components.forms'
      return I18n.t(`${rootKey}.${key}`) 
    }, 

    ...mapMutations('lopd_texts', ['clearLopd']),
    ...mapActions('commercial_contact', ['saveRequest'])
  }}
</script>
