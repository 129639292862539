import ApiClient from 'common/ApiClient'

const state = {
  sendingSaveRequest: false,
  saveRequestFailed: false
}

const mutations = {
  startSaveRequest(state) { state.sendingSaveRequest = true },
  endSaveRequest(state) { state.sendingSaveRequest = false },
  clearFailedSaveRequest(state) { state.saveRequestFailed = false },
  setFailedSaveRequest(state) { state.saveRequestFailed = true }
}

const actions = {
  async saveRequest(context, payload) {
    const api = new ApiClient()
    
    context.commit('clearFailedSaveRequest')

    context.commit('startSaveRequest')
    const response = await api.post(gon.url_for.commercial_contacts, payload)
    context.commit('endSaveRequest')

    if (response.failed) {
      context.commit('setFailedSaveRequest')
    }
  } 
}

export default {
  namespaced: true,
  name: 'commercial_contact',
  state,
  mutations,
  actions
}
