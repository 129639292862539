import calendars from "store/modules/calendars"
import document from "store/modules/document"
import rfc_search from "store/modules/rfc_search"
import rfc_alerts from "store/modules/rfc_alerts"
import hub_search from "store/modules/hub_search"
import sofia from "store/modules/sofia"
import conversa from "store/modules/conversa"
import search from "store/modules/search"
import lopd_texts from "store/modules/lopd_texts"
import commercial_contact from "store/modules/commercial_contact"
import SubmitFormService from "common/services/submit_form"
import user_subscription from "./modules/user_subscription"

export default {
  modules: {
    document,
    rfc_search,
    rfc_alerts,
    hub_search,
    calendars,
    sofia,
    conversa,
    search,
    user_subscription,
    lopd_texts,
    commercial_contact
  },
  plugins: [],
  state: {
    subscription_id: undefined,
    loadingLogin: false,
    showBackdrop: false,
    fontSize: 100
  },
  getters: {
    has_user_subscription(state) {
      return state.subscription_id != undefined
    }
  },
  mutations: {
    set_subscription_id(state, data) { state.subscription_id = data },
    startLoadingLogin(state) { state.loadingLogin = true },
    endLoadingLogin(state) { state.loadingLogin = false },
    showBackdrop(state) { state.showBackdrop = true },
    disableBackdrop(state) { state.showBackdrop = false },
    incrementFontSize(state) { state.fontSize += 5 },
    decrementFontSize(state) { state.fontSize -= 5 }
  },
  actions: {
    async submitLoginForm(context, userParams) {
      context.commit('startLoadingLogin')

      let submitFormService = new SubmitFormService()
      let response = await submitFormService.call(gon.url_for.user_subscription_logins, userParams)
      let data = await response.json()

      context.commit('endLoadingLogin')
      if (!response.ok) {
        return { "submitted": false, "data": { data } }
      }
      context.commit('set_subscription_id', data.id)

      return { "submitted": true, "data": { data } }
    },
    async submitRegisterForm(context, userParams) {
      let submitFormService = new SubmitFormService()
      let response = await submitFormService.call(gon.url_for.user_presubscriptions_path, userParams)

      if (!response.ok) {
        return { "submitted": false, "data": { data }, "status": response.status }
      }

      let data = await response.json()
      return { "submitted": true, "data": { data } }
    }
  }
}
