<template>
  <div v-if="visible" class="loader_spinner" role="loader">
    <div class="opacity_background"></div>
    <span :class="loader_style_class"></span>
  </div>
</template>

<script>
export default {
  name: "loader-vue",
  props: {
    visible: {type: Boolean, require: true},
    size: {type: String, require: false}
  },
  computed: {
    loader_style_class() {
      let class_name = "loader-general"
      return this.size ? class_name + " " +  this.size : class_name;
    },
  }
}
</script>

<style>

</style>
