<template>
  <div class="container-fluid mt-2 rfc-search pl-lg-5">
    <div class="row mb-1">
      <div class="col">
        <h2 id="title">{{ t("components.rfc_search.title")}}</h2>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-9 col-xl">
        <forms-generic-input-vue
          ref='generic_input_vue'
          input_type="filled"
          :input_name="t('components.rfc_search.code_input')"
          input_field="components.rfc_search.code_input"
          form_name='search_form'
          :auto_validates="false"
          @input_changed="setRfcCode"
          @enter:pressed="handleSearchClick"
          :default_value="this.rfc_code"
          :button_classes="['form-control', 'form-control-lg', 'old-style']"
        />
      </div>
      <div class="col-xs-auto old-style">
        <button id="search" title="search" @click="handleSearchClick" class="btn btn-primary btn-lg">
          <i class="fas fa-search"></i>
        </button>
      </div>
      <div class="col-md-auto btn-save-rfc d-flex justify-content-end">
        <button id="save_rfc" @click="handleAlertCreateClick" v-if="search_performed && !existing_rfc_alert" class="btn btn-secondary px-5 h-100">
          {{ t("components.rfc_search.save_alert") }}
        </button>
        <div id="existing_alert_label" v-if="existing_rfc_alert">
          {{ t("components.rfc_search.saved_alert") }}
          <a :href= my_alerts_url>{{ t("components.rfc_search.saved_my_alerts") }}</a>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <div v-if="invalid_rfc_code_visible" class="rfc-invalid text-danger">
          <i class="fas fa-exclamation-circle"></i>
          {{ t("components.rfc_search.code_format_invalid") }}
        </div>
        <div v-if="search_request_failed" class="text-danger" id="search_request_failed">
          {{ t("components.rfc_search.request_failed") }}
        </div>
      </div>
    </div>

    <loader-vue v-if="loading_rfc_search" :visible="true"/>
    <user-subscription-modal-login-vue ref="user_subscription_login_modal" @login:success="handleLoginSuccess"/>
    <user-subscription-modal-rfc-alert-vue ref="user_subscription_rfc_alert_modal" :rfcCode="rfc_code"/>

    <div v-if="results_empty" class="no-results">
      {{ t("components.rfc_search.no_results", { rfc_code: rfc_code }) }}
    </div>

    <div v-else class="results mt-1">
      <div v-for="(article, index) in rfc_search" class="articles pr-0 pr-md-5 pr-lg-0" :key="index">
        <div v-for="listing in article.listings" class="mb-3 overflow-auto">
          <h4 class="listing-title">{{ listing.title }}</h4>
          <p class="listing-description" v-html="listing.description"></p>
          <table class="listing-table">
            <thead>
              <tr>
                <th v-for="header in listing.headers">{{ header }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in listing.rows">
                <td v-for="value in row">{{ value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
         <h3 class="article-title mt-3 mb-5">Fuente: {{ article.source }} - {{ article.date }}</h3>
      </div>
    </div>

    <div v-if="search_performed" id="disclaimer">
      <p>
        {{ t("components.rfc_search.disclaimer") }}
      </p>
      <p>
        {{ t("components.rfc_search.sources_paragraph")}}
        <ul class="pl-3 mt-2">
          <li>
            <a :href="url_for_sat_69B" target="blank"
              v-html="t('components.rfc_search.source_link_text_1')"
            >
            </a>
          </li>
          <li>
            <a :href="url_for_sat_69" target="blank"
              v-html="t('components.rfc_search.source_link_text_2')"
            >
            </a>
          </li>
        </ul>
      </p>
    </div>
  </div>
</template>

<script>
import mixins from "common/mixins";
import validates from "common/validations";
import { urls_for_sat } from "common/mixins/urls";
import { mapState, mapActions , mapGetters } from "vuex";

export default {
  name: "rfc-search-vue",
  mixins: [mixins.translate, urls_for_sat, mixins.requests],
  props: {
    rfc_value: { type: String, default: '' }
  },
  components: {
    "user-subscription-modal-login-vue": () => import(/* webpackChunkName: "[request]" */ 'components/user_subscription/ModalLogin'),
    "user-subscription-modal-rfc-alert-vue": () => import(/* webpackChunkName: "[request]" */  'components/user_subscription/ModalRfcAlert'),
    "forms-generic-input-vue": () => import(/* webpackChunkName: "[request]" */ 'components/forms/GenericInput'),
    "loader-vue": () => import(/* webpackChunkName: "[request]" */  'components/Loader')
  },
  data() {
    return {
      rfc_code: decodeURIComponent(this.rfc_value),
      invalid_rfc_code_visible: false,
      search_request_failed: false,
      results_empty: false,
      search_performed: false
    };
  },
  computed: {
    rfcCodeInvalid() {
      return !validates.rfc_code_format(this.rfc_code)
    },
    my_alerts_url() {
      return `${this.tolweb_base_url}/rfc/alerts`
    },
    ...mapState("rfc_search", [
      "rfc_search",
      "loading_rfc_search",
      "existing_rfc_alert",
      "errors"
    ]),
    ...mapGetters("rfc_search", ["has_errors"]),
    ...mapGetters(["has_user_subscription"])
  },
  methods: {
    async handleSearchClick() {
      this.clearErrors()

      this.search_performed = false

      this.$refs.generic_input_vue.validateFormat()

      if (this.rfc_code == "") return

      if (this.rfcCodeInvalid) {
        this.invalid_rfc_code_visible = true
      }
      this.load_search()
    },

    async load_search(){
      await this.load_rfc_search_results(this.rfc_code)
      this.handleLoadResponse()
    },

    handleLoadResponse() {
      if (this.has_errors) {
        this.search_request_failed = true
      } else {
        this.search_performed = true
        if (this.rfc_search.length == 0) {
          this.results_empty = true
        }
      }
    },

    handleAlertCreateClick() {
      if (this.has_user_subscription) {
        this.showSaveRfcAlertModal()
      } else {
        this.showLoginForm()
      }
    },

    async handleLoginSuccess() {
      await this.load_rfc_search_results(this.rfc_code)
      this.search_performed = true
      if (!this.existing_rfc_alert) {
        this.showSaveRfcAlertModal()
      }
    },

    showLoginForm() {
      this.$refs.user_subscription_login_modal.show()
    },

    showSaveRfcAlertModal() {
      this.$refs.user_subscription_rfc_alert_modal.show()
    },

    setRfcCode(input_value) {
      this.rfc_code = input_value
      this.search_performed = false
      this.$store.commit("rfc_search/add_existing_rfc_alert", false)
      this.clearErrors()
    },

    clearErrors() {
      this.invalid_rfc_code_visible = false
      this.search_request_failed = false
      this.results_empty = false
    },
    ...mapActions("rfc_search", ["load_rfc_search_results"])
  },

  mounted() {
    if(this.rfc_value != '')
      this.load_search();
  }
}
</script>
