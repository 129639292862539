import token_formatter from 'common/token_formatter.js'
import { textProcessor } from 'common/links'

var filters = {

  char_limit: function(title,limit){
    var title_str = title.toString()
    if (title_str.length > limit)
      title_str = title_str.substring(0, limit).concat("...")
    return title_str
  },

  highlight: function(text, cleaned_token_list, stemm_text=false, add_buttons=false) {
    var matching_tokens = cleaned_token_list
    var text_to_replace = text
    if (stemm_text) {
      var matching_tokens = token_formatter.get_matching_tokens_in_text(matching_tokens, text)
    }
    var new_text = '<strong class="highlight"><em>$&</em></strong>'
    if (add_buttons)
      new_text = `
        <span class="highlight" data-highlight="">
          <button class="highlight_first"><i class="fas fa-angle-double-left"></i></button>
          <button class="highlight_prev"><i class="fas fa-angle-left"></i></button>
          <em>
            $&
          </em>
          <button class="highlight_next"><i class="fas fa-angle-right"></i></button>
          <button class="highlight_last"><i class="fas fa-angle-double-right"></i></button>
        </span>
      `
    matching_tokens.forEach(function(clean_token) {
      var re = new RegExp("\\b"+clean_token+"\\b","gi")
      text_to_replace = text_to_replace.replace(re, new_text)
    })

    return text_to_replace
  },

  clean_bolds: function(text) {
    var re = new RegExp("\<\/?b\>","gi")

    return text.replace(re, '')
  },

  highlight_substring: function(text, regex, reverse=false) {
    if (reverse) {
      return `<strong>${text.replace(regex, '</strong>$&<strong>')}</strong>`
    } else
      return text.replace(regex, '<strong>$&</strong>')
  },

  format_percentage: function(number, decimals) {
    return (number*100).toFixed(decimals) + "%"
  },

  parse_format_date: function(date_string) {
    var date = new Date(date_string)
    return date.toLocaleDateString()
  },

  remove_intro_chars: function(text) {
    return text.replace(/\\\\n|\\n|\n/g, '')
  },

  applyLinks(text, linksData) {
    return textProcessor(gon.app_tolgeo, text, linksData)
  },

  format_field_value_to_string: function(value) {
    var string = value
    if (Array.isArray(value))
      string = value.join(', ')
    return string
  },

  limit_string_length: function(str, limit, ellipsis=true) {
    return str.length > limit ? str.substring(0, limit) + (ellipsis ? '...' : '') : str;
  },

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  },
  titleize_label(str) {
    return str.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
  }
}
export default filters
