<template lang="pug">
.overflow-auto.h-100
  .row.mx-0.position-relative
    .col.pl-0
      .bg-secondary-light.my-3.px-4.py-3
        div
          p(v-html="t('user_subscriptions.home.subscribe_novelties')")
        button(
          id="change_subscription_btn"
          :class="change_subscription_button_class_selector"
          @click="change_subscription")
          | {{t(news_button_text)}}
      loader-vue(:visible="isLoading")
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import mixins from "common/mixins"

  export default {
    mixins: [mixins.translate],
    name: "user-subscription-novelty-subscription-vue",
    components: {
      "loader-vue": () => import(/* webpackChunkName: "[request]" */ "components/Loader"),
    },
    props: {
      hasNewsSubscription: { type: Boolean, default: false }
    },
    data() {
      return {
        isChecked: this.hasNewsSubscription
      }
    },
    methods: {
      async change_subscription(){
        this.isChecked = await this.setNoveltySubscription({status: !this.isChecked})
      },
      ...mapActions("user_subscription", ["setNoveltySubscription"])
    },
    computed: {
      news_button_text(){
        return this.isChecked ? 'user_subscriptions.home.unsubscribe' : 'user_subscriptions.home.subscribe'
      },
      change_subscription_button_class_selector(){
        return this.isChecked ? 'btn-base btn-gray' : 'btn-base btn-orange'
      },
      ...mapState("user_subscription", ["isLoading"])
    }
  }
</script>