<template>
  <div class="per_data_form">
    <h3 class="title">{{ t("user_subscriptions.password_recovery.request_title", { brand: app_brand }) }}</h3>
    <h5 class="text-info">{{ t("user_subscriptions.password_recovery.request_subtitle") }}</h5>

    <messages-list
      :messages= notifications
      :notification_message="true"
      ref="notifications_list"
    />

    <label for="email">
      {{ t("user_subscriptions.password_recovery.request_email") }}:
    </label>

    <input
      class="form-control mb-3"
      id="email"
      name="email_input"
      type="email"
      v-model="email_input"
      @input="clearErrors"
    />

    <messages-list
      :messages= errors
      ref="errors_list"
    />

    <button
      class="btn btn-primary accept_button"
      id="send_request"
      @click="validateForm"
      :disabled="sending_request"
    >
      {{ t(text_value) }}
    </button>

    <div class="row" v-if="contactUrl">
      <div class="col-md-12 mt-3" data-cy="contact_us" v-html="this.t('errors.contact_us', { url: contactUrl })"/>
    </div>
  </div>
</template>

<script>
import mixins from "common/mixins";
import Validator from "common/Validator";

export default {
  components: { "messages-list": () => import(/* webpackChunkName: "[request]" */ "components/forms/MessagesList") },
  mixins: [mixins.requests, mixins.translate],
  name: "user-subscription-request-password-recovery-vue",
  props: {
    contactUrl: { type: String, default: '' }
  },
  data() {
    return {
      email_input: "",
      errors: {},
      notifications: {},
      sending_request: false,
      text_value: "user_subscriptions.password_recovery.request_send",
    };
  },
  methods: {
    validateForm() {
      this.clearErrors();
      this.clearNotifications();

      let validator = new Validator([
        {
          fields: ["email"],
          values: [this.email_input],
          validates: "email_format",
        },
      ]).do();

      if (!validator.success) {
        this.setErrors(validator.messages);
        return;
      }

      this.requestSave();
    },

    requestSave() {
      this.sending_request=true;
      this.text_value = "user_subscriptions.password_recovery.request_sending",
      this.http_post({
        url: this.url_for_user_subscriptions_password_recoveries({email: this.email_input}),
        success: (data) => {
          if(data.status == 200) {
            this.resetInputs();
            this.sending_request= false;
            this.text_value = "user_subscriptions.password_recovery.request_send";
            this.addNotification({message: this.t("user_subscriptions.password_recovery.messages.feedback")});
          }
        },
        error: () => {
          this.sending_request= false;
          this.text_value = "user_subscriptions.password_recovery.request_send";
        },
      });
    },

    clearErrors() {
      this.setErrors({});
    },

    setErrors(errors) {
      this.errors = errors;
    },

    addError(error) {
      let errors = Object.assign(error, this.errors);
      this.setErrors(errors);
    },

    setNotifications(notifications) {
      this.notifications = notifications;
    },

    clearNotifications() {
      this.setNotifications({});
    },

    addNotification(notification) {
      let notifications = Object.assign(notification, this.notifications);
      this.setNotifications(notifications);
    },

    resetInputs() {
      this.email_input= "";
    },
  },
};
</script>
