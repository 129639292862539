<template>
  <div>
    <div class="search_token_item" v-for="token in search_tokens" v-bind:key="token.id">
      <span :class="{highlight: is_editing_token(token)}">{{token}}</span>
      <span class="edit_token" :class="{disabled: is_editing}" @click="edit_token(token)">E</span>
      <span class="remove_token" :class="{disabled: is_editing}" @click="remove_token(token)">X</span>
    </div>
    <div class="sub_searches_item" v-for="sub_search in sub_searches" v-bind:key="sub_search.id">
      {{sub_search.label}}: {{sub_search.value}}
      <span class="remove_sub_search" @click="remove_sub_search(sub_search)">X</span>
    </div>
    <input id="general" type="text" v-model="search_text" ref="search_box"/>
    <a id="operator_y" @click="insert_operator(' Y ')">Y</a>
    <a id="operator_o" @click="insert_operator(' O ')">O</a>
    <a id="operator_no" @click="insert_operator('!')">NO</a>
    <a id="show_search_opcions" @click.stop="show_options">Opciones</a>
    <a id="voice-micro" :class="{listening:listening}" :disabled="listening" @click="get_voice(set_text, reload_search)"></a>
    <div id="search_options" v-show="options_visible" v-on-clickaway="hide_options">
      <input type="radio" id="all_texts" value="all" v-model="selected_query_field"/>En todo
      <input type="radio" id="only_title" value="titles" v-model="selected_query_field"/>Titulos
      <input type="radio" id="substring" value="substring" v-model="selected_query_format"/>En todo
      <input type="radio" id="exacta" value="exacta" v-model="selected_query_format"/>Titulos
    </div>
    <button id="search_btn" @click="reload_search">Buscar</button>
    <!-- <button id="return_main_btn" @click="return_main_search">Ir al buscador avanzado</button> -->
  </div>
</template>
<script>
import MainSearchBox from 'components/forms/MainSearchBox';

export default {
  extends: MainSearchBox,
  props:['search_tokens', 'query_field', 'query_format', 'sub_searches'],
  data() {
    return {
      selected_query_field: this.query_field,
      selected_query_format: this.query_format,
      is_editing: false,
      edited_token: ''
    }
  },
  methods: {
    reload_search() {
      if(this.is_editing) {
        this.apply_token_edition()
      }
      if (!this.search_text=="") {
        var tokens = (this.search_tokens || []).concat(this.search_text)
        this.search_text = ""
        this.$emit('update:search_tokens', tokens)
      }
      this.$emit('perform_search')
    },
    edit_token(token) {
      if (!this.is_editing) {
        this.is_editing = true
        this.edited_token = token
        this.search_text = token
      }
    },
    apply_token_edition() {
      var self = this
      this.is_editing = false
      var tokens = this.search_tokens.map(function(item) { return item == self.edited_token ? self.search_text : item; })
      this.$emit('update:search_tokens', tokens)
      this.edited_token = ''
      this.search_text = ''
    },
    is_editing_token(token) {
      return token == this.edited_token
    },
    remove_token(token) {
      if(!this.is_editing) {
        var tokens = this.search_tokens.filter(item => item !== token)
        this.$emit('update:search_tokens', tokens)
        this.$emit('perform_search')
      }
    },
    remove_sub_search(sub_search) {
      var sub_searches = this.sub_searches.filter(item => (item.name !== sub_search.name) && (item.value !== sub_search.value))
      this.$emit('update:sub_searches', sub_searches)
      this.$emit('perform_search')
    },
    set_text(text) {
      this.search_text = text
    }
  },
  watch: {
    search_text(newValue, oldValue) {
      if (this.is_editing && newValue == '') {
        this.is_editing = false
        this.edited_token = ''
      }
    },
    selected_query_field(newValue, oldValue) {
      this.$emit('update:query_field', newValue)
    },
    selected_query_format(newValue, oldValue) {
      this.$emit('update:query_format', newValue)
    }
  }
}
</script>
