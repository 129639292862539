<template>
  <div>
    <div v-if="instructionsVisible" id='instruction_search_id'>
      <slot/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "instruction-search-vue",
  computed: {
    instructionsVisible(){
      if(this.searching){ return false }
      return Object.keys(this.groupResults).length === 0
    },
    ...mapState("search", ['groupResults', 'searching'])
  }
}
</script>

