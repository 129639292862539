<template>
  <div>
    <div>
      <forms-multiple-autocomplete-tree-selector-vue
        v-model="selected"
        :nodes="nodes"
        :counts_visible="true"
        :buttons_visible="true"
        :show_arrow="true"
        ref="multiple_autocomplete"
      >
        <template v-slot:box-search>
          <div class="col-12 py-3">
            <button
              @click="search"
              id="search_filter_button"
              class="btn btn-primary w-100"
              :disabled="disable_search_button"
            >
              {{ t("components.temas_search.button") }}
            </button>
          </div>
        </template>
      </forms-multiple-autocomplete-tree-selector-vue>
    </div>
  </div>
</template>

<script>
import mixins from 'common/mixins'
import { mapMutations, mapActions } from 'vuex'

export default {
  name: 'filter-search-vue',
  props: {
    preselectedFilters: { type: Array, default: () => [] },
    nodes: { type: Array, default: () => [] },
    filterName: { type: String, default: '' }
  },
  mixins: [ mixins.filters, mixins.translate ],
  components: {
    'forms-autocomplete-tree-selector-vue': () => import(
      /* webpackChunkName: "[request]" */ 'components/forms/AutocompleteTreeSelector'
    ),
    'forms-multiple-autocomplete-tree-selector-vue': () => import(
      /* webpackChunkName: "[request]" */ 'components/forms/MultipleAutocompleteTreeSelector'
    )
  },
  data() {
    return {
      selected: []
    }
  },
  computed: {
    filters() {
      return this.selected.length > 0 ? [this.new_filter(this.filterName, this.selected)] : []
    },
    disable_search_button(){
      return this.selected.length == 0
    },
    relatedPreselectedFilter(){
      return this.preselectedFilters.find((e) => e.name == this.filterName)
    }
  },
  mounted(){
    if(this.relatedPreselectedFilter){
      this.selected = this.relatedPreselectedFilter.values
      this.search()
    }
  },
  methods: {
    search() {
      this.setFilters(this.filters)
      this.performSearch()
      if(this.$refs.multiple_autocomplete)
        this.$refs.multiple_autocomplete.hideFilters()
    },
    ...mapMutations('search', ['setFilters']),
    ...mapActions('search', ['performSearch'])
  }
};
</script>
