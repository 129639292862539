<template lang="pug">
.custom-dropdown
  select(@change="redirectToOption").form-control
    option(value="#") {{ label }}
    option(v-for="option in options" :value="option.url") {{ option.label }}
</template>
  
<script>
export default {
  name: 'direct-access-selector-vue',
  props: {
    label: String,
    options: Array,
  },
  methods: {
    redirectToOption(event) {
      const selectedOption = event.target.value
      if (selectedOption !== "#") {
        window.location.assign(selectedOption)
      }
    },
  },
};
</script>
