<template>
  <modal-vue modal_id="save_file_modal" :content_loaded="folders_loaded" :visible.sync="isVisible">
    <template v-slot:header>
      {{ t("components.modal_folder_save.title") }}
    </template>

    <template v-slot:body>
      <div class="container-fluid">
        <div class="row" v-if="!has_name_file_input">
          <p>{{ t("components.modal_folder_save.message_file_name") }}</p>
        </div>
        <div class="row">
          <input
            class="form-control col-8"
            id="file_name_input"
            type="text"
            ref="name_input"
            v-model="file_name_input"
            v-on:keydown.enter="save_document"
          />
          <h5 class="align-self-end col-2 mb-0">{{ file_extension }}</h5>
          <div v-if="error_conflict_saving" class="col-12 alert-danger mt-1">
            {{ t("components.modal_folder_save.error_conflict_saving") }}
          </div>
          <div v-if="error_invalid_file_name" class="col-12 alert-danger mt-1">
            {{ t("components.modal_folder_save.error_invalid_file_name") }}
          </div>
        </div>
      </div>
      <create-folder-vue :bus="bus" :selected_folder="selected_folder_path" />

      <div class="vh-50 overflow-auto folder-list">
        <div>
          <hr />
          <folder-vue :bus="bus" :folder="root_folder" />
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div v-if="create_folder_error">
        {{ t("components.modal_folder_save.error_create_folder") }}
      </div>
      <div v-if="invalid_folder_error">
        {{ t("components.modal_folder_save.error_invalid_folder_name") }}
      </div>
      <div v-if="folders_load_error">
        {{ t("components.modal_folder_save.error_message") }}
      </div>
      <button
        v-if="allow_save_document"
        id="save-document"
        class="btn btn-primary"
        @click="save_document"
        :disabled="disabled_click"
      >
        {{ t("components.modal_folder_save.save") }}
      </button>
      <button
        id="cancelar"
        class="btn btn-secondary"
        @click="handle_cancel_click"
        :disabled="disabled_btn_cancel_click"
      >
        {{ t("components.modal_folder_save.cancel") }}
      </button>
    </template>
  </modal-vue>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from "vuex"
import mixins from "common/mixins"
import FolderRetrieveService from "common/services/folders_retrieved"
import { FolderIcon } from "vue-feather-icons"

export default {
  name: "modal-folder-save-vue",
  mixins: [mixins.translate, mixins.requests],
  props: {
    bus: { type: Object, default: () => new Vue() },
    file_name: { type: String, default: "" },
    file_extension: { type: String, default: "" },
    document_name: { type: String, default: "" }
  },
  components: {
    "modal-vue": () => import(/* webpackChunkName: "[request]" */ "components/Modal"),
    "folder-vue": () => import(/* webpackChunkName: "[request]" */ "components/trees/Folder"),
    "create-folder-vue": () => import(/* webpackChunkName: "[request]" */ "components/CreateFolder"),
    FolderIcon
  },
  data() {
    return {
      file_name_input: '',
      folders_loaded: false,
      new_folder: false,
      folders_load_error: false,
      root_folder: {},
      selected_folder_path: "",
      create_folder_error: false,
      invalid_folder_error: false,
      new_folder_path: "",
      error_conflict_saving: "",
      error_invalid_file_name: "",
      has_name_file_input: true,
      new_folder_created: true,
      is_creating_folder: false,
      isVisible: false
    }
  },
  computed: {
    folder_tree() {
      return new FolderRetrieveService(this.bus)
    },
    file_full_name_input() {
      return this.file_name_input + this.file_extension
    },
    allow_save_document() {
      return (
        !this.folders_load_error && this.folders_loaded && !this.new_folder && this.new_folder_created
      )
    },
    disabled_click() {
      return !this.has_name_file_input
    },
    disabled_btn_cancel_click() {
      return this.is_creating_folder
    },
    ...mapState('sofia', [
      'incorrect_filename_error'
    ])
  },
  watch: {
    file_name_input(val) {
      this.has_name_file_input = Boolean(val)
    },
    incorrect_filename_error(failed) {
      if(failed) this.show_invalid_filename_error()
      this.clear_incorrect_filename_error()
    },
  },

  created() {
    this.reset_modal()
    this.reload()

    this.bus.$on("create_folder_toggle", () => {
      this.new_folder = !this.new_folder
      this.focus_file_name_Input()
      this.create_folder_error = false
      this.invalid_folder_error = false
    })
    this.bus.$on("invalid_folder_name", () => {
      this.folders_loaded = true
      this.invalid_folder_error = true
    })
    this.bus.$on("create_folder_failed", () => {
      this.folders_loaded = true
      this.create_folder_error = true
    })
    this.bus.$on("create_folder_success", () => {
      this.reset_modal_without_refresh()
      this.reload()
    })

    this.bus.$on("folders_retrieved_failed", () => {
      this.folders_load_error = true
    })

    this.bus.$on("folders_retrieved", (root) => {
      this.root_folder = root
      this.selected_folder_path = this.select_last_created_folder(this.root_folder)
      this.folders_loaded = true
      this.new_folder_created = true
      this.bus.$emit("create_folder_without_refresh")
      this.focus_file_name_Input()
    })

    this.bus.$on("new_folder_created", (data) => {
      this.new_folder_path = data.path_new_folder
      this.is_creating_folder = true
    })

    this.bus.$on("create_folder_without_refresh", () => {
      this.is_creating_folder = false
    })

    this.bus.$on("folder_selected", (data) => {
      this.restart_selected_attribute(this.root_folder)
      let selected_folder = this.search_folder_by_attribute(
        this.root_folder,
        "id",
        data.folder.id
      )
      selected_folder.selected = true

      this.selected_folder_path = selected_folder.path
    })

    this.bus.$on("arrow_toggle", (data) => {
      let folder = this.search_folder_by_attribute(
        this.root_folder,
        "id",
        data.folder.id
      )
      folder.children.forEach((element) => {
        element.show = !element.show
      })
    })
  },
  methods: {
    search_folder_by_attribute(
      folder,
      attribute,
      value_attribute,
      found_folder
    ) {
      if (folder[attribute] == value_attribute) {
        found_folder = folder
        return folder
      } else {
        if (folder.children != undefined) {
          folder.children.forEach((child) => {
            let temp_folder_result = this.search_folder_by_attribute(
              child,
              attribute,
              value_attribute,
              found_folder
            )
            if (temp_folder_result != null) {
              found_folder = temp_folder_result
              return temp_folder_result
            }
          })
        }
      }
      return found_folder
    },
    restart_selected_attribute(folders_tree) {
      folders_tree.selected = false

      if (folders_tree.children != undefined) {
        folders_tree.children.forEach((child) => {
          this.restart_selected_attribute(child)
        })
      }
    },

    save_document() {
      this.folders_loaded = false
      this.error_conflict_saving = false
      this.error_invalid_file_name = false
      this.$emit(
        "save_document_on_folder:triggered",
        this.selected_folder_path,
        this.file_full_name_input
      )
    },

    select_last_created_folder() {
      if (this.new_folder_path !== "") {
        let new_folder = this.search_folder_by_attribute(
          this.root_folder,
          "path",
          this.new_folder_path
        )
        this.root_folder.selected = false
        new_folder.selected = true
        this.set_show_tree_folder_selected(
          this.root_folder.path,
          new_folder.path
        )
        return this.new_folder_path
      } else {
        return this.root_folder.path
      }
    },

    set_show_tree_folder_selected(root_folder_path, select_folder_path) {
      let array_path = select_folder_path
        .replace(root_folder_path, "")
        .split("/")

      array_path.pop()

      let tree_path = root_folder_path
      array_path.forEach((path) => {
        tree_path += path + "/"
        let folder = this.search_folder_by_attribute(
          this.root_folder,
          "path",
          tree_path
        )
        folder.show = true
        if (folder.children != undefined) {
          folder.arrow_open = true
          folder.children.forEach((item) => {
            item.show = true
          })
        }
      })
    },

    reset_modal() {
      this.root_folder = {}
      this.folders_loaded = false
      this.new_folder = false
    },

    reset_modal_without_refresh() {
      this.new_folder = false
      this.new_folder_created = false
    },

    handle_cancel_click() {
      this.isVisible = false
    },
    focus_file_name_Input() {
      Vue.nextTick().then(() => {
        this.$refs.name_input.focus();
        if (this.document_name) {
          this.file_name_input = this.document_name;
        } else {
          this.file_name_input = this.file_name;
        }
      });
    },
    save_error() {
      this.folders_loaded = true
      this.error_conflict_saving = true
    },
    show_invalid_filename_error(){
      this.folders_loaded = true
      this.error_invalid_file_name = true
    },
    hide() {
      this.isVisible = false
    },
    show() {
      this.isVisible = true
    },
    reload() {
      this.folder_tree.call()
    },
    ...mapMutations('sofia', ['clear_incorrect_filename_error'])
  }
}
</script>
