<template lang="pug">
  div#conversa-main(:class="wrapperClass" ref="conversaMain" @click.stop)
    div#conversa-close-button.conversa_close_button(:class="closeBtnClass")
      a(href="#" @click="hideConversa()" class="hvr-forward")
        img(:src="get_img_url('ico_conversa_close.png')" class="conversa_close_button__icoCloseConversa")
    div#conversa-wrapper(
      :class="wrapperClass"
    )
</template>

<script>
import mixins from 'common/mixins.js'
import { mapState, mapMutations, mapGetters } from 'vuex'

export default {
  mixins: [mixins.requests],
  name: 'conversa-wrapper-vue',
  data() {
    return {
      conversa: null,
      error: null
    }
  },
  props: {
    from_rails: Boolean, default: false
  },
  computed: {
    ...mapState(["subscription_id"]),
    ...mapState('conversa', ['show_conversa', 'conversa_search']),
    display_conversa() {
      return (this.show_conversa || !this.from_rails) && this.conversa
    },
    wrapperClass() {
      return this.display_conversa ? 'showConversa' : ''
    },
    closeBtnClass() {
      return this.display_conversa && this.from_rails ? 'showButton' : 'hiddenButton'
    },
    ...mapGetters(["has_user_subscription"])
  },
  methods: {
    ...mapMutations('conversa', ['setShowConversa']),
    buildConversa() {
      return new Conversa(this.conversaOptions())
    },
    render_conversa() {
      if (this.conversa) {
        this.conversa.render('conversa-wrapper')
      }
    },
    hideConversa() {
      this.setShowConversa(false)
    },
    async get_token() {
      return this.get_service_token('conversa', {});
    },
    get_img_url(img_name) {
      return require('../../../assets/images/conversa/'+ img_name )
    },
    conversaOptions(){
      return {
        config: {
          endPoint: gon.sofia_service_url + '/',
          getTokenFunction: this.get_token,
          locale: I18n.locale,
          materia: gon.materia_url_param || '',
          paisiso: gon.paisiso,
          isPersonalized: gon.subscription_id != undefined,
          personalizedUserID: this.personalizedUserID(),
          globalUserID: gon.user_id,
          currentApp: gon.current_app,
          userHasConversa: gon.user_has_conversa
        }
      }
    },
    personalizedUserID(){
      return gon.subscription_id || 'not_personalized'
    },
    handleOutsideClick(event) {
      if (this.show_conversa) {
        if (!this.isClickInsideConversa(event) && !this.isClickOnAnyConversaButton(event)) {
          event.preventDefault();
          this.setShowConversa(false);
        }
      }
    },

    isClickInsideConversa(event) {
      return this.$refs.conversaMain && this.$refs.conversaMain.contains(event.target);
    },

    isClickOnAnyConversaButton(event) {
      return (
        this.isClickedOnConversaButton('conversaBtn', 0, event) ||
        this.isClickedOnConversaButton('conversaBtn', 1, event) ||
        this.isClickedOnConversaButton('conversa_expanded_button', 0, event)
      );
    },

    isClickedOnConversaButton(className, index, event) {
      const buttonElement = document.getElementsByClassName(className)[index];
      return buttonElement && buttonElement.contains(event.target);
    }
  },
  mounted() {
    try {
      this.conversa = this.buildConversa()
      this.render_conversa()
    } catch (error) {
      this.error = error
    }
    document.addEventListener('click', this.handleOutsideClick)
  },
  watch: {
    show_conversa() {
      if (this.show_conversa) {
        this.conversa.eventBus.publish('sendQuestionFromSearchBox',this.conversa_search)
      }
    }
  }
}
</script>
