<template>
  <div>
    <textarea id="text" type="text" v-model="text" :maxlength="maxlength"/>
    <a id="voice-micro" :class="{listening:listening}" :disabled="listening" @click="get_voice(set_text)"></a>
  </div>
</template>

<script>
import mixins from 'common/mixins.js'
import filters from 'common/filters.js'

export default {
  props: ['maxlength'],
  data() {
    return {
      text: ''
    }
  },
  mixins: [mixins.voice],
  methods: {
    set_text(text) {
      if (this.maxlength)
        this.text = this.$options.filters.limit_string_length(text, this.maxlength, false)
      else
        this.text = text
    },
    clean() {
      this.text = ''
    },
  },
  filters: {
    limit_string_length: filters.limit_string_length
  },
}
</script>
