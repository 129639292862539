import ApiClient from 'common/ApiClient'

const state = {
  texts: {},
  texts_loaded: false,
  lopd_privacy: false,
  lopd_group: false,
  lopd_publicity: false
}

const getters = {
  privacy: (state) => { return state.lopd_privacy },
  group: (state) => { return state.lopd_group }, 
  publicity: (state) => { return state.lopd_publicity } 
}

const mutations = {
  addTexts(state, data) { state.texts = data },
  startRequest(state) { state.texts_loaded = false },
  successfulRequest(state) { state.texts_loaded = true },
  togglePrivacy(state) { state.lopd_privacy = !state.lopd_privacy },
  toggleGroup(state) { state.lopd_group = !state.lopd_group },
  togglePublicity(state) { state.lopd_publicity = !state.lopd_publicity },
  clearLopd(state) { 
    state.lopd_privacy = false 
    state.lopd_group = false
    state.lopd_publicity = false
  }
}

const actions = {
  async loadTexts(context) {
    const apiClient = new ApiClient()

    context.commit('startRequest')
    const response = await apiClient.get(gon.url_for.lopd_html_url)

    if (response.ok) {
      context.commit('addTexts', response.data)
      context.commit('successfulRequest')
    }
  }
}

export default {
  namespaced: true,
  name: 'lopd_texts',
  state,
  getters,
  mutations,
  actions
}
