class domainBreadcrumb {
  constructor(currentSearch, currentApp, currentElements, savedOption) {
    this.currentSearch = currentSearch;
    this.currentApp = currentApp;
    this.savedOption = savedOption;
    this.currentElements = currentElements;
    this.firstWord = this.setFirstWord();
  }

  getBreadcrumbs() {
    this.validateInputs();
    let breadcrumbs = []

    if (this.useSavedOption()) {
      breadcrumbs.push(this.capitalizedWord(this.translateOption(this.savedOption)));
    }
    breadcrumbs.push(this.getInitial());
    if (this.isGroupedFacet()) {
      breadcrumbs.push(this.getHumanizedFirstWord());
    }
    breadcrumbs.push(this.getHumanizedSelectedFacetWords());
    return breadcrumbs;
  }

  useSavedOption() {
    return this.currentApp === "tol" && this.savedOption != '';
  }

  validateInputs() {
    if (this.currentSearch == undefined || this.currentSearch.id == '') {
      throw new Error("currentSearch");
    }
    if (this.currentApp == undefined || this.currentApp == '') {
      throw new Error("currentApp");
    }
    if (this.currentElements == undefined || this.currentElements == []) {
      throw new Error("currentElements");
    }
    if (this.savedOption == undefined) {
      throw new Error("savedOption");
    }
  }

  isGroupedFacet() {
    const firstWord = this.getHumanizedFirstWord();
    return this.currentElements[firstWord] && this.currentElements[firstWord].length > 1;
  }

  setFirstWord() {
    if (this.currentSearch !== undefined) {
      return this.currentSearch.id.split("-")[0];
    } else {
        return "";
    }
  }

  getInitial() {
    let firstLetter = this.currentSearch.id.charAt(0).toUpperCase();
    return firstLetter;
  }

  getHumanizedFirstWord() {
    return this.capitalizedWord(this.firstWord);
  }

  getHumanizedSelectedFacetWords() {
    if (this.currentSearch.name != '') return this.currentSearch.name;
      
    let currentSearchWords = this.currentSearch.id.split("-");
    let humanizedSelectedFacetWords = currentSearchWords.map((word) => this.capitalizedWord(word));

    return humanizedSelectedFacetWords.join(" ");
  }

  capitalizedWord(word) {
    if (word == this.firstWord) {
      return this.capitalize(word);
    }
    
    return word;
  }

  capitalize(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  translateOption(option) {
    let translated_option = I18n.t(
      `components.forms.options_selector.${this.currentApp}.${option}`
    );

    if (translated_option.includes("missing")) {
      return option;
    }

    return translated_option;
  }
}

class hierarchyBreadcrumb {
  constructor(storedFilters, filterName) {
    this.storedFilters = storedFilters;
    this.filterName = filterName;
  }

  getBreadcrumbs() {
    this.validateInputs();
    let breadcrumbs = [];
    let filterValues = this.getFilterValues();
    if (filterValues.length == 0) {
      return breadcrumbs;
    }
    breadcrumbs = this.processFilterValues(filterValues[0]);
    if (breadcrumbs.length != 1) {
      breadcrumbs.shift();
    }
    return breadcrumbs.map((val) => val[0].toUpperCase() + val.slice(1));
  }

  validateInputs() {
    if (this.storedFilters == undefined || this.storedFilters == []) {
      throw new Error("storedFilters");
    }
    if (this.filterName == undefined || this.filterName == '') {
      throw new Error("filterName");
    }
  }

  getFilterValues() {
    const filter = this.storedFilters.find((filter) => filter.name == this.filterName);
    if (filter) {
      return filter.values;
    }
    return [];
  }

  processFilterValues(filter) {
    return filter.replace('_mb/', '').replace(/-/g, ' ').split('/');
  }
}

class materiasVocesBreadcrumb extends hierarchyBreadcrumb{
  constructor(storedFilters, filterName) {
    super(storedFilters, filterName)
  }

  getBreadcrumbs() {
    let breadcrumbs = super.getBreadcrumbs()

    if (breadcrumbs) {
      breadcrumbs.shift()
    }

    return breadcrumbs
  }
}

export { domainBreadcrumb, hierarchyBreadcrumb, materiasVocesBreadcrumb };
