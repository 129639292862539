<template lang="pug">
div
  span#title.novelties-links__subtitle {{ label }}
  button.ml-2(
    v-for="document in documents",
    @click="goToDocument(document.document_id)"
  )
    i.fas.fa-circle
    span.novelties_document {{documentTitle(document) }}
</template>

  <script>
import mixins from "common/mixins.js";

export default {
  name: "novelty-result-vue",
  mixins: [mixins.requests],
  props: {
    documents: { type: Array, default: () => [] },
    label: { type: String, default: '' }
  },
  methods: {
    goToDocument(id) {
      let url = this.url_for_grails_documento_show(id)
      window.location.assign(url)
    },
    documentTitle(document) {
      return document.document_title && document.num_tol ? `${document.document_title} (${document.num_tol})` : ' '
    }
  },
};
</script>