<template>
  <div>
    <div :class="classSearchButton">
      <slot v-if="!current_node.id" name="box-search"/>
    </div>
    <div class="box-list-filter">
      <div class="box-list-filter__firstColumn">
        <ul
          v-for="node in nodes"
          :class="classShowArrow(node.id)"
          :key="node.id"
          ref="nodes"
          :id="node.id + 'parent'"
        >
          <div
            class="w-100 d-flex"
            @click="showTree(node)"
          >
            <span>
              <input
                type="checkbox"
                class ="form-check-input"
                :id="node.id"
                @click.stop="toggleCheck(node.id)"
                ref="filterCheckBox"
              />
              {{ node.name }}
            </span>
            <button
              class="ml-auto"
              @click.stop="showTree(node)"
              :id="arrowId(node.id)"
            >
              <i :class="parentClass(node.id)"></i>
            </button>
          </div>
          <div v-if="showChildren(node.id)" class="w-100 pt-3 form-check-label-disabled">
            <div
              class='children_selected'>
            <ul
              v-for="selectedEl in selectedChildren[node.id].slice(0,4)"
              ref='visible_child'
              :key="selectedEl"
            >
              <input
                type="checkbox"
                checked = "checked"
                disabled = "true"
              />
                {{findNode(nodes, selectedEl).name}}
            </ul>
            <div
              class="collapse"
              :id="createToggleId(node.id)"
              ref = 'collapsedChildren'
            >
              <ul
                v-for="selectedEl in selectedChildren[node.id].slice(4,)"
                ref='toggable_child'
                :key="selectedEl"
              >
              <input
                type="checkbox"
                checked = "checked"
                disabled = "true"
              />
                {{findNode(nodes, selectedEl).name}}
              </ul>
            </div>
            <button v-if="toggleVisible(node.id)"
              :id="'toggle_selected_child' + node.id"
              class='w-100 collapse-children-button'
              data-toggle="collapse"
              :data-target="'#childCollapse' + node.id.replace('//', '')"
              @click = "collapseChildrenClass(node.id)"
            >
              <i
                ref = 'iconButton'
                :id = "'icon' + node.id.replace('//', '')"
                class = 'w-100 fas fa-plus'
              >
              </i>
            </button>
            </div>
          </div>
        </ul>
      </div>
      <div class="box-list-filter__secondColumn" v-if="current_node.id" id='filter_second_column'>
      <div class="box-list-filter__secondColumn--filter" v-on-clickaway="hideFilters">
        <slot name="box-search"/>
        <div class="pt-3">
          <input
            type="text"
            id="autocomplete"
            v-model="autocomplete"
            v-if="autocomplete_visible"
            class="form-control"
            :placeholder="t('components.forms.autocomplete_tree_selector.placeholder_filter')"
            :disabled="only_selection_visible"
          />
          <div id="button_panel" class="box-select-filter" v-if="buttons_visible">
            <div class="form-check">
              <input
                id="only_selection_switch" type="checkbox"
                class="form-check-input" v-model="only_selection_visible"
                :disabled="is_filtering || !has_selected_children"
              />
              <i></i>
              <label class="form-check-label" for="only_selection_switch">
                {{ t('components.forms.autocomplete_tree_selector.only_selection') }}
              </label>
            </div>
            <button
              @click="expand(true)" v-if="show_expand"
              id="expand_button" class="btn btn-outline-primary"
              :disabled="only_selection_visible || is_filtering"
            >
              {{ t('components.forms.autocomplete_tree_selector.open_all') }}
            </button>
            <button
              @click="expand(false)" v-else
              id="collapse_button" class="btn btn-outline-primary"
              :disabled="only_selection_visible || is_filtering"
            >
              {{ t('components.forms.autocomplete_tree_selector.close_all') }}
            </button>
            <button @click="ask_restore_confirmation" v-if="has_selected_children"
              id="restore_button" class="btn btn-outline-primary"
            >
              {{ t('components.forms.autocomplete_tree_selector.restore_selection') }}
            </button>
            <button @click="check_filtered" v-if="is_filtering" class="btn btn-primary" id="select_all">
              {{ t('components.forms.autocomplete_tree_selector.check_filtered') }}
            </button>
          </div>
        </div>
        <div
          class = "filterName"
        >
          {{current_node.name}}
       </div>
        <ul class="list-unstyled list-origin"
          v-empty = "{ text: t('components.forms.autocomplete_tree_selector.text_empty'),
                       show_message: is_parent_selected,
                       text_parent: t('components.forms.autocomplete_tree_selector.text_empty_parent') }">
          <forms-checkbox-tree-node-vue
            v-for="node in current_node.children"
            :key="node.id"
            v-bind="node"
            :filter="autocomplete"
            :is_filtering="is_filtering"
            :selected="selected"
            :auto_select_children="auto_select_children"
            :counts_visible="false"
            :only_selection_visible="only_selection_visible"
            @update_selected="update_selected"
            @status_changed="someChildChanged"
            ref="children"
            :class="class_show_arrow"
          />
        </ul>
        <div id="empty_message"></div>
      </div>
        <backdrop-vue/>
        <confirmation-modal-vue
          :title="t('components.forms.autocomplete_tree_selector.confirmation_title')"
          @confirmation:clicked="handle_confirmation_clicked"
          ref="confirmation_tree_selector"
        >
          {{ t("components.forms.autocomplete_tree_selector.confirmation_body") }}
        </confirmation-modal-vue>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "common/mixins"
import AutocompleteTreeSelector from 'components/forms/AutocompleteTreeSelector'
import { directive as onClickaway } from 'vue-clickaway';
import { mapMutations } from "vuex"

export default {
  props: {
    nodes: { type: Array, default: () => [] }
  },
  components:{
    "backdrop-vue": () => import(/* webpackChunkName: "[request]" */ "components/Backdrop.vue")
  },
  extends: AutocompleteTreeSelector,
  directives: {
    onClickaway: onClickaway,
  },
  name: 'forms-multiple-autocomplete-tree-selector-vue',
  mixins: [mixins.filters],
  data(){
    return{
      current_node: {},
      selectedChildren: {},
      has_selected_children: false
    }
  },
  computed:{
    curret_node_element() {
      return this.$refs.nodes ? this.$refs.nodes.filter(node => node.id == this.current_node.id + 'parent')[0] : undefined
    },
    is_parent_selected() {
      return this.selected.includes(this.current_node.id)
    },
    classSearchButton(){
      return this.current_node.id ? 'box-search-clean' : 'box-search-button'
    }
  },
  methods:{
    showTree(nodo){
      if(this.current_node.id && nodo.id == this.current_node.id){
        this.hideFilters()
      }
      else{
        this.showBackdrop()
        this.show_expand = true
        this.current_node =  nodo
      }
      if(this.selectedChildren[nodo.id] == undefined){
        this.selectedChildren[nodo.id] = []
      }
    },
    toggleCheck(node_id) {
      if(this.selected.includes(node_id)) {
        this.selected.splice(this.selected.indexOf(node_id), 1)
      }
      else {
        this.selected.push(node_id)
      }
    },
    toggleVisible(node){
      return this.selectedChildren[node].length > 4
    },
    someChildChanged() {
      let indeterminate_value = this.$refs.children.some((child) => {
        return child.$data.indeterminate || child.$data.checked
      })
      if(indeterminate_value) {
        this.curret_node_element.classList.add('selected')
      }
      this.$refs.filterCheckBox.filter(node => node.id == this.current_node.id)[0].indeterminate = indeterminate_value
    },
    restore_selection() {
      if(this.current_node.id){
        let compair_values = this.current_node.id.split(/[\s,]|\//)
        this.selected = this.selected.filter((e) => { return !e.includes(compair_values[2]) })
        this.$refs.filterCheckBox.filter(node => node.id == this.current_node.id)[0].checked = false
        this.$emit('input', this.selected)
      }
      this.only_selection_visible = false
      this.selectedChildren[this.current_node.id] = []
    },
    parentClass(nodeId) {
      return this.current_node && nodeId == this.current_node.id ? 'fas fa-chevron-left' : 'fas fa-chevron-right'
    },
    collapseChildrenClass(node){
      let iconButton = this.$refs.iconButton.filter(x => x.id.includes(node.replace('//', '')))
      if(iconButton[0].classList.contains('fa-plus')){
        iconButton[0].classList.replace('fa-plus', 'fa-minus')
      }else if(iconButton[0].classList.contains('fa-minus')){
        iconButton[0].classList.replace('fa-minus', 'fa-plus')
      }
    },
    classShowArrow(nodeId) {
      let className = 'list-select parent-text'
        if(this.current_node && this.current_node.id == nodeId){
          className += ' active'
        }
        if(this.selectedChildren[nodeId] && this.selectedChildren[nodeId].length > 0){
          className += ' selected'
        }
      return className
    },
    arrowId(value){
      return value.replace('//', '_') + '_arrow'
    },
    createToggleId(node){
      return 'childCollapse' + node.replace(/[/ ]/g, '')
    },
    showChildren(node){
      return this.selectedChildren[node] && this.selectedChildren[node].length > 0
    },
    hideFilters() {
      this.disableBackdrop()
      this.current_node = {}
    },
    updateChildren(nodeId, selected){
      if(this.nodeHasCreatedParent(nodeId)){
        this.selectedChildren[nodeId] = this.selectedChildren[nodeId].concat(selected)
      }
      else{
        this.selectedChildren[nodeId] = [selected]
      }
    },
    nodeHasCreatedParent(nodeId) {
      return Object.keys(this.selectedChildren).includes(nodeId);
    },
    findParentBySelectedChildren(node_id) {
      return this.nodes.filter(
        (node) => this.findNode(node.children, node_id) != null
      )[0];
    },
    checkPreSelectedNodes() {
      let preselected_ids = this.nodes.filter((e) => this.selected.includes(e.id))
                          .map((e) => e.id)
      if(this.$refs.filterCheckBox)
        this.$refs.filterCheckBox.map((e) =>
          preselected_ids.includes(e.id) ? (e.checked = true) : ""
        )
    },
    indeterminatePreSelectedNodes() {
      if(this.$refs.filterCheckBox)
        this.$refs.filterCheckBox.map((e) =>
          Object.keys(this.selectedChildren).includes(e.id)
            ? (e.indeterminate = true)
            : ""
        )
    },
    scrollToLabel() {
      const label = this.$el.querySelector(`label[for="${this.selected}"]`);
      if (label) {
        const container = this.$refs.scrollableColumn;
        if (container) {
          const distance = label.getBoundingClientRect().top -
            container.getBoundingClientRect().top + ( container.scrollTop || 0 ) - 10;
          container.scrollTop = distance;
        }
      }
    },
    updateAllSelectedChildren(){
      for(let nodeSelected in this.selected){
        let foundParent = this.findParentBySelectedChildren(this.selected[nodeSelected])
        if(foundParent){
          this.updateChildren(foundParent.id, this.selected[nodeSelected])
        }
      }
    },
    ...mapMutations(['showBackdrop', 'disableBackdrop'])
  },
  created(){
    this.updateAllSelectedChildren()
  },
  mounted(){
    this.checkPreSelectedNodes()
    this.indeterminatePreSelectedNodes()
 },
  watch: {
    selected(new_value, old_value){
      if(Array.isArray(new_value)){
        let added = new_value.filter(x => !old_value.includes(x))
        let removed = old_value.filter(x => !new_value.includes(x))

        if(added.length != 0){
          if(Object.keys(this.current_node).length != 0)
            this.selectedChildren[this.current_node.id] = this.selectedChildren[this.current_node.id].concat(added)
        }

        if(removed.length != 0){
          let index = this.selectedChildren[this.current_node.id].indexOf(removed[0])
          if(index !== -1){
            this.selectedChildren[this.current_node.id].splice(index, 1)
          }
        }
        if(this.selectedChildren[this.current_node.id]){
          this.has_selected_children = this.selectedChildren[this.current_node.id].length > 0
        }
      }
      this.scrollToLabel()
    }
  },
}
</script>


