<template lang="pug">
div
  div.sidebar_filters.resultTree__index
    .div(v-if="show_header" :class="header_class()")
      forms-filters-tree-header-vue(@update:panel_collapsed="update_toggle_collapsed")
    div.card-header(:class="header_class()+isClickable()" :id="cardHeaderId()")
      div.filterTree__header(:type="headerType()" data-toggle="collapse" :data-target="`#${collapseId()}`" :aria-expanded="expanded_default.toString()" :aria-controls="collapseId()" @click='backToResults')
        h5.mr-auto#node_name(v-if="isSingleAmbito") {{ capitalizedAmbitoId }}
          span {{ nodeName }}
        div(v-else)
          h5.mr-auto#node_name {{ nodeName }}
          p.mb-0.mt-2#node_ambitos {{  multiAmbitos }}
        i.fas.fa-angle-down
    div.collapse(:class="contentClass()" :id="collapseId()" :aria-labelledby="cardHeaderId()" data-parent="#accordion")
      div.resultTree__index--scrollable(:class="classDocument")
        ul.list-unstyled.list-origin.card-body
          forms-tree-node-vue(
                      v-for="node in nodeChildren"
                      :id="node.id"
                      :key="node.id"
                      v-bind="node"
                      :name="node.name"
                      :children="node.children"
                      :total="node.total"
                      ref="children"
                      class="form-check-label pl-0"
                      :related_to_doc="isRelatedToDoc(node.name)",
                      :opened_children="opened_children",
                      @value_selected="selected_value",
                      :filter_name="filter_name",
                      :hasSearchTokens="has_search_tokens"
                    )
      .hubsFilter__form(v-if="!is_document_view")
        a#selobusca.pr-4(:href = "url_for_grails_selobuscamos()")
          | {{ t("components.forms.notice.form_not_found") }}
          p
            strong {{ t("components.forms.notice.we_look_for_it") }}
              i.fas.fa-arrow-right.pl-4
  confirmation-modal-vue(
    ref='redirect_alert_confirmation_modal'
    :title="t('components.forms.filter_tree.confirm_title')"
    @confirmation:clicked='handleConfirmationClick')
      .d-flex.align-items-center.py-4.flex-column.backModal
        i.fas.fa-exclamation-circle.pr-2
        p.mb-0(v-html="confirmationLabel")

</template>
<script>
import mixins from 'common/mixins'
import filters from 'common/filters.js'
import { mapState } from 'vuex'

export default {
  name: 'forms-filters-tree-vue',
  props: {
    filter_name: { type: String, default: '' },
    node: { type: Object, default: () => {} },
    show_header: { type: Boolean, default: true },
    is_document_view: { type: Boolean, default: false },
    opened_children: { type: Array, default: () => [] },
    expanded_default: { type: Boolean, default: false },
    has_search_tokens: { type: Boolean, default: false },
    loading_search: { type: Boolean, default: () => false }
  },
  components: {
    'forms-tree-node-vue': () => import(
      /* webpackChunkName: "[request]" */ 'components/forms/TreeNode'
    ),
    'forms-filters-tree-header-vue': () => import(
      /* webpackChunkName: "[request]" */ 'components/forms/FiltersTreeHeader'
    ),
    'confirmation-modal-vue': () =>
    import(
      /* webpackChunkName: "[request]" */ 'components/ConfirmationModal'
    )
  },
  mixins: [mixins.translate, mixins.filters, mixins.tree_node, mixins.requests],
  data: function () {
    return {
      panel_collapsed: false,
      treeNode: this.node,
      selectedNodeName: '',
      selectedFilterValue: ''
    }
  },
  methods: {
    update_toggle_collapsed() {
      this.panel_collapsed = !this.panel_collapsed
      this.$emit('update:panel_collapsed')
    },
    header_class() {
      return this.node
        ? `filterTree__header-${this.nodeAmbito().toLowerCase()}`
        : ''
    },
    isClickable() {
      return this.is_document_view ? '' : ' headerSearch'
    },
    nodeAmbito() {
      if (this.node.ambito){
        return  Array.isArray(this.node.ambito) ? 'default' : this.node.ambito
      }
      else {
        return 'default'
      }
    },
    selected_value(filter_value, name) {
      if (this.is_document_view) {
        this.showRedirectConfirmationModal(filter_value, name)
      } else {
        this.$emit('update:filter', this.filter_name, [filter_value])
      }
    },
    showRedirectConfirmationModal(filter_value, name){
        this.selectedNodeName = name
        this.selectedFilterValue = filter_value
        this.$refs.redirect_alert_confirmation_modal.show()
    },
    isRelatedToDoc(node_name) {
      return this.docRelated(node_name, this.opened_children)
    },
    cardHeaderId() {
      return `header${this.nodeId}`
    },
    collapseId() {
      return `collapse${this.nodeId}`
    },
    headerType(){
      if(this.mustExpand){
        return 'text'
      }
      return 'button'
    },
    contentClass(){
      if(this.mustExpand){
        return 'show'
      }
      return ''
    },
    updateTotalNodes(node, filterTreeFacetValues) {
      const facetValue = this.findNode(filterTreeFacetValues, node.id)

      if (facetValue) {
        node.total = facetValue.total
      }

      for (const child of node.children || []) {
        this.updateTotalNodes(child, filterTreeFacetValues)
      }
    },
    backToResults(){
      if(!this.is_document_view) {
        this.$emit('update:filter', this.filter_name, [])
      }
    },
    handleConfirmationClick(confimationObject){
      if(confimationObject.confirmed)
        this.$emit('redirect:search', this.selectedFilterValue, this.node)
      else {
        this.selectedFilterValue = ''
        this.selectedNodeName = ''
        return
      }
    },
    updateCounts(facetsValues) {
      const filterTreeFacet = facetsValues.find(facet => facet.id === this.filter_name)
      if (filterTreeFacet) {
        const facetsToUse = this.has_search_tokens ? filterTreeFacet.values : this.node.children
        this.updateTotalNodes(this.treeNode, facetsToUse)
      }
    },
    handleEmitWithSearchToken(){
      let filtersFiltered = this.filters.filter(el => el.name !== this.filter_name)
       if(!filtersFiltered)
        return
      this.$emit('send:parameterized_request', {
        filters: filtersFiltered,
        rows: 25,
        page: 0,
        config: {
          "default_facet_keys": [this.filter_name]
        },
        empty_documents: "true"
      })
    }
  },
  filters: {
    capitalize: filters.capitalize,
  },
  watch: {
    facets(newFacets) {
      if(this.has_search_tokens){
        this.handleEmitWithSearchToken()
      } else {
        this.updateCounts(newFacets)
      }
    },
    searchTokensFacets(newSearchTokensFacets) {
      this.updateCounts(newSearchTokensFacets)
    }
  },
  computed: {
    capitalizedAmbitoId() {
      if (!this.node || this.nodeAmbito() === 'default') {
        return ''
      }

      const capitalizedAmbito = this.$options.filters.capitalize(
        this.node.ambito
      )
      return `${capitalizedAmbito} - `
    },
    isSingleAmbito() {
      return !Array.isArray(this.node.ambito)
    },
    multiAmbitos() {
      return Array.isArray(this.node.ambito) && this.node.ambito[0] !== 'default'
        ? this.node.ambito.join(' - ')
        : ''
    },
    nodeName() {
      return this.node ? this.node.name : ''
    },
    nodeId() {
      return this.node ? this.node.id : ''
    },
    nodeChildren() {
      return this.treeNode ? this.treeNode.children : ''
    },
    classDocument() {
      return this.is_document_view ? '' : 'mh-60'
    },
    confirmationLabel(){
      return I18n.t('components.forms.filter_tree.confirm_label', {
          selectedNodeName: this.selectedNodeName,
        })
    },
    mustExpand(){
      return this.show_header || this.expanded_default
    },
    ...mapState('search', ['facets', 'filters', 'searchTokensFacets'])
  }
}
</script>