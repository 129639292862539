export default {
  methods: {
    search_redirect_to_document_detail(search, query_format) {
      let clean_search = (search || '').replace(/[._\s]+/g, '')
      if (!this.search_by_valid_docid(clean_search) && query_format !== 'docid')
        return false

      let docid = clean_search.replace(/[^\d]/g, '')
      if (!docid.trim())
        return false

      window.location.href = this.url_for_rails_documento_show(docid)
      return true
    },
    search_by_valid_docid(search) {
      return search && search.toUpperCase().startsWith(this.docids_prefix)
    }
  }
}
