export default class {
  constructor(bus) {
    this.bus = bus
  }
  async call(path, folder_name) {

    let url = get_url({ path: path, folder_name: folder_name })
    let response = await fetch(url, { method: "POST" })
    let data = await response

    switch (response.status) {
      case 200:
        this.bus.$emit("create_folder_success", data)
        break;
      case 422:
        this.bus.$emit("invalid_folder_name")
        break;
      default:
        this.bus.$emit("create_folder_failed")
        break;
    }
  }
}

let get_url = (params) => {
  return `/base/${gon.current_app}/sofia_file_analyzer/create_folder?${url_params(params)}`
}

let url_params = (params) => {
  return Object.entries(params).map(
    kv => kv.map(encodeURIComponent).join("=")
  ).join("&");
}
