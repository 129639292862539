import Vue from 'vue/dist/vue.esm'

Vue.component(
  "filter-search-vue",
  () => import(/* webpackChunkName: "[request]" */ "components/FilterSearch")
)

Vue.component(
  "instruction-search-vue",
  () => import(/* webpackChunkName: "[request]" */ "components/InstructionsSearch")
)
