<template>
<div>
  {{questions}}
  <trees-question-list-vue></trees-question-list-vue>
  <a href='#' id='fallo_estimatorio_tab' @click='set_tab("fallo estimatorio")'>fallo estimatorio</a>
  <a href='#' id='fallo_estimatorio_parcial_tab' @click='set_tab("fallo estimatorio parcial")'>fallo estimatorio parcial</a>
  <a href='#' id='fallo_desestimatorio_tab' @click='set_tab("fallo desestimatorio")'>fallo desestimatorio</a>

  <documents-list-vue 
    v-if='current_tab == "fallo estimatorio"'
    v-bind:key='"fallo estimatorio"'
    v-bind:filters='filters_fallo_estimatorio' />
  <documents-list-vue 
    v-else-if='current_tab == "fallo estimatorio parcial"'
    v-bind:key='"fallo estimatorio parcial"'
    v-bind:filters='filters_fallo_estimatorio_parcial' />
  <documents-list-vue 
    v-else-if='current_tab == "fallo desestimatorio"'
    v-bind:key='"fallo desestimatorio"'
    v-bind:filters='filters_fallo_desestimatorio' />
</div>
</template>

<script>

export default {
  name: "tree-main-vue",
  props: ['input_questions', 'input_documents', 'input_base_filters', 'input_description', 'input_tree_id'],
  data: function() {
    return {
      questions: this.input_questions,
      current_tab: 'fallo estimatorio',
      base_filters: this.input_base_filters,
      description: this.input_description,
      tree_id: this.input_tree_id
    }
  },
  components: {
    'trees-question-list-vue': () => import(/* webpackChunkName: "[request]" */ "components/trees/QuestionList"),
    'documents-list-vue': () => import(/* webpackChunkName: "[request]" */ "components/documents/List")
  },
  computed: {
    question_filters: function() {
      var filters = []
      this.questions.forEach(function(question) {
        if (question.answer.value && question.answer.value != "-1") {
          filters.push({
            values: [question.value], 
            name: question.name, 
            operator: "AND", 
            sign: question.answer.value == "1"  ? 'POSITIVE' : 'NEGATIVE'
          })
        }
      })
      return filters
    },
    filters: function() {
      return this.base_filters.concat(this.question_filters)
    },
    filters_fallo_estimatorio: function() {
      return this.filters.concat([this.build_deciding_filter('fallo estimatorio')])
    },
    filters_fallo_estimatorio_parcial: function() {
      return this.filters.concat([this.build_deciding_filter('fallo estimatorio parcial')])
    },
    filters_fallo_desestimatorio: function() {
      return this.filters.concat([this.build_deciding_filter('fallo desestimatorio')])
    }
  },
  methods: {
    set_tab: function(tab) {
      this.current_tab = tab
    },
    build_deciding_filter: function(deciding_value) {
      return {name: 'themine_global_sentido-del-fallo_mb', values: [deciding_value], sign: 'POSITIVE', operator: 'AND'}
    }
  }
}

</script>

<style scoped>

</style>
