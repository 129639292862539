let state = {
  upload_error: false,
  save_in_folder_error: false,
  incorrect_filename_error: false,
  uploading: false,
  current_file: '',
  new_file_path: '',
  folder_file_path: ''
}

let mutations = {
  upload_error(state) { state.upload_error = true },
  upload_error_clear(state) { state.upload_error = false },
  upload_start(state) { state.uploading = true },
  upload_end(state) { state.uploading = false },
  new_file_path(state, { path, file_name }) { state.new_file_path = path + file_name },
  update_current_file(state, new_file) { state.current_file = new_file },
  folder_file_path(state, file_path) { state.folder_file_path = file_path },
  save_in_folder_fail(state) { state.save_in_folder_error = true },
  save_in_folder_error_clear(state) { state.save_in_folder_error = false },
  set_incorrect_filename_error(state) { state.incorrect_filename_error = true },
  clear_incorrect_filename_error(state) { state.incorrect_filename_error = false }

}

let actions = {
  async upload(context, file) {
    let url = gon.url_for.sofia_file_uploader_upload
    let formData = new FormData()
    formData.append("file", file)
    formData.append("file_name", file.name)

    
    context.commit('upload_start')
    let response = await fetch(url, { method: "POST", body: formData })
    context.commit('upload_end')
    
    if (!response.ok) {
      context.commit("upload_error")
      return
    }

    context.commit('upload_error_clear')
    context.commit("new_file_path", await response.json())
  },

  async save_in_folder(context, params) {
    let url = gon.url_for.sofia_file_analyzer_save_file
    let response = await fetch(url, { 
      method: "POST", 
      body: JSON.stringify(params), 
      headers: { 'Content-Type': 'application/json;charset=UTF-8' } 
    })

    if (response.ok) {
      context.commit('save_in_folder_error_clear')
      context.commit('folder_file_path', `${params.folder_path}${params.input_path}` )
      return
    }

    switch (response.status) {
      case 422:
        context.commit("set_incorrect_filename_error")
        break
      default:
        context.commit("save_in_folder_fail")
    }

    return
  }  
}

export default {
  namespaced: true,
  name: "sofia",
  state,
  mutations,
  actions
}
