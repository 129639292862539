<template lang="pug">
  .col-md-6.mb-3.mb-md-0.mt-4
    .bg-primary-light
      .row.custom-control.mr-sm-2
        label.title(for="myInput") {{labelOption}}
        p.pt-4#description
          | {{ schedule_option.description }}
        .row.mx-0.form-group
          .col-md-6.checkbox-element.cntr.form-check(v-for="hour in calculateHours")
            .d-flex.flex-column
              .d-flex.flex-row.align-items-center
                input.inputCheckbox.form-input(
                  type="checkbox"
                  :name="inputName"
                  :id="hour.id_hour"
                  :value="hour.hour"
                  :multiple="true"
                  class = "tutorial_checkbox",
                  v-model="selectedHours"
                )
                label.form-label.cbx(:for="hour.id_hour")
                span {{ hour.hour }}
              select(:id="selectId(hour)" :name="inputName" v-if="selectedHours.includes(hour.hour)")
                option.option_date(
                  v-for="(day, index) in availableDays(hour.hour)",
                  :value="day",
                  :id="optionId(hour, index)"
                ) {{ day }}

</template>

<script>
import mixins from 'common/mixins'

export default {
  name: 'card-tutorial-date-vue',
  mixins: [ mixins.translate ],
  props: {
    schedule_option: { type: Object, default: () => { return {} } }
  },
  data() {
    return {
      option: this.schedule_option,
      selectedHours: [],
      appname: gon.current_app,
      today: new Date()
    };
  },
  computed:{
    days() {
      return I18n.t(`tutorial_info.${this.appname}.workdays`)
    },
    months() {
      return I18n.t(`tutorial_info.${this.appname}.months`)
    },
    inputName() {
      return `${this.option.field_name}[]`
    },
    labelOption() {
      return `${this.option.name} (${this.option.duration})`
    },
    calculateHours(){
    return this.option.hours.map((val, ind) => ({
      hour: val,
      id_hour: `${this.option.field_name}${ind}`
    }))
    },
  },
  methods: {
    selectId(hour){
      return `${hour.id_hour}_select`
    },
    optionId(hour, index){
      return `${hour.id_hour}_option_${index}`
    },
    availableDays(dayWeekSelected) {
      const numberDateOptions = 4
      const daysWeek = this.days.map(el => Object.values(el)[0])
      const indexOfDaySelected = daysWeek.findIndex(day => dayWeekSelected.includes(day)) + 1
      const todayIndex = this.today.getDay()
      const selectedDayEqualCurrent = todayIndex === indexOfDaySelected
      let startNextWeek = indexOfDaySelected < todayIndex

      if (selectedDayEqualCurrent) {
        startNextWeek = this.showCurrentDate(dayWeekSelected)
      }

      const dates = Array.from({ length: numberDateOptions }, (_, i) =>
        this.createDate(indexOfDaySelected, i + (startNextWeek ? 1 : 0))
      )

      return dates
    },

    showCurrentDate(dayWeekSelected) {
      const selectedTutorialHour = dayWeekSelected.match(/(\d{1,2}):\d{2}/)[1]
      const selectedTutorialHourPassed = selectedTutorialHour < this.today.getHours()
      return selectedTutorialHourPassed
    },

    createDate(dayWeek, weekNumber) {
      const newDate = new Date(this.today.getTime())
      const daysBetweenTodaySelected = dayWeek - newDate.getDay()
      const daysToWeek = 7 * weekNumber + daysBetweenTodaySelected
      newDate.setDate(newDate.getDate() + daysToWeek)

      const dayOfMonth = newDate.getDate()
      const monthName = Object.values(this.months[newDate.getMonth()])[0]

      return `${dayOfMonth}/${monthName}`
    },
  }
}
</script>
