
<template>
  <div>
    <bin-card-wrapper-vue v-if='show_bin_card' />
    <forms-perform-search-box-vue/>
    <search-general-tabs-vue v-if='tabsVisible' />
    <search-general-list-vue v-bind="jurisprudencia" :hide_save_document="hide_save_document" :hide_quick_view="hide_quick_view"/>
    <search-general-list-vue v-bind="textolegal" :hide_save_document="hide_save_document" :hide_quick_view="hide_quick_view"/>
    <search-general-list-vue v-bind="doctrina" :hide_save_document="hide_save_document" :hide_quick_view="hide_quick_view"/>
    <search-general-list-vue v-bind="gacetas" :hide_save_document="hide_save_document" :hide_quick_view="hide_quick_view"/>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import mixins from 'common/mixins.js'
import search_mixin from 'common/mixins/search_mixin'
import bin_card_mixin from "common/mixins/bin_card_mixin"

export default {
  name: "busqueda-general-vue",
  mixins: [mixins.requests, search_mixin, mixins.translate, bin_card_mixin.requests],
  props: {
    token_id: { type: String, default: '' },
    input_search_tokens: { type: Array, default: () => [] },
    input_query_format: { type: String, default: 'substring' },
    input_query_field: { type: String, default: 'all' },
    input_search_groups: { type: Array, default: () => [] },
    search_box_visible: { type: Boolean, default: true },
    search_on_load: { type: Boolean, default: true },
    tabs_visible: { type: Boolean, default: true },
    result_header_visible: { type: Boolean, default: true },
    results_rows: { type: Number, default: 5 },
    hide_component: { type: Boolean, default: false },
    show_overflow: { type: Boolean, default: true },
    show_instructions: { type: Boolean, default: false },
    input_filters: { type: [Array, Object], default: () => [] },
    show_bin_card: { type: Boolean, default: false },
    hide_save_document: { type: Boolean, default: false },
    hide_quick_view: { type: Boolean, default: false }
  },
  components: {
    'forms-perform-search-box-vue': () => import(/* webpackChunkName: "[request]" */ "components/forms/PerformSearchBox"),
    'search-general-tabs-vue': () => import(/* webpackChunkName: "[request]" */ "components/search/GeneralTabs"),
    'search-general-list-vue': () => import(/* webpackChunkName: "[request]" */ "components/search/GeneralList"),
    'bin-card-wrapper-vue': () => import(/* webpackChunkName: "[request]" */ "components/bin_card/BINCardWrapper")
  },
  data() {
    return {
      redirecting: false,
      showInstructions: this.show_instructions,
      emptyResultsVisible: false,
      binCardMinimized: false
    }
  },
  computed: {
    ...mapState("search", ['filters', 'searching', 'groupResults', 'binCard', 'tokenId', 'searchTokens']),
    displayComponent(){
      if(this.hide_component){
        return this.filters.length > 0
      }
      return true
    },
    displayInstructions(){
      if(this.display_themes_instructions){
        return this.filters.length > 0
      }
      return true
    },
    displayResults(){
      return Object.keys(this.groupResults).length > 0
    },
    generalSearchClass(){
      var generalSearchClass = this.show_overflow ? 'overflow-auto' : ''
      generalSearchClass+= this.showInstructions ? ' h-auto' : ''
      return generalSearchClass
    },
    resultsColumnClass(){
      return this.show_bin_card ? 'col-md' : ''
    },
    searchingVisible() {
      return this.redirecting || this.searching && !this.tabs_visible
    },
    noResultsVisible() {
      return !this.redirecting && !this.searching && !this.tabs_visible && this.emptyResultsVisible
    },
    resultsTitleVisible() {
      return this.result_header_visible && this.tabs_visible && !this.redirecting
    },
    tabsVisible() {
      return this.tabs_visible && !this.redirecting
    },
    searchTokenHasCard(){
      if (this.binCard != undefined && "match" in this.binCard) {
        return  Object.keys(this.binCard).length > 0
      }
      return false
    },
    displayDomain(){
      return this.binCard.domain != ""
    },
    classSearchesWithCard(){
      return this.show_bin_card ? 'pt-5' : ''
    }
  },
  methods: {
    domainClass() {
      if(this.binCard.domain != undefined)
        return `bin-header__domain-${this.binCard.domain.toLowerCase()}`
    },
    expandBINCard(){
      this.goToBINCard(this.binCard.match, this.tokenId, this.searchTokens)
    },
    toggleMinimizeBinCard(){
      this.binCardMinimized = !this.binCardMinimized
    },
    ...mapActions('search', ['performSearch']),
    ...mapMutations('search', [
      'setTokenId',
      'setSearchTokens',
      'setQueryFormat',
      'setQueryField',
      'setSearchType',
      'setSearchGroups',
      'setRows',
      'setFilters'
    ])
  },
  watch: {
    groupResults(val) {
      if (Object.keys(val).length > 0)
        this.showInstructions = false

      if (Object.values(val).reduce((ac, n) => ac + n.total, 0) > 0) {
        this.emptyResultsVisible = false
      } else {
        this.emptyResultsVisible = true
      }
    }
  },
  created() {
    this.setSearchTokens(this.input_search_tokens)

    let search_text = (this.input_search_tokens || []).filter(token => token !== null).join(' ');
    if (this.search_on_load &&
        this.search_redirect_to_document_detail(search_text, this.input_query_format)) {
      this.redirecting = true
      return
    }

    this.setTokenId(this.token_id)
    this.setQueryFormat(this.input_query_format)
    this.setQueryField(this.input_query_field)
    this.setSearchType('general')
    this.setRows(this.results_rows)
    this.setSearchGroups(this.input_search_groups)
    this.setFilters(this.input_filters)

    if (this.search_on_load || this.searchTokens.length > 0)
      this.performSearch()
  }
}

</script>
