<template>
  <div class="facetBox">
    <div class="pb-3" v-if="is_grouped">
      <div v-for="group_facet in filters_by_group_facets" v-bind:key="group_facet.id">
        <span class="facetTitle px-3" data-toggle="collapse"
          :data-target="'#facet_' + group_facet.group">
          {{ group_facet.group }}
          <button class="itemsFacetGroup__button">
            <i class="fas fa-chevron-down"></i>
          </button>
        </span>
        <ul class="list-group-flush collapse mb-0" :id="'#facet_' + group_facet.group"
          v-bind:class="{ show: has_applied_filters(group_facet.facets) }" >
          <li class="list-group-item p-0" v-for="facet_filter in group_facet.facets"
            v-bind:key="facet_filter.id">
            <search-grouped-checkbox-facet-vue :key="facet_filter.id" v-bind="facet_filter"
              @update_filter="update_filter" v-if="!if_facet_empty(facet_filter)"/>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <ul class="list-group list-group-flush pb-0">
        <li class="list-group-item p-0" v-for="facet_filter in filters_with_facets"
          v-bind:key="facet_filter.id">
            <search-date-facet-vue :key="facet_filter.id" v-bind="facet_filter"
              @update_filter="update_filter" v-if="is_date_facet(facet_filter)"/>
            <search-alphabetical-facet-vue :key="facet_filter.id" v-bind="facet_filter"
              @update_filter="update_filter" v-if="is_alphabetical_facet(facet_filter)"/>
            <search-boolean-facet-vue :key="'bool'+facet_filter.id" v-bind="facet_filter"
              @update_filter="update_filter" v-else-if="is_boolean_facet(facet_filter)" />
            <search-grouped-checkbox-facet-vue :key="'grouped'+facet_filter.id" v-bind="facet_filter"
              @update_filter="update_filter" v-else-if="!if_facet_empty(facet_filter)"/>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import mixins from 'common/mixins.js'

export default {
  props: ['facets', 'filters', 'applied_filters', 'config'],
  data() {
    return {
      filters_with_facets: this.build_filters_with_facets(this.facets, this.filters),
      filters_by_group_facets: [],
      is_grouped: false
    }
  },
  components: {
    'search-grouped-checkbox-facet-vue': () => import(/* webpackChunkName: "[request]" */ "components/search/GroupedCheckboxFacet"),
    'search-date-facet-vue': () => import(/* webpackChunkName: "[request]" */ "components/search/DateFacet"),
    'search-boolean-facet-vue': () => import(/* webpackChunkName: "[request]" */ "components/search/BooleanFacet"),
    'search-alphabetical-facet-vue': () => import(/* webpackChunkName: "[request]" */ "components/search/AlphabeticalFacet")
  },
  mixins: [mixins.filters],
  computed: {
  },
  methods: {
    update_filter(name, values, operator) {
      this.$emit('update_filter', name, values, operator)
    },
    build_filters_by_group(){
      let unique_groups = []
      let facet_by_groups = []

      if (!this.facets)
        return facet_by_groups

      unique_groups = this.facets.map((el) => el.group ).filter((value, index, self) => {
                return self.indexOf(value) === index;
              })
      if(unique_groups[0]!= '' && unique_groups[0]!= null){
        this.is_grouped = true
        unique_groups.forEach((group)=> {
            facet_by_groups.push({'group': group,
                                  'facets': this.filters_with_facets.filter((el) => el.facet.group == group)
                                })
            })
      }
      return facet_by_groups
    },
    build_filters_with_facets(facets, filters) {
      var self = this
      var result = []

      if(!facets)
        return result

      facets.forEach((facet) => {
        var filter = JSON.parse(JSON.stringify(filters.find((element) => {return element.name == facet.id}) || self.new_filter(facet.id)))
        var applied_filter = self.applied_filters.find((element) => {return element.name == facet.id}) || self.new_filter(facet.id)
        if(facet.class_type == "alphabetical")
          filter.facet = facet
        else
          filter.facet = self.reorder_facet(facet, applied_filter)
        self.remove_parent_counts(filter.facet)
        filter.applied_values = applied_filter.values
        this.configDefaultOpenedFilters(filter, facet.class_type)
        result.push(filter)
      })
      return result
    },
    configDefaultOpenedFilters(filter, type){
      if (this.config['facets_opened']) {
        this.checkOpenFilter(this.config, filter)
      }
      var applied_special_filter = this.applied_filters.find((applied_filter) => Object.keys(this.config).includes(applied_filter.name) )
      if (applied_special_filter){
        this.checkOpenFilter(this.config[applied_special_filter.name], filter)
      }
      if (type == 'date') {
        filter.facet['default_visible'] = true
      }
    },
    checkOpenFilter(config_route, filter){
      let facets_to_open = config_route.facets_opened
      if (facets_to_open[filter.name]) {
        filter.facet['default_visible'] = true
      }
    },
    reorder_facet(facet, filter) {
      var result = JSON.parse(JSON.stringify(facet))
      if (filter.values.length > 0) {
        filter.values.reverse().forEach((some_filter) => {
          var cv = this.findNode(result.values, some_filter)
          var cv_copy = JSON.parse(JSON.stringify(cv))
          if (cv && cv != undefined && cv.children != undefined) {
              this.deleteNode(result.values, some_filter)
          }
          if (cv_copy) {
            result.values.unshift(cv_copy)
          }
        })
      }
      result.values = this.group_values_with_results_first(result.values)
      return result
    },
    group_values_with_results_first(values) {
      var self = this
      var values_with_results = []
      var values_without_results = []
      values.forEach(function(value) {
        if (value.children != undefined && value.children.length > 0)
          value.children = self.group_values_with_results_first(value.children)
        if (value.total > 0)
          values_with_results.push(value)
        else
          values_without_results.push(value)
      })
      return values_with_results.concat(values_without_results)
    },
    remove_parent_counts(facet) {
      facet.values.forEach((parent) => {
        if (parent.children.length > 0 && !parent.children.some((child) => { return child.total > 0}))
          parent.total = 0
      })
    },
    is_date_facet(facet_filter) {
      return facet_filter.facet.class_type == 'date'
    },
    is_boolean_facet(facet_filter) {
      return facet_filter.facet.class_type == 'boolean'
    },
    if_facet_empty(facet_filter) {
      return facet_filter.facet.values.length == 0
    },
    is_alphabetical_facet(facet_filter) {
      return facet_filter.facet.class_type == 'alphabetical'
    },
    has_some_filter(facet_group) {
      let facet_length = facet_group.map((el) => el.facet.values.length)
      return facet_length.some((x) => x > 0)
    },
    has_applied_filters(facet_group) {
      let facet_length = facet_group.map((el) => el.applied_values.length)
      return facet_length.some((x) => x > 0)
    },
  },
  watch: {
    facets(new_value, old_value) {
      this.filters_with_facets = this.build_filters_with_facets(new_value, this.filters)
      this.filters_by_group_facets = this.build_filters_by_group()
    },
    filters(new_value, old_value) {
      this.filters_with_facets = this.build_filters_with_facets(this.facets, new_value)
      this.filters_by_group_facets = this.build_filters_by_group()
    }
  },
}
</script>
