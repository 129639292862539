export default {
  requests: {
    data () {
      return {
        typeform_url: 'https://sqbz34hbi62.typeform.com/FIchas-BIN'
      }
    },
    methods: {
      goToBINCard(search_token, token_id, search_tokens) {
        var regex = /[^a-zA-Z0-9-_\u00C0-\u00FF]/g
        const binCardId = search_token.replace(regex, ' ')
        const encodedSearchToken = encodeURIComponent(binCardId)
        const baseUrl = `${gon.url_for.bin_card_path.replace(':id', encodedSearchToken )}`
        const params = new URLSearchParams()
        if(token_id)
          params.append('token_id', token_id)
        if(search_tokens[0])
          params.append('search_token', search_tokens[0])
        window.location.href = `${baseUrl}?${params}`
      }
    },
    computed: {
      typeFormFeedbackLink(){
        if (!this.bin_card) {
          return false
        }
        const paisiso = gon.paisiso
        let utm_params = new URLSearchParams()
        utm_params.append('bincard_id', this.bin_card.match)
        utm_params.append('paisiso', paisiso)
        return `${this.typeform_url}?${utm_params}`
      }
    }
  }
}
