import Vue from 'vue/dist/vue.esm'

Vue.component(
  "conversa-wrapper-vue",
  () => import(/* webpackChunkName: "[request]" */ "components/conversa/Wrapper")
)

Vue.component(
  "conversa-wrapper-ext-vue",
  () => import(/* webpackChunkName: "[request]" */ "components/conversa/Wrapper-ext")
)

Vue.component(
  "conversa-button-vue",
  () => import(/* webpackChunkName: "[request]" */ "components/conversa/ConversaButton")
)
