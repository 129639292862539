import MD5 from 'crypto-js/md5'
import ApiClient from 'common/ApiClient'
import { formatNumberTol } from 'common/documentUtils'

const REGEX_LOOKBEHIND = '(?<=(^|>)[^<]*scope)'
const REGEX_LOOKAHEAD = '(?=scope[^>]*(<|$))'

const textProcessor = (tolgeo, text, exprs) => {
  let finalText = `${text}`
  let replacementsMap = {}

  let expressions = getExpressions(exprs)
  let keys = Object.keys(expressions).sort((a, b) => b.length - a.length).slice(0, 500)

  keys.forEach((expr) => {
    let data = expressions[expr]

    let regexScopes = buildRegexScopes(expr, data)
    let regexKey = buildRegexKey(buildRegex(expr), regexScopes)
    let matches = Array.from(finalText.matchAll(regexKey))
    while (matches.length > 0) {
      matches.forEach((match) => {
        let hashCode = `repl_${MD5(match[0]).toString()}`
        let reSub = buildRegexKey(escapeRegexChars(match[0]), regexScopes)
        replacementsMap[hashCode] = buildLink(tolgeo, match[0], data)
        finalText = finalText.replace(reSub, hashCode)
      })
      matches = Array.from(finalText.matchAll(regexKey))
    }
  })

  for (var [key, repl] of Object.entries(replacementsMap)) {
    finalText = finalText.replace(new RegExp(key, 'g'), repl)
  }

  return finalText
}

const getExpressions = (exprs) => {
  let expressions = {}
  for (var [key, data] of Object.entries(exprs)) {
    let transformedKey = transformKey(key)
    if (transformedKey.length > 1 && transformedKey.length < 200)
      expressions[transformedKey] = data
  }

  return expressions
}

const transformKey = (key) => {
  return key.replace(/\s+/g, ' ').trim()
}

const buildRegex = (key) => {
  if (!key)
    return ''

  return escapeRegexChars(key)
    .replace(/[aá]/g, '[aá]')
    .replace(/[eé]/g, '[eé]')
    .replace(/[ií]/g, '[ií]')
    .replace(/[oó]/g, '[oó]')
    .replace(/[uú]/g, '[uú]')
    .replace(/[nñ]/g, '[nñ]')
    .replace(/\s/g, '[^\\w<>]*')
}

const escapeRegexChars = (key) => {
  return key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

const buildRegexScopes = (expr, data) => {
  let scope = ['', '']
  if (data['expr_orig'])
    scope = transformKey(data['expr_orig']).split(expr)

  return [
    REGEX_LOOKBEHIND.replace('scope', buildRegex(scope[0])),
    REGEX_LOOKAHEAD.replace('scope', buildRegex(scope[1]))
  ]
}

const buildRegexKey = (key, regexScopes) => {
  return new RegExp(`${regexScopes[0]}\\b(?<m>${key})\\b${regexScopes[1]}`, 'img')
}

const calculateLinkTitle = (tolgeo, data) => {
  if (data['title'])
    return data['title']
  else
    return formatNumberTol(tolgeo, data['docid'])
}

const buildLink = (tolgeo, text, data) => {
  let attrs
  let css_class
  if (data['latinajo']) {
    attrs = { latinajo: data['latinajo'] }
    css_class = 'tol_latinajo'
  } else {
    attrs = { docid: data['docid'] }
    css_class = 'tol_link'
  }

  let attributes = Object.entries(attrs).map(([key, val]) => `data-${key}="${val}"`).join(' ')
  let title = calculateLinkTitle(tolgeo, data)
  return `<span title="${title}" ${attributes} class="tolmatic ${css_class}">${text}</span>`
}

const fetchLinks = async (id) => {
  const api = new ApiClient()

  const response = await api.get(gon.url_for.link_path.replace(':id', id))

  return response.data
}

export { textProcessor, fetchLinks }
