<template lang="pug">
div.modal.show.modal-active.fade(
  :id="modal_id"
  tabindex="-1"
  role="dialog"
  :aria-labelledby="title"
  aria-hidden="true"
  v-show="visible"
)
  div.modal-dialog.modal-dialog-centered.modal-dialog-scrollable(
    :class="size_modal"
    role="document"
  )
    div.modal-content(v-on-clickaway="closeModal")
      div.modal-header
        slot(name="header")
        button.align-self-center.close(
          @click="closeModal"
          v-if="autoCloseEnabled"
        )
          span(aria-hidden="true")
            | &times;
      div.modal-body.px-4.pb-0
        slot(v-if="content_loaded" name="body")
        div.modal-loader(v-else)
          div.lds-css.ng-scope
            div.loader-general.mx-auto
              div
      div.modal-footer.px-4.pb-4.border-top-0
        slot(name="footer")
          button(
            @click="handleAcceptButtonClicked"
            id="accept"
            class="btn btn-primary"
          )
            | {{ acceptLabel }}
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'

export default {
  name: 'modal-vue',
  props: {
    modal_id: { type: String, default: '' },
    content_loaded: { type: Boolean, default: false },
    size_modal: { type: String, default: '' }, 
    visible: { type: Boolean, default: false },
    title: { type: String, default: ''},
    autoCloseEnabled: { type: Boolean, default: false }
  },
  directives: {
    onClickaway: onClickaway
  },
  computed: {
    acceptLabel: () => I18n.t('components.forms.button.accept')
  },
  methods: {
    handleAcceptButtonClicked() {
      this.$emit('accept_button:clicked')
    },
    closeModal() {
      if(this.autoCloseEnabled && this.content_loaded && this.visible)
        this.$emit('update:visible', false)
    }
  }
}
</script>
