<template>
<div v-if="showBreadcrumbs">
  <breadcrumb-vue :breadcrumbs="breadcrumbs"/>
</div>
</template>

<script>

import mixins from "common/mixins"
import Breadcrumb from "./Breadcrumb.vue"
import { mapState } from 'vuex'
import { hierarchyBreadcrumb } from "common/classes/breadcrumbs.js";
import { materiasVocesBreadcrumb } from "common/classes/breadcrumbs.js";

export default {
  name: 'filter-breadcrumb-vue',
  mixins: [mixins.translate, mixins.filters],
  props: {
    filter_name: { type: String },
  },
  data() {
    return {
      breadcrumbs: [],
    }
  },
  components: {
    'breadcrumb-vue': Breadcrumb
  },
  computed: {
    ...mapState("search", ["filters"]),
    showBreadcrumbs (){
      let service = []
      if(this.filters.length > 0 && this.filter_name.length > 0){
        service = this.filter_name == 'themine-hierarchy_global_temas_mb'
          ? new materiasVocesBreadcrumb(this.filters, this.filter_name)
          : new hierarchyBreadcrumb(this.filters, this.filter_name)
        this.breadcrumbs = service.getBreadcrumbs()
        return true
      }
      return false
    },
  },
}
</script>
