export default function(bus) {
  bus.$on("document_load_request", async document_id => {
    let url = `/base/${gon.current_app}/searches/${document_id}`
    let response = await fetch(url)
    let data = await response.json()

    if (!response.ok) {
      bus.$emit("document_load_error")
      return
    }

    bus.$emit("document_loaded", { data })
  })
}
