<template lang="pug">
  div
    button.btn.btn-block.btn-multiselect(type="button" @click="showModal" :class="buttonClass")
      template(v-if="selectedCount == 0")
        | {{ t(`search.${field}_title`) }}
        | ...
        i.fas.fa-plus-circle.fa-2x
      template(v-else)
        span.text-secondary
          | {{ t(`search.${field}_change`) }}:
        span.font-italic
          | {{ selectedFirst2 }}
        template(v-if="selectedExtra > 0")
          | {{ selectedExtraLabel }}
    input.d-none(:name="`${field}[]`" type="checkbox" :value="select" v-for="select in selected" checked)
    forms-confirmation-dialog-selector-vue(
      :data="data"
      :input-selected="selected"
      ref="confirmation"
      @confirm="confirm"
      component="forms-autocomplete-tree-selector-vue"
      :title="t(`search.${field}_title`)"
    )
</template>

<script>
import mixins from 'common/mixins'

export default {
  name: 'forms-multi-select-button-vue',
  props: {
    field: { type: String, default: '' },
    data: { type: Array, default: () => [] },
    inputSelected: { type: Array, default: () => [] },
  },
  mixins: [ mixins.translate, mixins.filters ],
  components: {
    'forms-confirmation-dialog-selector-vue': () => import(/* webpackChunkName: "[request]" */ "components/forms/ConfirmationDialogSelector")
  },
  data() {
    return {
      selected: this.inputSelected
    }
  },
  computed: {
    selectedCount() {
      return this.selected.length
    },
    buttonClass() {
      return this.selectedCount > 0 ? 'btn-outline-primary selected_items' : 'btn-outline-blue'
    },
    selectedFirst2() {
      return this.selected
        .slice(0, 2)
        .map((value) => this.findNode(this.data, value).name)
        .join(', ')
    },
    selectedExtra() {
      return Math.max(this.selected.length - 2, 0)
    },
    selectedExtraLabel() {
      return this.t("components.forms.multi_select_button.selected_extra_label", {
        extra: this.selectedExtra
      })
    }
  },
  methods: {
    confirm(confirmed) {
      this.selected = confirmed
    },
    showModal() {
      this.$refs.confirmation.showModal()
    },
    clean() {
      this.selected = []
    }
  }
}
</script>