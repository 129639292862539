<template lang="pug">
  .dashboard-container.services-content
    h5.pt-5.pl-3 {{ t('components.access_services.acceso').toUpperCase() }}
    .row.service-row
      .service.col-lg-4.col-md-6.d-flex.p-0.justify-content-center(
        v-for="button in buttons"
        :key="button.name"
      )
        a.btn.button-services.mt-4.text-left(:href="button.url" :target="button.new_tab ? '_blank' : ''" )
          b {{ t('components.access_services.buttons.' + button.name) }}
          img(:src="classObjectPrueba(button.icon)" alt="icon")
</template>

<script>
  import mixins from "common/mixins"

  export default {
    name: "access-services-vue",
    mixins: [mixins.translate],
    props: {
      buttons: {
        type: Array,
        default: []
      }
    },
    methods: {
      classObjectPrueba(icon){
        return '/base/assets/conmex/' + icon + '.png'
      }
    }
  }
</script>
