import Vue from 'vue/dist/vue.esm'

Vue.component(
  'documents-header-vue',
  () => import(/* webpackChunkName: "[request]" */ "components/documents/Header")
)
Vue.component(
  'pdf-viewer-vue',
  () => import(/* webpackChunkName: "[request]" */ "components/files/PdfViewer")
)
Vue.component(
  'documents-options-button-vue',
  () => import(/* webpackChunkName: "[request]" */ "components/documents/OptionsButton")
)