import ApiClient from 'common/ApiClient'

const state = {
  isLoading: false,
  errors: [],
  newsletterEnabled: false
}

const mutations = {
  start_loading(state) { state.isLoading = true },
  end_loading(state) { state.isLoading = false },
  add_error(state, data) { state.errors.push(data) },
  clear_errors(state) { state.errors = [] },
  set_newsletter_status(state, data) { state.newsletterEnabled = data.newsletter_enabled }
}

const actions = {
  async setNoveltySubscription(context, newState) {
    const apiClient = new ApiClient()
    let url = `/base/${gon.current_app}/user_subscriptions/novelties/update`

    context.commit('start_loading')
    let response = await apiClient.put(url, newState)

    context.commit('end_loading')
    
    if(response.failed){
      context.commit('add_error', response.error)
      return !newState
    }

    context.commit('set_newsletter_status', response.data)
    return state.newsletterEnabled
  },
}

export default {
  namespaced: true,
  name: "user_subscription",
  state,
  mutations,
  actions
}