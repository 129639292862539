<template>
<div class="boxBreadcrumb my-4" v-if="showBreadcrumbs">
  <div class="boxBreadcrumb__row">
    <ul class="boxBreadcrumb__row--list mb-0" v-for="value in filterBreadcrumbs" :key="value">
      <li >{{value}} <i class="fas fa-chevron-right"></i></li>
    </ul>
  </div>
</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: "breadcrumb-vue",
  props: {
    breadcrumbs: { type: Array, default: () => [] }
  },
  data() {
    return {
      filterBreadcrumbs: []
    }
  },
  computed: {
    ...mapState("search", ["breadcrumbsForSelected"]),
    showBreadcrumbs() {
      let breadcrumbsToShow = [];
      if (this.hasBreadcrumbs) {
        breadcrumbsToShow = this.breadcrumbs;
      } else if (this.hasStoreBreadcrumbs) {
        breadcrumbsToShow = this.breadcrumbsForSelected;
      }
      this.filterBreadcrumbs = breadcrumbsToShow;
      return breadcrumbsToShow.length > 0;
    },
    hasBreadcrumbs() {
      return this.breadcrumbs.length > 0;
    },
    hasStoreBreadcrumbs() {
      return this.breadcrumbsForSelected.length > 0;
    }
  },
}
</script>
