<template lang="pug">
section(:id="id" :style="{'font-size': fontSize+'%'}")
  h5(:style="{'font-size': fontSize+'%'}")
    | {{ label.toUpperCase() }}
  documents-paragraph-vue(
    v-for="(paragraph, index) in value"
    :key="index"
    :text="paragraph"
    :cleaned_tokens="cleaned_tokens"
    :show_all="show_all"
    :highlight_add_buttons="highlight_add_buttons"
    :highlight_background="highlight_background"
    :links-data="linksSectionData"
  )
  button.btnUP(@click="goUp" v-if="goUpButtonVisible")
    i.fas.fa-arrow-up
    | {{ t('components.documents.section.go_up') }}
</template>

<script>
import mixins from 'common/mixins'
import { mapState } from 'vuex'

export default {
  props: {
    id: { type: String, default: '' },
    label: { type: String, default: '' },
    value: { type: Array, default: () => [] },
    cleaned_tokens: { type: Array, default: () => [] },
    show_all: { type: Boolean, default: false },
    highlight_add_buttons: { type: Boolean, default: false },
    highlight_background: { type: Boolean, default: false },
    goUpButtonVisible: { type: Boolean, default: false },
    linksData: { type: Object, default: () => { return {} } },
  },
  components: {
    'documents-paragraph-vue': () => import(/* webpackChunkName: "[request]" */ "components/documents/Paragraph")
  },
  computed: {
    linksSectionData() {
      return this.linksData[this.id] || {}
    },
    ...mapState(['fontSize'])
  },
  mixins: [mixins.translate],
  methods: {
    goUp() {
      $(this.$el).scrollParent()[0].scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>
