export default class {
  constructor(bus) {
    this.bus = bus
  }
  async call() {
    let url = get_url()
    let response = await fetch(url)

    if (!response.ok) {
      this.bus.$emit("folders_retrieved_failed")
      return
    }

    let data_formatted = map_sofia_folders(await response.json())
    data_formatted.children.sort((current, next) => { return next.text.localeCompare(current.text) });

    this.bus.$emit("folders_retrieved", data_formatted)
  }
}

let get_url = () => {
  return `/base/${gon.current_app}/sofia_file_analyzer/user_subscription_folders?`
}

let map_sofia_folders = (response) => {
  let response_root_folder = response.folders.folders_tree[0];

  let root_folder = folder_attribute(
    response_root_folder.id,
    "/",
    response_root_folder.path,
    [],
    0,
    true,
    true
  );

  response.folders.folders_tree.forEach((element) => {
    if (element.parent == "0") {
      root_folder.children.push(
        folder_attribute(
          element.id,
          element.text,
          element.path,
          [],
          1,
          false,
          true
        )
      );
    } else {
      if (element.parent != "#") {
        let folder = search_folder_by_id(root_folder, element.parent);

        if (folder != undefined) {
          let folder_depth = (element.path.match(/\//g) || []).length - 3;

          folder.children.push(
            folder_attribute(
              element.id,
              element.text,
              element.path,
              [],
              folder_depth,
              false,
              false
            )
          );
        }
      }
    }
  });
  return root_folder
}

let folder_attribute = (id, text, path, children, depth, selected, show) => {
  return {
    id: id,
    text: text,
    path: path,
    children: children,
    depth: depth,
    selected: selected,
    show: show,
  };
}

let search_folder_by_id = (folder, id, found_folder) => {
  if (folder.id == id) {
    found_folder = folder;
    return folder;
  } else {
    if (folder.children != undefined) {
      folder.children.forEach((child) => {
        let temp_folder_result = search_folder_by_id(
          child,
          id,
          found_folder
        );
        if (temp_folder_result != null) {
          found_folder = temp_folder_result;
          return temp_folder_result;
        }
      });
    }
  }
  return found_folder;
}