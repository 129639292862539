import Section from 'components/documents/Section.vue'
import mixins from 'common/mixins.js'

export default {
  mixins: [mixins.requests],
  props: {
    id: { type: Number, default: 0 },
    title: { type: String, default: '' },
    numeroTOL: { type: String, default: '' },
    cover: { type: String, default: '' },
    header_fields: { type: Array, default: () => [] },
    body_fields: { type: Array, default: () => [] },
    metadata_fields: { type: Array, default: () => [] },
    list_fields: { type: Array, default: () => [] },
    indice_fields: { type: Array, default: () => [] },
    file_fields: { type: Array, default: () => [] },
    clazz: { type: String, default: '' },
    indice_virtual: { type: Object, default: () => { return {} } },
    descriptores: { type: Object, default: () => { return {} } },
    group_ids: { type: Array, default: () => [] },
    search_tokens: { type: Array, default: () => [] },
    mine: { type: Object, default: () => { return {} } },
    calculated: {},
    translations: {},
    isbn13: {}
  },
  components: {
    'documents-section-vue': Section
  }
}
