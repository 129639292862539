<template>
  <div class="w-100">
    <p v-if="document_id" class="col text-right mb-2">
      {{t("sofia.tirantcloud.document_editor")}}
      <a target="_blank" rel="noopener noreferrer" class="btn btn-link m-0 p-0" :href="url_for_rails_documento_show(document_id)"> {{docids_prefix}}{{document_id}}</a>
    </p>
    <div id="tirantcloud-container" v-once />
    <modal-quick-view-vue :bus="bus" :copy-buttons-visible="true"/>
    <sofia-filter-modals-vue
      ref="sofia-filter-modals"
      @confirm_filter_values="confirmFilterValues"
      :sofia-filters="sofiaFilters"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import mixins from 'common/mixins'
import tirantCloud from 'common/mixins/tirantcloud'

export default {
  name: 'tirant-cloud-wrapper-vue',
  props: {
    path: { type: String, default: "" },
  },
  mixins: [mixins.requests, mixins.translate, tirantCloud],
  components: {
    "modal-quick-view-vue": () => import(/* webpackChunkName: "[request]" */ "components/ModalQuickView")
  },
  data() {
    return {
      bus: new Vue(),
      show_document_link: false,
      show_new_document_menu: false,
      document_id: "",
      saveStatusVisible: true,
      documentTitleVisible: true
    }
  },
  computed: {
    folder_path() { return this.user_path }
  },
  methods: {
    show_document(document_id, document_type) {
      this.bus.$emit("document_show", document_id, document_type)
    },
    on_document_metadata_changed(metadata = {}) {
      if(metadata.document_id !== undefined) {
          this.http_get({
            url: this.url_for_rails_documento_show(metadata.document_id),
            success: (response) => {
              if(response.status == 200)
                this.document_id = metadata.document_id
            }
          })
      } else {
        this.document_id = ""
      }
    }
  },
  mounted() {
    this.tirantCloud.generateFileList({
      selector: 'tirantcloud-container',
      title: this.t('components.tirantcloud.file_list.title'),
      rootPath: this.user_path,
      path: this.user_path,
      onSofiaDocumentClick: this.show_document,
      onDocumentMetadataChanged: this.on_document_metadata_changed,
      redirectPath: this.path,
      showNewDocumentMenu: this.show_new_document_menu
    });
  }
};
</script>
