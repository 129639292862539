import Vue from 'vue/dist/vue.esm'

Vue.component(
  "tirant-cloud-wrapper-vue",
  () => import(/* webpackChunkName: "[request]" */ "components/TirantCloudWrapper")
)

Vue.component(
  "sofia-premium-form-vue",
  () => import(/* webpackChunkName: "[request]" */ "components/sofia/PremiumForm")
)
