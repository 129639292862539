<template>
  <div class="create_folder">
    <div
      v-if="new_folder"
      class="p-2 bg-light"
    >
      <span>
        {{ t("components.modal_folder_save.message_save_folder") }}
      </span>
      <div class="d-flex mt-2">
        <input
          v-model="new_folder_input"
          class="form-control col-6"
          type="text"
          ref="input_new_folder"
          id="input_new_folder"
          :placeholder="t('components.modal_folder_save.create_folder.folder_name')"
          v-on:keyup.enter="handle_save_folder"
          :disabled="is_loading"
        />
        <button id="create_link" :class="disabled_class" class="btn btn-primary ml-2" @click="handle_save_folder" :disabled="disabled">
          {{ create_folder_label }}
        </button>
        <button :class="disabled_class_btn_cancel" class="btn btn-secondary ml-2" @click="toggle_create_folder" :disabled="disabled_btn_cancel">
          {{ t("components.modal_folder_save.create_folder.cancel") }}
        </button>
      </div>
    </div>
    <div v-else class="d-flex justify-content-between align-items-center">
      <span>
        {{ t("components.modal_folder_save.message_save_file") }}
      </span>
      <button
        id="new_folder"
        class="btn btn-outline-info btn-sm"
        @click="toggle_create_folder"
      >
        <folder-icon size="1.5x" class="custom-class" />
        {{ t("components.modal_folder_save.create_folder.new_folder") }}
      </button>
    </div>
  </div>

</template>

<script>
import mixins from "common/mixins"
import CreateFolderService from "common/services/create_folder"
import { FolderIcon } from "vue-feather-icons"
import Vue from 'vue'

export default {
  name: "create-folder-vue",
  mixins: [mixins.translate, mixins.requests],
  props: ["bus", "selected_folder"],
  components: {
    FolderIcon
  },

  data() {
    return {
      is_loading: false,
      new_folder: false,
      new_folder_input: "",
      has_text: false
    }
  },
  computed: {
    create_folder_label() {
      if (this.is_loading) {
        return  I18n.t("components.modal_folder_save.create_folder.creating")
      }
      return I18n.t("components.modal_folder_save.create_folder.create")
    },
    disabled() {
      return this.is_loading || !this.has_text
    },
    disabled_class() {
      return { disabled:  this.disabled}
    },
    disabled_btn_cancel() {
      return this.is_loading
    },
    disabled_class_btn_cancel() {
      return { disabled: this.disabled_btn_cancel }
    },
    create_folder() {
      return new CreateFolderService(this.bus)
    }
  },
  watch: {
    new_folder_input(val) {
      this.has_text = Boolean(val)
    }
  },
  methods: {
    toggle_create_folder() {
      this.new_folder = !this.new_folder
      this.bus.$emit("create_folder_toggle")
      this.focus_folder_name_input()
      this.new_folder_input = ""
    },
    focus_folder_name_input(){
     Vue.nextTick().then(() => {
        this.$refs.input_new_folder.focus();
      });
    },
    handle_save_folder() {
      if(!this.has_text) return;

      this.is_loading = true
      this.create_folder.call(this.selected_folder, this.new_folder_input)
      this.bus.$emit("new_folder_created", {
        path_new_folder: this.selected_folder + this.new_folder_input + '/'
      })
    }
  },
  created() {
    this.bus.$on("create_folder_failed", () => {
      this.is_loading = false
    })

    this.bus.$on("create_folder_without_refresh",() => {
      this.is_loading = false
      this.new_folder = false
    })
  }
}
</script>
