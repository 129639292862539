<template>
<div>
  <input id="general" type="text" v-model="search_text" ref="search_box"/>
  <a id="voice-micro" :class="{listening:listening}" :disabled="listening" @click="get_voice(set_text, submit_form)"></a>
  <a id="operator_y" @click="insert_operator(' Y ')">Y</a>
  <a id="operator_o" @click="insert_operator(' O ')">O</a>
  <a id="operator_no" @click="insert_operator('!')">NO</a>
  <a id="show_search_opcions" @click.stop="show_options">Opciones</a>
  <div id="search_options" v-show="options_visible" v-on-clickaway="hide_options">
    Render opciones de búsqueda
  </div>
</div>
</template>
<script>
import { directive as onClickaway } from 'vue-clickaway';
import mixins from 'common/mixins.js'
import { mapMutations } from 'vuex'

export default {
  props:{
    conversaSearch: { type: Boolean, default: false }
  },
  data() {
    return {
      options_visible: false,
      search_text: '',
      selected : false
    }
  },
  mixins: [mixins.voice],
  directives: {
    onClickaway: onClickaway,
  },
  mounted() {
    this.focus_input();
  },
  methods: {
    insert_operator(operator) {
      this.search_text += operator
    },
    show_options() {
      this.options_visible = !this.options_visible
    },
    hide_options() {
      this.options_visible = false
    },
    clean() {
      this.search_text = ''
    },
    set_text(text) {
      this.search_text = text
    },
    submit_form() {
      var form = this.$refs.micro.closest('form')
      form.submit();
    },
    focus_input() {
      this.$refs.search_box.focus()
    },
    ...mapMutations('conversa', ['setConversaSearch'])
  },
  watch: {
    search_text(newValue) {
      if (newValue && this.conversaSearch) {
        this.setConversaSearch(newValue)
      }
    }
  }
}

</script>