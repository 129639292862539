let filled = (...args) => {
  if(args[0] == null ){
    return false
  }
  let value = extract_value(args[0])
  
  return value.length > 0
}

let length_between = (...args) => {
  let value = extract_value(args[0])
  let params = args[1]
  let min_length = params[0]
  let max_length = params[1]

  return (value.length >= min_length && value.length <= max_length)
} 

let changed = (...args) => {
  let values = args[0]

  return values[0] != values[1]
}

let year_format = (...args) => {
  let value = extract_value(args[0])
  var regex_year = /^(19|20)\d{2}$/

  return regex_year.test(value)
}

let checked = (...args) => {
  let value = args[0]
  return value
}

let email_format = (...args) => {
  let value = extract_value(args[0])
  var regex_email = 
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return regex_email.test(value)
}

let extract_value = values => {
  if(Array.isArray(values)){
    return values[0]
  } 

  return values
}

let rfc_code_format = (...args) => {
  let value = extract_value(args[0])
  let regex_rfc_code = 
    /[A-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z,0-9]{0,3}/

  return regex_rfc_code.test(value)
}

let no_blanks = (...args) => {
  let value = extract_value(args[0])

  return !/\s/.test(value)
}

let validations = { 
  filled, 
  length_between, 
  changed, 
  email_format,
  checked,
  year_format,
  rfc_code_format,
  no_blanks
}

export default validations