import Vue from 'vue/dist/vue.esm'

Vue.component(
  "sofia-file-analyzer-vue",
  () => import(/* webpackChunkName: "[request]" */ "components/sofia/FileAnalyzer")
)
Vue.component(
  "sofia-header-vue",
  () => import(/* webpackChunkName: "[request]" */ "components/sofia/Header")
)
Vue.component(
  "sofia-home-vue",
  () => import(/* webpackChunkName: "[request]" */ "components/sofia/Home")
)
Vue.component(
  "sofia-premium-form-vue",
  () => import(/* webpackChunkName: "[request]" */ "components/sofia/PremiumForm")
)