export default class {
  async call(url, params){
    let response = await fetch(url, {
      method: "POST",
      headers: {
        'Accept': 'application/json, multipart/form-data',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify(params)
    })
    return response
  }
}

