export default class ApiClient {
  constructor() {
    this.headers = { 'Content-Type': 'application/json' }
    this.data = {}
  }

  async get(endpointUrl, id = '') {
    const url = id == '' ? endpointUrl : this.obtainUrl(endpointUrl, id)
    const response = await fetch(url, { headers: this.headers })

    if (response.ok) {
      this.data = await response.json()
    }

    return {
      ok: response.ok,
      failed: !response.ok,
      data: this.data
    }
  }

  async post(endpointUrl, payload) {
    payload = { ...payload, format: 'json' }

    const response = await fetch(endpointUrl, {
      headers: this.headers,
      method: 'POST',
      body: JSON.stringify(payload)
    })

    if (response.ok) {
      this.data = await response.json()
    }

    return {
      ok: response.ok,
      failed: !response.ok,
      data: this.data
    }

  }

  async put(endpointUrl, payload) {
    const response = await fetch(endpointUrl, {
      headers: this.headers,
      method: 'PUT',
      body: JSON.stringify(payload)
    })

    if (response.ok) {
      this.data = await response.json()
    }

    return {
      ok: response.ok,
      failed: !response.ok,
      data: this.data
    }
  }

  obtainUrl(endpointUrl, id) {
    return `${endpointUrl}/${id}`
  }
}
