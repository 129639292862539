<template lang="pug">
  .form-row
    .col-sm-4.mt-2
      .d-flex
        select.form-control.mr-1(v-model="selector" :name="`${name}_type`")
          option(v-for="option in ['range', 'one']" :key="`${name}_${option}`" :value="option")
            | {{ t(`search.date_selector.${option}`) }}
        span.btn.btn-secondary.btn-circle(
          type="button"
          :title="t('search.ayuda.title_ayuda')"
          data-toggle="modal"
          data-target="#fechas_ayuda_modal"
        )
          i.fas.fa-question.fa-fw
    .col-sm-4.mt-2
      input.d-none(:name="`${name}_desde`" v-model="formattedFechaDesde")
      .input-group
        input.form-control.datepicker(
          :placeholder="label ? t('components.forms.date_selector.desde', { field: label }) : t('desde')"
          @blur="validate"
          v-model="fechaDesde"
          data-provide="datepicker"
          onchange="this.dispatchEvent(new Event('input'))"
          :class="{ 'is-invalid': invalidFechaDesde }"
          ref="fecha_desde"
        )
        .input-group-append(@click="focusInput('fecha_desde')")
          span.input-group-text
            i.far.fa-calendar-alt
      .invalid-feedback
        | {{ t('search.date_error') }}
    .col-sm-4.mt-2
      input.d-none(:name="`${name}_hasta`" v-model="formattedFechaHasta")
      .input-group
        input.form-control.datepicker(
          :placeholder="label ? t('components.forms.date_selector.hasta', { field: label }) : t('hasta')"
          @blur="validate"
          v-model="fechaHasta"
          data-provide="datepicker"
          onchange="this.dispatchEvent(new Event('input'))"
          :class="{ 'is-invalid': invalidFechaHasta }"
          ref="fecha_hasta"
          :disabled="notRange"
        )
        .input-group-append(@click="focusInput('fecha_hasta')")
          span.input-group-text
            i.far.fa-calendar-alt
      .invalid-feedback
        | {{ t('search.date_error') }}
</template>

<script>
import mixins from 'common/mixins'

export default {
  name: 'forms-date-selector-vue',
  props: {
    selected: { type: Array, default: () => ['', ''] },
    name: { type: String, default: '' },
    label: { type: String, default: '' }
  },
  mixins: [mixins.translate],
  data() {
    var selector = this.selected[0] == this.selected[1] && this.selected[0] != '' ? 'one' : 'range'
    return {
      selector: selector,
      fechaDesde: this.reverseFormatDate(this.selected[0]),
      fechaHasta: selector == 'range' ? this.reverseFormatDate(this.selected[1]) : '',
      invalidFechaDesde: false,
      invalidFechaHasta: false
    }
  },
  computed: {
    notRange() {
      return this.selector != 'range'
    },
    formattedFechaDesde() {
      return this.formatDate(this.fechaDesde, true)
    },
    formattedFechaHasta() {
      if (this.notRange)
        return this.formatDate(this.fechaDesde, false)
      else
        return this.formatDate(this.fechaHasta, false)
    }
  },
  methods: {
    formatDate(date, isStart) {
      if (date == '')
        return ''
      var regex = /^(((?<day>\d{2,})\s*(\/|-|\.|de)?\s*)?(?<month>\d{2,})\s*(\/|-|\.|de)?\s*)?(?<year>\d{4})$/
      var months = {
        '01': /ene(ro)?|jan(uary)?/i,
        '02': /feb(rero)?(ruary)?/i,
        '03': /mar(zo)?(ch)?/i,
        '04': /abr(il)?|apr(il)?/i,
        '05': /may(o)?/i,
        '06': /jun(io)?(e)?/i,
        '07': /jul(io)?(y)?/i,
        '08': /ago(sto)?|aug(ust)?/i,
        '09': /sep(tiembre)?(tember)?/i,
        '10': /oct(octubre)?(ober)?/i,
        '11': /nov(iembre)?(ember)?/i,
        '12': /dic(iembre)?|dec(ember)?/i,
      }
      var tmp = date
      Object.keys(months).forEach((number) => {
        tmp = tmp.replace(months[number], number)
      })
      var match = tmp.match(regex)
      if (match == null)
        return null

      var year = match.groups.year
      var month = match.groups.month
      var day = match.groups.day

      if (month == null)
        month = isStart ? '01' : '12'
      if (day == null) {
        day = isStart ? '01' : new Date(parseInt(year), parseInt(month), 0).getDate();
      }
      return `${year}-${month}-${day}`
    },
    reverseFormatDate(date) {
      return date.split('-').reverse().join('/')
    },
    validate() {
      this.invalidFechaDesde = this.formattedFechaDesde == null
      this.invalidFechaHasta = this.formattedFechaHasta == null
    },
    clean() {
      this.selector = 'range'
      this.fechaDesde = ''
      this.fechaHasta = ''
    },
    focusInput(field) {
      this.$refs[field].focus()
    }
  },
  watch: {
    selector(newValue) {
      if (newValue == 'one') {
        this.fechaHasta = ''
      }
    },
    selected(newValue) {
      var safeValue = newValue || ['', '']
      this.fechaDesde = this.reverseFormatDate(safeValue[0])
      this.fechaHasta = this.reverseFormatDate(safeValue[1])
    },
    formattedFechaDesde(newValue) {
      if(newValue != null)
        this.invalidFechaDesde = false
      this.$emit('update:selected', [this.invalidFechaDesde ? '' : newValue, this.invalidFechaHasta ? '' : this.formattedFechaHasta])

    },
    formattedFechaHasta(newValue) {
      if(newValue != null)
        this.invalidFechaHasta = false
      this.$emit('update:selected', [this.invalidFechaDesde ? '' : this.formattedFechaDesde, this.invalidFechaHasta ? '' : newValue])
    }
  }
}
</script>
