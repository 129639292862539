let state = {
  show_conversa: false,
  conversa_search: ''
}

let mutations = {
  setShowConversa(state, show_conversa) { state.show_conversa = show_conversa },
  setConversaSearch(state, data) { state.conversa_search = data }
}

export default {
  namespaced: true,
  name: "conversa",
  state,
  mutations
}
