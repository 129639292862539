<template lang='pug'>
modal-vue(
    :visible.sync="isVisible",
    modal_id="notification_modal"
    :content_loaded="true"
  )
    template(v-slot:header)
      | {{ title }}
    template(v-slot:body)
      div.notifiaction-text
        | {{ text }}
    template(v-slot:footer)
      button.btn.btn-primary(
        type="button"
        @click="hide"
        data-cy="accept-button"
      )
        | {{ t("components.forms.button.accept") }}
</template>

<script>
import ModalVue from "./Modal.vue";
import mixins from 'common/mixins'
export default {
  name: 'notification-modal-vue',
  mixins: [mixins.translate],
  components: { ModalVue },
  props: {
    title: { type: String, default: '' },
    text: { type: String, default: '' }
  },
  data() {
    return { 
      isVisible: false
    }
  },
  methods: {
    show() { this.isVisible = true },
    hide() { this.isVisible = false }
  }
}
</script>
