<template>
<div class="row justify-content-center">
  <div class="col">
    <div class="login_form_per" id="login_form_per" v-if="show_form" >
      <loader-vue :visible="sending_request" />
      <p style="text-align:center">{{t('user_presubscriptions.info_register', { brand: app_brand })}}</p>
      <form>
        <div class="form-group">
          <label class="login_form_label" for="input_name">{{t('user_presubscriptions.placeholders.name')}}</label>
          <input type="text" class="form-control" id="input_name" aria-describedby="emailHelp" :placeholder= 'name_place_holder' v-model="name" >
        </div>
        <div class="form-group">
          <label class="login_form_label" for="input_last_name">{{t('user_presubscriptions.placeholders.last_name')}}</label>
          <input type="text" class="form-control" id="input_last_name" :placeholder="last_name_place_holder" v-model="last_name">
        </div>
        <div class="form-group">
          <label class="login_form_label" for="input_email">{{t('user_presubscriptions.placeholders.email')}}</label>
          <input type="" class="form-control" id="input_email" :placeholder="email_place_holder"  v-model="email" >
        </div>
        <div class="form-group">
          <label class="login_form_label" for="input_phone">{{t('user_presubscriptions.placeholders.phone')}}</label>
          <input type="tel" class="form-control" id="input_phone" :placeholder="phone_place_holder" v-model="phone">
        </div>
        <div class="form-group">
          <label class="login_form_label" for="input_password">{{t('user_presubscriptions.fields.password')}}</label>
          <forms-password-input-vue v-model="password"/>
        </div>
        <forms-lopd-text-vue v-if="!is_tolgeo_mex"/>
        <forms-messages-list-vue
          :messages= errors
          :title="errors_title"
        />
        <button id="submit_presubscription_form" class="btn btn-primary mt-3  submit" @click= "try_send_data">
          {{t('user_presubscriptions.create')}}
        </button>
      </form>
    </div>
    <div v-else class="login_form_per" id="request_response_container">
      <p v-if="!request_error"  class="message-success" v-html="t('user_presubscriptions.submit_message', {email:this.email_message})"> </p>
      <a href=# id="back_to_form" @click="()=> show_form = !show_form" class="center-right more-info">{{t('user_presubscriptions.back_form')}}</a>
    </div>
  </div>
</div>
</template>

<script>

import Vue from "vue"
import mixins from "common/mixins"
import Validator from "common/Validator"
import constants from "common/constants.js"
import { mapState, mapActions } from "vuex";

export default {
  mixins: [mixins.translate, mixins.requests],
  name: "user-presubscription-register-form-vue",
  components: {
    "forms-lopd-text-vue": () => import(/* webpackChunkName: "[request]" */ "components/forms/LopdText"),
    "forms-messages-list-vue": () => import(/* webpackChunkName: "[request]" */ "components/forms/MessagesList"),
    "loader-vue": () => import(/* webpackChunkName: "[request]" */ "components/Loader"),
    "forms-password-input-vue": () => import(/* webpackChunkName: "[request]" */ "components/forms/PasswordInput"),
  },
  data() {
    return {
      name:"",
      last_name:"",
      email: "",
      email_message: "",
      phone: "",
      password: "",
      sending_request: false,
      show_form: true,
      no_errors: true,
      errors: {},
      service_bus: new Vue(),
      request_error: false,
      name_place_holder: I18n.t('user_presubscriptions.placeholders.name'),
      last_name_place_holder: I18n.t('user_presubscriptions.placeholders.last_name'),
      email_place_holder: I18n.t('user_presubscriptions.placeholders.email'),
      phone_place_holder: I18n.t('user_presubscriptions.placeholders.phone'),
      errors_title: I18n.t('user_presubscriptions.errors_title'),
      password_min_length: constants.PASSWORD_MIN_LENGTH,
      password_max_length: constants.PASSWORD_MAX_LENGTH,
      phone_min_length: constants.PHONE_MIN_LENGTH,
      phone_max_length: constants.PHONE_MAX_LENGTH
    }
  },
  methods: {
    update_parent(input,errors) {
        this.email = input
        this.no_errors = errors
    },
    try_send_data(e){
      e.preventDefault()
      if(this.validate_form())
      {
        this.submit_form()
      }
    },
    validate_form() {
      let validator_params = [
        {
          fields: I18n.t("user_presubscriptions.fields.name"),
          values: this.name,
          validates: "filled",
        },
        {
          fields: I18n.t("user_presubscriptions.fields.last_name"),
          values: this.last_name,
          validates: "filled",
        },
        {
          fields: I18n.t("user_presubscriptions.fields.email"),
          values: this.email,
          validates: "email_format",
        },
        {
          fields: I18n.t("user_presubscriptions.fields.phone"),
          values: this.phone,
          validates: "filled",
        },
        {
          fields: I18n.t('user_presubscriptions.fields.phone'),
          values: this.phone,
          params: [this.phone_min_length, this.phone_max_length],
          validates: "length_between",
        },
        {
          fields: I18n.t('user_presubscriptions.fields.password'),
          values: this.password,
          validates: ["filled", "no_blanks"],
        },
        {
          fields: I18n.t('user_presubscriptions.fields.password'),
          values: this.password,
          params: [this.password_min_length, this.password_max_length],
          validates: "length_between",
        }
      ]
      if (!this.is_tolgeo_mex){
        validator_params = validator_params.concat({
          fields: I18n.t("user_presubscriptions.fields.check_info"),
          values: this.lopd_privacy,
          validates: "checked",
        })
      }
      let validator = new Validator(validator_params).do()

      if (!validator.success) {
        this.set_errors(validator.messages)
        return false
      }
      this.set_errors(validator.messages)
      return true

    },
    async submit_form(){
      this.sending_request = true
      let response = await this.submitRegisterForm(
        {
          perusuid: this.email,
          nombre: this.name,
          apellidos: this.last_name,
          telefono: this.phone,
          password: this.password,
          lopd_privacidad: this.lopd_privacy,
          lopd_grupo: this.lopd_group,
          lopd_comercial: this.lopd_publicity
        }
      )
      if(response.submitted){
        this.submitSuccess()
      }else{
        this.submitFailed(response.status)
      }
    },
    submitSuccess(){
      this.request_error = false
      this.email_message = this.email
      this.clear_form()
      this.hide_form()
    },
    submitFailed(status){
      this.request_error = true
      this.sending_request = false
      if(status == "422"){
        this.set_errors({"errors:": I18n.t("user_presubscriptions.submit_same_email_error",
          {url_password_recovery: this.recover_password_url})})
      }else{
         this.set_errors({"errors:": I18n.t("user_presubscriptions.submit_message_error")})
      }
    },
    set_errors(errors) {
      this.errors = errors
    },
    clear_form() {
      this.name = "",
      this.last_name = "",
      this.phone = "",
      this.email = "",
      this.password = ""
    },
    hide_form() {
      this.sending_request = false
      this.show_form = false
    },
    ...mapActions(["submitRegisterForm"])
  },
  computed: {
    recover_password_url() {
      return `${ this.tolweb_base_url }/user_subscriptions/password_recoveries/new`
    },
    ...mapState('lopd_texts', [
      'lopd_privacy',
      'lopd_group',
      'lopd_publicity'
    ]),
  }
}
</script>
