<template>
  <div>
    <slot name="box-search"/>
    <input
      type="text"
      id="autocomplete"
      v-model="autocomplete"
      v-if="autocomplete_visible"
      class="form-control mb-3"
      :placeholder="t('components.forms.autocomplete_tree_selector.placeholder_filter')"
      :disabled="only_selection_visible"
    />
    <div id="button_panel" class="box-select-filter" v-if="buttons_visible">
      <button
        @click="expand(true)" v-if="show_expand"
        id="expand_button" class="btn btn-outline-primary"
        :disabled="only_selection_visible || is_filtering"
      >
        {{ t('components.forms.autocomplete_tree_selector.open_all') }}
      </button>
      <button
        @click="expand(false)" v-else
        id="collapse_button" class="btn btn-outline-primary"
        :disabled="only_selection_visible || is_filtering"
      >
        {{ t('components.forms.autocomplete_tree_selector.close_all') }}
      </button>
      <button @click="check_filtered" v-if="is_filtering" class="btn btn-outline-primary">
        {{ t('components.forms.autocomplete_tree_selector.check_filtered') }}
      </button>
      <button @click="ask_restore_confirmation" v-if="has_selected"
        id="restore_button" class="btn btn-outline-primary"
      >
        {{ t('components.forms.autocomplete_tree_selector.restore_selection') }}
      </button>
      <div
        class="form-check">
        <input
          id="only_selection_switch"
          type="checkbox"
          class="form-check-input" v-model="only_selection_visible"
          :disabled="is_filtering || !has_selected"
        />
        <i></i>
        <label class="form-check-label" for="only_selection_switch">
          {{ t('components.forms.autocomplete_tree_selector.only_selection') }}
        </label>
      </div>
    </div>
    <ul class="list-unstyled list-origin">
      <forms-checkbox-tree-node-vue
        v-for="node in nodes"
        :key="node.id"
        v-bind="node"
        :filter="autocomplete"
        :is_filtering="is_filtering"
        :selected="selected"
        :auto_select_children="auto_select_children"
        :counts_visible="counts_visible"
        :only_selection_visible="only_selection_visible"
        @update_selected="update_selected"
        ref="children"
        :class="class_show_arrow"
      />
    </ul>
    <confirmation-modal-vue
      :title="t('components.forms.autocomplete_tree_selector.confirmation_title')"
      @confirmation:clicked="handle_confirmation_clicked"
      ref="confirmation_tree_selector"
    >
      {{ t("components.forms.autocomplete_tree_selector.confirmation_body") }}
    </confirmation-modal-vue>
  </div>
</template>
<script>
  import Vue from "vue"
  import mixins from 'common/mixins.js';

  export default {
    name: 'forms-autocomplete-tree-selector-vue',
    props: {
      nodes: { type: Array, default: () => [] },
      value: { type: Array, default: () => [] },
      auto_select_children: { type: Boolean, default: false },
      autocomplete_visible: { type: Boolean, default: true },
      counts_visible: { type: Boolean, default: false },
      buttons_visible: { type: Boolean, default: false },
      show_arrow: { type: Boolean, default: false }
    },
    mixins: [mixins.translate],
    data() {
      return {
        autocomplete: '',
        show_expand: true,
        selected: this.value,
        only_selection_visible: false,
        confirmation_modal_bus: new Vue()
      }
    },
    components: {
      'forms-checkbox-tree-node-vue': () => import(/* webpackChunkName: "[request]" */ 'components/forms/CheckBoxTreeNode'),
      'confirmation-modal-vue': () => import(/* webpackChunkName: "[request]" */ 'components/ConfirmationModal')
    },
    computed: {
      is_filtering() {
        return this.autocomplete.length >= 2
      },
      has_selected() {
        return this.selected.length > 0
      },
      class_show_arrow() {
        if(this.show_arrow){
          return 'list-select'
        }
      }
    },
    methods: {
      expand(value) {
        this.$refs.children.forEach((child) => child.expand(value, false))
        this.show_expand = !this.show_expand
      },
      update_selected(ids, selected) {
        var updated_list
        if (selected) {
          updated_list = this.selected.concat(ids)
          updated_list = Array.from(new Set(updated_list))
        } else {
          updated_list = this.selected.filter((value) => {return !ids.includes(value)})
        }
        this.selected = updated_list
        this.$emit('input', updated_list)
      },
      check_filtered() {
        this.$refs.children.forEach((child) => child.check_filtered())
      },
      restore_selection() {
        this.selected = []
        this.$emit('input', [])
        this.only_selection_visible = false
      },
      ask_restore_confirmation() {
        this.$refs.confirmation_tree_selector.show()
      },
      handle_confirmation_clicked({ confirmed }) {
        if (confirmed) { this.restore_selection() }
      }
    },
    watch: {
      value(val) {
        this.selected = val
      }
    },
  }
</script>
