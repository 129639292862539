<template>
  <div>
    <div class="row justify-content-center">
      <div class="col">
        <loader-vue :visible="sending_request" size="fa-4x"/>
        <div class="login_form_per">
          <form action="">
            <div class="form-group">
              <div class="login_form_label" for="input_username">
                {{ t("user_subscription_login.perusuid") }}
              </div>
              <input
                type="email"
                :placeholder="email_place_holder"
                v-model="username"
                class="form-control mt-1"
              />
            </div>
            <div class="form-group">
              <div class="login_form_label" for="input_password">
                {{ t("user_subscription_login.password") }}
              </div>
              <forms-password-input-vue v-model="password" custom_class="mt-1"/>
            </div>
            <p class="text-secondary forget-password">
              {{ t("user_subscription_login.forget_text") }}
              <a :href= recover_password_url target="_blank">{{ t("aqui") }}</a>
            </p>
            <forms-messages-list-vue
              :messages= errors
              :single_message="true"
            />
            <div v-if="error_request" id="errors_list"  class="alert alert-danger alert-dismissible fade show">
              <span
                class="d-block"
                v-for="error in error_request_messages"
                v-bind:key="error.id"
                role="alert"
              >{{ error }}</span>
            </div>
            <button
              v-if="must_redirect"
              id="submit_login_form"
              class="btn btn-primary mt-3 submit"
              @click="try_send_data"
            >
              {{ t("entrar") }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import mixins from "common/mixins"
import Validator from "common/Validator"
import { mapActions } from "vuex";

export default {
  mixins: [mixins.translate, mixins.requests],
  name: "user-subscription-login-form-vue",
  components: {
    "forms-messages-list-vue": () => import(/* webpackChunkName: "[request]" */ "components/forms/MessagesList"),
    "loader-vue": () => import(/* webpackChunkName: "[request]" */ "components/Loader"),
    "forms-password-input-vue": () => import(/* webpackChunkName: "[request]" */ "components/forms/PasswordInput")
  },
  props: {
    remote_form: { type: Boolean, default: true },
    must_redirect: { type: Boolean, default: true },
  },
  data() {
    return {
      errors: {},
      error_request_messages: [],
      error_request: false,
      username: "",
      password: "",
      sending_request: false,
      service_bus: new Vue(),
      email_place_holder: I18n.t("sofia.sofia_form.email_place_holder"),
      password_place_holder: I18n.t("user_subscription_login.password"),
    }
  },
  methods: {
    try_send_data(e=null) {
      if(e){
        e.preventDefault()
      }
      this.clear_request_errors()
      if(this.validateForm())
      {
        this.submit_form()
      }
    },
    validateForm() {
      this.clear_errors()
      let validator_params = [
        {
          fields: I18n.t("sofia.sofia_form.fields.email"),
          values: this.username,
          validates: "email_format",
        }
      ]
      let validator = new Validator(validator_params).do()

      if (!validator.success) {
        this.set_errors(validator.messages)
        return false
      }
      this.set_errors(validator.messages)
      return true
    },
    async submit_form() {
      this.sending_request = true
      let response = await this.submitLoginForm( {
        user_subscriptions_login: {
          perusuid: this.username,
          password: this.password,
        },
      })
      if(response['submitted']){
        this.submitSuccess()
      }else{
        this.submitFailed(response['data'].data)
      }
      this.clear_errors()
    },
    submitSuccess(){
      this.error_request = false
      this.sending_request = false
      if(this.must_redirect) {
        window.location.href = this.url_for_user_subscription_home()
      }
      else{
        this.$emit("login:success", { login_action: true })
      }
    },
    submitFailed(data){
      this.error_request = true
      this.sending_request = false
      this.getErrors(data)
    },
    set_errors(errors) {
      this.errors = errors
    },
    getErrors(data) {
      this.error_request = true
      var error_array = []
      if (data.length) {
        data.forEach(function (message, index) {
          error_array[index] = message.split(":")[1]
        })
        this.error_request_messages = error_array
      }
    },
    clear_request_errors() {
      this.error_request = false
      this.error_request_messages=[]
    },
    clear_errors() {
      this.set_errors({})
    },
     ...mapActions(["submitLoginForm"])
  },
  computed: {
    recover_password_url() {
      return `${ this.tolweb_base_url }/user_subscriptions/password_recoveries/new`
    },
  }
}
</script>
