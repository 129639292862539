import ApiClient from 'common/ApiClient'

const state = {
  rfc_search: [],
  loading_rfc_search: false,
  errors: [],
  existing_rfc_alert: false
}

const getters = {
  has_errors(state) {
    return state.errors.length != 0
  }
}

const mutations = {
  start_loading_rfc_search(state) { state.loading_rfc_search = true },
  end_loading_rfc_search(state) { state.loading_rfc_search = false },
  add_error(state, data) { state.errors.push(data) },
  clear_errors(state) { state.errors = [] },
  add_rfc_search_result(state, data) { state.rfc_search = data },
  clear_rfc_search_results(state) { state.rfc_search = [] },
  add_existing_rfc_alert(state, data) { state.existing_rfc_alert = data },
  reset_existing_rfc_alert(state) { state.existing_rfc_alert = false },
}

const actions = {
  async load_rfc_search_results(context, rfc_code) {
    const apiClient = new ApiClient()

    context.commit('clear_errors')
    context.commit('clear_rfc_search_results')
    context.commit('start_loading_rfc_search')
    context.commit('reset_existing_rfc_alert')

    let response = await apiClient.get(gon.url_for.rfc_searches, rfc_code)

    context.commit('end_loading_rfc_search')

    if (response.failed) {
      context.commit('add_error', 'search_rfc_failed')
      return
    }
    context.commit('add_existing_rfc_alert', response.data.existing_alert)
    context.commit('add_rfc_search_result', response.data.result)
  }
}

export default {
  namespaced: true,
  name: "rfc_search",
  state,
  getters,
  mutations,
  actions
}
