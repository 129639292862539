<template>
  <modal-vue
    :visible.sync="is_visible"
    modal_id="confirmation_modal"
    :content_loaded="true">

    <template v-slot:header>
      {{ title }}
    </template>

    <template v-slot:body>
      <slot></slot>
    </template>

    <template v-slot:footer>
      <button id="aceptar" class="btn btn-primary" :disabled="!valid" @click="confirmation_accept">{{t("aceptar")}}</button>
      <button id="cancelar" class="btn btn-secondary" @click="confirmation_cancel">{{t("cancel")}}</button>
    </template>
  </modal-vue>
</template>

<script>
import mixins from 'common/mixins'

export default {
  name: 'confirmation-modal-vue',
  mixins: [ mixins.translate ],
  props: {
    title: { type: String, default: ""},
    valid: {type: Boolean, default: true}
  },
  components: {
    "modal-vue": () => import(/* webpackChunkName: "[request]" */ "components/Modal")
  },
  data() {
    return { is_visible : false }
  },
  methods: {
    show() { this.is_visible = true },
    hide() { this.is_visible = false },
    confirmation_accept() {
      this.hide()
      this.$emit("confirmation:clicked", { confirmed: true }) 
    },
    confirmation_cancel() {
      this.hide()
      this.$emit("confirmation:clicked", { confirmed: false })
    }
  }
}
</script>
