<template>
  <div v-html="html_content" data-cy="static_content" v-if="content_loaded" :class="[classes]"></div>
</template>

<script>
import mixins from 'common/mixins.js'

export default {
  name: "static-content-vue",
  mixins: [mixins.requests],
  props: ["resource", "classes"],
  data() {
    return {
      html_content: '',
      content_loaded: false
    }
  },
  mounted() {
    this.fetch_estatica()
  },
  methods: {
    async fetch_estatica(){
      const apps = [this.$current_app, 'default']

      for (const app of apps) {
        for (const locale of this.$fallbacks) {
          const estatica_url = this.url_for_estaticas(app, locale, this.resource)
          const response = await fetch(estatica_url)
          if (response.ok) {
            this.html_content = await response.text()
            this.content_loaded = true
            return
          }
        }
      }
    }
  }
}
</script>
