import stem from 'common/stemmer_es.js'

const STOPWORDS = new Set(['de','la','que','el','en','y','a','los','del','se','las','por','un','para','con','no','una','su','al','es',
  'lo','como','más','pero','sus','le','ya','o','fue','este','ha','sí','porque','esta','son','entre','está','cuando','muy','sin',
  'sobre','ser','tiene','también','me','hasta','hay','donde','han','quien','están','desde','todo','nos','durante',
  'todos','uno','les','ni','contra','otros','fueron','ese','eso','había','ante','ellos','e','esto','mí','antes',
  'algunos','qué','unos','yo','otro','otras','otra','él','tanto','esa','estos','mucho','quienes','nada','muchos','cual',
  'sea','poco','ella','estar','haber','estas','estaba','estamos','algunas','algo','nosotros','mi','mis','tú','te','ti','tu',
  'tus','ellas','nosotras','vosotros','vosotras','os','mío','mía','míos','mías','tuyo','tuya','tuyos','tuyas','suyo','suya',
  'suyos','suyas','nuestro','nuestra','nuestros','nuestras','vuestro','vuestra','vuestros','vuestras','esos','esas','estoy',
  'estás','está','estamos','estáis','están','esté','estés','estemos','estéis','estén','estaré','estarás','estará','estaremos',
  'estaréis','estarán','estaría','estarías','estaríamos','estaríais','estarían','estaba','estabas','estábamos','estabais',
  'estaban','estuve','estuviste','estuvo','estuvimos','estuvisteis','estuvieron','estuviera','estuvieras','estuviéramos',
  'estuvierais','estuvieran','estuviese','estuvieses','estuviésemos','estuvieseis','estuviesen','estando','estada',
  'estadas','estad','he','has','ha','hemos','habéis','han','haya','hayas','hayamos','hayáis','hayan','habré','habrás',
  'habrá','habremos','habréis','habrán','habría','habrías','habríamos','habríais','habrían','había','habías','habíamos','habíais',
  'habían','hube','hubiste','hubo','hubimos','hubisteis','hubieron','hubiera','hubieras','hubiéramos','hubierais','hubieran',
  'hubiese','hubieses','hubiésemos','hubieseis','hubiesen','habiendo','habido','habida','habidos','habidas','soy','eres','es',
  'somos','sois','son','sea','seas','seamos','seáis','sean','seré','serás','será','seremos','seréis','serán','sería','serías',
  'seríamos','seríais','serían','era','eras','éramos','erais','eran','fui','fuiste','fue','fuimos','fuisteis','fueron','fuera',
  'fueras','fuéramos','fuerais','fueran','fuese','fueses','fuésemos','fueseis','fuesen','siendo','sido','tengo','tienes','tiene',
  'tenemos','tenéis','tienen','tenga','tengas','tengamos','tengáis','tengan','tendré','tendrás','tendrá','tendremos','tendréis',
  'tendrán','tendría','tendrías','tendríamos','tendríais','tendrían','tenía','tenías','teníamos','teníais','tenían','tuve',
  'tuviste','tuvo','tuvimos','tuvisteis','tuvieron','tuviera','tuvieras','tuviéramos','tuvierais','tuvieran','tuviese','tuvieses',
  'tuviésemos','tuvieseis','tuviesen','teniendo','tenido','tenida','tenidos','tenidas','tened'])

var accepted_characters = /[^a-zA-Z0-9 àáâãāçćčèéêēęiìïíīîñńôöòóōśûüùúū \\\/]/gi

var token_formatter = {
  format_and_split_tokens: function(tokens){
    var cleaned_tokens = []
    tokens.forEach(function(token) {
      token = token.replace(accepted_characters, "")
      token.split(/(\s+)/).forEach( function(e) {
        if( !STOPWORDS.has(e.toLowerCase().toString()) && (e == e.trim()) && e!="" ){
          cleaned_tokens.push(stem(e) )
        }
      });

    })
    return cleaned_tokens
  },

  get_matching_tokens_in_text: function(cleaned_token_list, text){
    var splitted_text_items = text
      .toLowerCase()
      .replace(/<[^>]+>/ig, ' ')
      .replace(accepted_characters, ' ')
      .split(/\s+/)
    var unique_text_items = Array.from(new Set(splitted_text_items))
    var matching_tokens = []

    unique_text_items.forEach(function(text_token){
      if(cleaned_token_list.includes(stem(text_token))){
        matching_tokens.push(text_token)
      }
    })
    return matching_tokens
  }

}

export default token_formatter