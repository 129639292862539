import Vue from 'vue/dist/vue.esm'

Vue.component(
  'subscriptions-contract-button-vue',
  () => import(/* webpackChunkName: "[request]" */ "components/subscriptions/ContractButton")
)
Vue.component(
  'subscriptions-contract-form-modal-vue',
  () => import(/* webpackChunkName: "[request]" */ "components/subscriptions/ContractFormModal")
)
